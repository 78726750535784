import React from "react";

function Footer() {
  return (
    <>
      <div>test footer</div>
    </>
  );
}

export default Footer;
