import React from 'react'

function ReportBoxModal(props) {
    const { closeReportBox } = props;
    return (
        <>
            <section className='reportbox_modal'>
                <div className='modalbody_'>

                    <div className='close_btn'>
                        <button className='btn' onClick={() => closeReportBox(false)}><i className="fa-solid fa-xmark"></i></button>
                    </div>

                    <div className='head'>
                        <h4>Why do you want to report this person?</h4>
                    </div>
                    <form>
                        <div className="form-group">
                            <textarea
                                className="form-control"
                                id=""
                                rows={5}
                                defaultValue={""}
                                placeholder='Write Your Reason'
                            />
                        </div>
                    </form>

                    <div className='modalSubmit_btn'>
                        <button className='btn'>Submit</button>
                    </div>

                </div>
            </section>
        </>
    )
}

export default ReportBoxModal