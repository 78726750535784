import React, { useState } from 'react'
import ImageDownloader from '../../View/ImageDownloader'

function ViewInmage({ imgurl }) {
    const [isfulldownload, setisfulldownload] = useState(false)
    console.log(isfulldownload, "lllkk")
    return (
        <>
            <img style={isfulldownload ? { position: "absolute", zIndex: "11" } : {}} height={60} width={60}
                src={imgurl} alt="/" />
            <ImageDownloader imageUrl={imgurl} isfulldownload={isfulldownload} setisfulldownload={setisfulldownload} />
        </>
    )
}

export default ViewInmage