import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import ProductServices from "../../services/ProductServices"

const initialState = {
    productData: [],
    AllProduct: [],
    loading: false,
    allLoading: false,
}

export const getProduct = createAsyncThunk("product_get_Product", async () => {
    const res = await ProductServices.getAssignProduct()
    console.log("resProdd", res)
    return res
})

export const getAllProduct = createAsyncThunk("product_get_all_Product", async () => {
    const res = await ProductServices.getallProject()
    console.log("resProdd_all", res)
    return res
})

const productSlice = createSlice({
    name: "get_product",
    initialState,
    reducers: {
        clearState: (state) => {
            state.taskData = [];
            state.loading = false;
        }
    },
    extraReducers: {
        [getProduct.pending]: (state) => {
            state.loading = true;
        },
        [getProduct.fulfilled]: (state, { payload }) => {
            if (payload && payload?.status) {
                state.productData = payload?.data;
                state.loading = false;
            } else {
                state.loading = false;
            }
        },
        [getAllProduct.fulfilled]: (state, { payload }) => {
            if (payload && payload?.status) {
                state.AllProduct = payload?.data;
                state.allLoading = false;
            } else {
                state.allLoading = false;
            }
        },
        [getProduct.rejected]: (state) => {
            state.loading = false;
        },
        [getAllProduct.rejected]: (state) => {
            state.allLoading = false;
        }
    }
})

export const { clearState } = productSlice.actions;
export const productReducer = productSlice.reducer;

