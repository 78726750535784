import React, { useState, useEffect } from "react";

// API
import ProductServices from "../../services/ProductServices";
import AuthServices from "../../services/AuthServices";

// Helpers
import { toast } from "react-hot-toast";

// Components
import PaymentCardModal from "../../Component/Modal/PaymentCardModal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import blankchake from "../../Images/blankChake.png";
import chaked from "../../Images/Chaked.png";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { click } from "@testing-library/user-event/dist/click";
import { useDispatch, useSelector } from "react-redux";
import { changeUpdateState } from "../../Redux/Reducer/UpdateServiceSlice";
import UpdateServiceNew from "../../Component/Profile/UpdateServiceNew";
import { array } from "yup";
import moment from "moment";
function Subscripindex() {
  const localdata = localStorage.getItem("user");
  const userdata = JSON.parse(localdata);
  console.log("khjhjk", userdata);
  const [cardData, setcardData] = useState(false);
  const [allPlans, setallPlans] = useState([]);
  const [stripeCustomerId, setstripeCustomerId] = useState(null);
  const [stripeData, setstripeData] = useState(null);
  const [cardModalOpen, setcardModalOpen] = useState(false);
  const [myPlan, setmyPlans] = useState([]);
  const [topupdata, setTopdata] = useState([]);
  console.log("stripeData", allPlans);

  useEffect(() => {
    getActivePlan();
    getAllPlan();
    getCustomerId();
    myPlans();
    getAllTopUpdata();
  }, []);

  //............................service section.........

  const myDivRef = useRef(null);
  const navigate = useNavigate();
  const [modalflag, setModalflag] = useState(false);
  console.log("userdata", userdata);
  const upflag = useSelector((state) => state.ServiceReducer.updateflag);
  const dispatch = useDispatch();
  console.log("jhgjhkk", upflag);

  const [tabIndex, setTabIndex] = useState(0);

  console.log("kjbjhc", myPlan[0]?.skillLimit);

  const [selected, setSelected] = useState(
    userdata?.categories.length > 0
      ? userdata?.categories?.map((label) => ({
          label,
          value: label,
        }))
      : []
  );
  console.log("jkhdgdhkg", selected);
  //get users data:
  const getAlldatas = async () => {
    const res = await AuthServices.getUserDatas();
    const temparr = res?.data[0].categories.map((label) => ({
      label,
      value: label,
    }));
    setSelected(temparr);
  };

  useEffect(() => {
    getAlldatas();
  }, [modalflag]);
  //..........................

  function getActivePlan() {
    ProductServices.activeStatusCard()
      .then((res) => {
        // console.log("active card status", res);
        if (
          res &&
          res.status &&
          res.data[0] &&
          res.data[0].productDetails.length != 0
        ) {
          setcardData(true);
        }
      })
      .catch((err) => {
        console.log("get active plan error: ", err.message);
      });
  }

  //my Plans..
  const myPlans = () => {
    ProductServices.getMyPlans()
      .then((res) => {
        if (res && res.status) {
          setmyPlans(res.data);
        } else {
          // alert("server error");
        }
      })
      .catch((err) => {
        console.log("get my plan error: ", err.message);
      });
  };

  //Buy plans(checkout card via stripe)
  const buyPlans = (item) => {
    console.log("ghfhg", item);

    const data = {
      // success_url: "http://localhost:3000",
      userName: userdata?.username,
      planId: item._id,
      planBuyPrice: item?.price,
      limitAvailable: item?.bidLimit,
      skillLimit: item?.skillLimit,
      deductionPercentage: item?.deductionPercentage,
      customer: stripeCustomerId,
      priceId: item.stripePriceId,
    };
    ProductServices.CardCheckout(data)
      .then((res) => {
        console.log("jdfhgjkdh", res);
        if (res && res.status) {
          // setmyPlans(res.data);
          console.log("jfghjkjlk", res);
          window.open(res?.data?.url);
        } else {
          toast.error(res.msg);
          // alert("server error");
        }
      })
      .catch((err) => {
        toast.error(err.msg);
        console.log("get my plan error: ", err.message);
      });
  };

  function getAllPlan() {
    ProductServices.getPlan()
      .then((res) => {
        console.log("All subscr plans", res);
        if (res && res.status) {
          // .filter(it => it.stripePriceId != "").map(it => ({ ...it, price: 100 }))
          setallPlans(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  //All TopUpData
  function getAllTopUpdata() {
    ProductServices.getAllTopups()
      .then((res) => {
        if (res && res.status) {
          setTopdata(res.data);
        }
        console.log("rrrw", res);
      })
      .catch((err) => {
        console.log(err, "uuukk");
      });
  }

  const buyTopUps = (item) => {
    console.log("ghfhg", item);

    //   {
    //     "customer":"cus_PLzY85CLa1u518",
    //     "price":15,    // top price
    //     "name":"Top-up",  // name of the top up
    //     "success_url":"https://www.amazon.in/",  // any url you want to redirect after success
    //     "bidLimit":4,   // top up bid limit   //
    //     "skillLimit":2,    // top up slill limit
    //     "topUpId" : "65b2011fb2d4481c92f4e86b"   // top up _id
    // }

    const data = {
      // success_url: `http://localhost:3000/subscription?paymentSuccess=true`,
      success_url: `https://login.buildbeta.co.uk/subscription?paymentSuccess=true`,

      name: userdata?.username,
      topUpId: item._id,
      price: item?.price,
      bidLimit: item?.bidLimit,
      skillLimit: item?.skillLimit,
      customer: stripeCustomerId,
      // priceId: item.stripePriceId,
    };

    ProductServices.buyTopup(data)
      .then((res) => {
        console.log("jdfhgjkdh", res);
        if (res && res.status) {
          // setmyPlans(res.data);
          console.log("jfghjkjlk", res);
          window.open(res?.data?.url);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((err) => {
        toast.error(err.msg);
        console.log("get my plan error: ", err.msg);
      });
  };

  function getCustomerId() {
    AuthServices.fetchProfile()
      .then((res) => {
        console.log("getCustomerId", res);
        if (res && res.status) {
          setstripeCustomerId(res.data[0].stripe_customer_Id);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  console.log("stripeCustomerId", stripeCustomerId);

  // function buyPlan() {
  //   if (cardData) {
  //     toast.success("Already have a active trial plan", { duration: 3 });
  //     return;
  //   }

  //   setcardModalOpen(true);
  // }

  const tablist = [
    "My Active Plan",
    "My Services",
    "Subscription Plans",
    "My Top-Up",
    "My Referels",
  ];

  const tabpanel = [
    <section
      className="subscription_page"
      style={{ display: myPlan.length > 0 ? "block" : "none" }}
    >
      <div className="allsubscription_card">
        <div className="row">
          {myPlan?.map((item1, index) => {
            const item = item1.planDetail;

            const targetDate = moment(item1.expireOn);
            const currentDate = moment();

            const daysRemaining = targetDate.diff(currentDate, "days");

            // console.log(`Days remaining: ${daysRemaining}`, item);

            if (index < 1)
              return (
                <div className="col-md-4 col-sm-6 col-12" key={index}>
                  <div className="subscript_card">
                    <div className="toparea">
                      <h5>
                        {item?.name} (£{item?.price / 100})
                      </h5>
                    </div>
                    <div className="planarea">
                      <h4>
                        <span className="amount">
                          £
                          {Number(item?.price / 100 / item?.duration).toFixed(
                            2
                          )}
                        </span>
                        <span className="time"> &nbsp;monthly</span>
                      </h4>

                      <ul>
                        <li
                          style={{
                            fontWeight: "700",
                            fontSize: "20px",
                          }}
                        >
                          Duration {item?.duration} months
                        </li>
                        <li>Bid Limit {item1?.limitAvailable}</li>
                        <li>Service Limit {item1?.skillLimit}</li>
                        <li>{item?.bestValue}</li>

                        <li>Try 1 Month For Free</li>
                        <li
                          style={{
                            display: "flex",
                            marginTop: "20px",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>Remaing:{daysRemaining}days</div>
                          <div>
                            <button className="cancel_btn">Cancel</button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
          })}
        </div>
      </div>
    </section>,
    <div className="perentDiv">
      <div className="maindiv">
        {selected?.map((ele, id) => {
          return (
            <div className="subDiv">
              <p>{ele.value}</p>
              <img height={20} width={20} src={chaked}></img>
            </div>
          );
        })}
      </div>
      <div>
        <button
          className="editButton"
          onClick={() => {
            setModalflag(true);
          }}
        >
          Edit Service
        </button>
      </div>
    </div>,
    <section className="subscription_page">
      <div className="allsubscription_card">
        <div className="row">
          {allPlans &&
            allPlans?.reverse().map((item, index) => (
              <div className="col-md-4 col-sm-6 col-12" key={index}>
                <div className="subscript_card">
                  <div className="toparea">
                    <h5>
                      {item?.name} (£{item?.price / 100})
                    </h5>
                  </div>
                  <div className="planarea">
                    <h4>
                      <span className="amount">
                        £{Number(item?.price / 100 / item?.duration).toFixed(2)}
                      </span>
                      <span className="time"> &nbsp;monthly</span>
                    </h4>

                    <ul>
                      <li
                        style={{
                          fontWeight: "700",
                          fontSize: "20px",
                        }}
                      >
                        Duration {item?.duration} months
                      </li>
                      <li>Bid Limit {item.bidLimit}</li>
                      <li>Service Limit {item.skillLimit}</li>
                      <li>{item?.bestValue}</li>

                      <li>Try 1 Month For Free</li>
                    </ul>
                  </div>

                  <div className="subscript_btn">
                    <button
                      className="btn"
                      onClick={() => {
                        buyPlans(item);
                      }}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>,
    <section className="subscription_page">
      <div className="allsubscription_card">
        <div className="row">
          {topupdata &&
            topupdata?.reverse().map((item, index) => (
              <div className="col-md-4 col-sm-6 col-12" key={index}>
                <div className="subscript_card">
                  <div className="toparea">
                    <h5>
                      {item?.name} (£{item?.price / 100})
                    </h5>
                  </div>
                  <div className="planarea">
                    <ul>
                      <li
                        style={{
                          fontWeight: "700",
                          fontSize: "20px",
                        }}
                      ></li>
                      <li>Bid Limit {item.bidLimit}</li>
                      <li>Service Limit {item.skillLimit}</li>
                      <li>{item?.bestValue}</li>
                    </ul>
                  </div>

                  <div className="subscript_btn">
                    <button
                      className="btn"
                      onClick={() => {
                        buyTopUps(item);
                      }}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>,
    "",
  ];

  return (
    <>
      <div className="main_wrap">
        <div className="container-fluid">
          <Link to="/Settings" className="posted_icon">
            <i className="fa-solid fa-arrow-left"></i>
          </Link>
          <div className="sbsrtplandiv">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList>
                {userdata?.jobRole[0] == "64dca6a1a63f41ccadf38c3c"
                  ? tablist
                      .filter((ele, id) => id !== 0 && id !== 2 && id != 1)
                      .map((ele, id) => {
                        console.log(userdata, "nbjhj");

                        return <Tab>{ele}</Tab>;
                      })
                  : tablist.map((ele, id) => {
                      console.log(userdata, "nbjhj");

                      return <Tab>{ele}</Tab>;
                    })}
              </TabList>
              {/* //...contents */}
              {userdata?.jobRole[0] == "64dca6a1a63f41ccadf38c3c"
                ? tabpanel
                    .filter((ele, id) => id !== 0 && id !== 2 && id != 1)

                    .map((ele, id) => <TabPanel>{ele}</TabPanel>)
                : tabpanel.map((ele, id) => <TabPanel>{ele}</TabPanel>)}
            </Tabs>
          </div>

          {cardModalOpen && (
            <PaymentCardModal
              openModal={setcardModalOpen}
              cardData={cardData}
              stripe_customer_Id={stripeCustomerId}
              stripeData={stripeData}
            />
          )}
        </div>
      </div>

      {modalflag && (
        <UpdateServiceNew
          setTabIndex={setTabIndex}
          setModalflag={setModalflag}
        />
      )}
    </>
  );
}

export default Subscripindex;
