import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import succ from "../../Images/check_img.avif";
import moment from 'moment';
import ProductServices from '../../services/ProductServices';
import toast from 'react-hot-toast';

function QueryPayment(props) {
    const { setopenmodal, PayTaskValues, singleTaskData,
        projectId, payForTask_changeStatus, offlinepayment,
        amount, setAmount,
        wallet, setWallet } = props;
    console.log("singleTaskData", singleTaskData, projectId)
    const localdata = localStorage.getItem("user");
    const userdata = JSON.parse(localdata);
    const [mode, setmode] = useState("")



    const PaymentProceed = async () => {

        const data = {
            price: singleTaskData.taskBudget,
            name: singleTaskData.taskName,
            customer: userdata.stripe_customer_Id,
            success_url: `https://login.buildbeta.co.uk/project-detail/${projectId}?paymentSuccess=true&taskId=${singleTaskData._id}`,


            // success_url: `http://localhost:3000/project-detail/${projectId}?paymentSuccess=true&taskId=${singleTaskData._id}`,


            type: "payment"

        }
        const res = await ProductServices.Task_PaymentProceed(data)
        if (res && res.status) {
            window.open(res.data.url)
        }
    }



    const SendNotification_While_OfflinePayment = async () => {

        const data =
        {
            "taskId": singleTaskData._id
        }





        const res = await ProductServices.SendNotification_OfflinePayment(data)
        if (res && res.status) {
            console.log(res, "popoower")
            toast.success("Payment Send succsessfuly")
            setopenmodal(false)
            // alert("notification send sucsessfully")
        }
    }



    const Paycash = () => {
        if (mode === "wallet") {
            PayTaskValues()
        }
        // if (mode === "online") {
        //     PaymentProceed()
        // }
        if (mode === "offline") {
            // payForTask_changeStatus()
            // offlinepayment()

            SendNotification_While_OfflinePayment()

            // toast.success("Payment Send succsessfuly")
            // setopenmodal(false)
        }
    }
    return (
        <>
            <section className="success">
                <div className="success_page">

                    <div className='paymntCancel' style={{ cursor: "pointer" }} onClick={() => { setopenmodal(false) }}>
                        <i class="fa-solid fa-xmark"></i>
                    </div>

                    <div className="success_title">
                        <p>Payment Date: {moment().format('YYYY-MM-DD HH:mm:ss')}</p>
                        <p>Payment Amount: {singleTaskData.taskBudget}</p>
                        <p>Task Name: {singleTaskData.taskName}</p>
                        <p>Project Name: {singleTaskData.projectData.name}</p>
                        {/* <span>Lorem ipsum repudiandae aperiam harum quaerat illo eaque!</span> */}
                    </div>

                    {/* <input type='radio' className="back_home_btn" value={"wallet"} name='pay' />Pay within
                            Buildbeta
                            <input type='radio' value={"offline"} name='pay' />Pay outside
                            build beta */}

                    <div className='paymentMthdDiv'>
                        <h5 className='paymntMthdHead'> Select Payment Method: </h5>
                        <div className="home_btn" >

                            <div className='showCheck' onChange={(e) => {
                                // alert(e.target.value)

                                setmode(e.target.value)


                            }}>

                                <label>
                                    <input type="radio" value="wallet" name="paymethod_offline" />
                                    Pay within Buildbeta
                                </label>

                                <label>
                                    <input type="radio" value="offline" name="paymethod_offline" />
                                    Pay outside build beta
                                </label>


                            </div>

                            {/* <select
                                style={{ display: mode ? "none" : "block" }}
                                className="back_home_btn">


                                <option>--select--</option>

                                <option value="wallet" className="back_home_btn">Pay within
                                    Buildbeta</option>
                                <option value="online" className="back_home_btn">online</option>
                                <option value="offline" className="back_home_btn">Pay outside
                                    build beta</option>
                            </select> */}

                            <button
                                // style={{ display: mode ? "block" : "none" }}
                                className="back_home_btn" onClick={() => { Paycash() }}>Pay</button>
                        </div>
                    </div>

                </div>

            </section>
        </>
    )
}

export default QueryPayment