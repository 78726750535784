import HttpClient from "../utils/HttpClient";

const getAssignProduct = async () => {
  let endpoint = "assign-project";
  return await HttpClient.get(endpoint);
};
const getallProject = async () => {
  let endpoint = "all-project/";
  return await HttpClient.get(endpoint);
};
const fetchAddTask = async (data) => {
  let endpoint = "task";
  return await HttpClient.post(endpoint, data);
};

const fetchGetTask = async (id) => {
  let endpoint = "task/" + id;
  return await HttpClient.get(endpoint);
};

//.................................................for Chatt................//
const getbidder = async (id) => {

  return HttpClient.get('get-bidder/' + id);
}

const getChat = async (id) => {
  console.log("lhhjk", id)
  return HttpClient.get('chat/' + id);
}


const uploadimage = async (data) => {
  return HttpClient.ImageUplode('project-image', "POST", data, {});
}

//......................................................getChatt...................//


const deleteTask = async (id) => {
  let endpoint = "task/" + id;
  return await HttpClient.deletemethod(endpoint);
};
const deleteSubTask = async (id) => {
  let endpoint = "sub-task/" + id;
  return await HttpClient.deletemethod(endpoint);
};
const fetchAddSubTask = async (data) => {
  let endpoint = "sub-task";
  return await HttpClient.post(endpoint, data);
};

const fetchGetSubTask = async (id) => {
  let endpoint = "sub-task/" + id;
  return await HttpClient.get(endpoint);
};

const updateTask = async (data, id) => {
  let endpoint = "task/" + id;
  return await HttpClient.put(endpoint, data);
};

const EditSubTask = async (data, id) => {
  let endpoint = "sub-task/" + id;
  return await HttpClient.put(endpoint, data);
};
const getsingleProject = async (id) => {
  let endpoint = "single-project/" + id;
  return await HttpClient.get(endpoint);
};
const bidProject = async (data) => {
  let endpoint = "bid";
  return await HttpClient.post(endpoint, data);
};

const statusChange = async (data, id) => {
  let endpoint = "assign-project/" + id;
  return await HttpClient.put(endpoint, data);
};
const getAllprojectList = async (data) => {
  return HttpClient.post('get-project', data);
}

const activeStatusCard = async () => {
  return HttpClient.get('active-status-card');
}

const getPlan = async () => {
  return HttpClient.get('getplan');
}

const CreateSubscription = async (data, id) => {
  return HttpClient.post('subscription/' + id, data);
}

const buyTrailplan = async (data) => {
  return HttpClient.post('sub-trial-plan', data);
}

const Makepayment = async (data) => {
  return HttpClient.post('payment', data);
}
// const piepayment = async (id) => {
//   return HttpClient.get('pie-payment/' + id);
// }

//...................................
const getMybiddedProject = async () => {
  return HttpClient.get('get-all-bidded-project');
}
const getMyPlans = async () => {
  return HttpClient.get('get-own-plan');
}
const CardCheckout = async (data) => {
  return HttpClient.post('checkout', data);
}

const walletHistory = async () => {
  return HttpClient.get('wallet-history');
}
const addbalancewallet = async (data) => {
  return HttpClient.post('add-balance-wallet', data);
}

const requestStatusCahange = async (data, id) => {
  return HttpClient.put('task/request-status/' + id, data);
}
const requestStatusComplete = async (data, id) => {
  return HttpClient.put('task/complete-status/' + id, data);
}

const Task_Payment = async (data, id) => {
  return HttpClient.post('task/payment/' + id, data);
}

const Task_PaymentProceed = async (data) => {
  return HttpClient.post('task/payment-stripe', data);
}

const ViewTSKiNV = async (id) => {
  return HttpClient.get('task/get-invoice-task-completion/' + id);
}

const piepayment = async (id) => {
  return HttpClient.get('pie-payment/' + id);
}
const pietask = async (id) => {
  return HttpClient.get('pie-task/' + id);
}

const pietime = async (id) => {
  return HttpClient.get('pie-time/' + id);
}


const updateprojectStatus = async (data, id) => {
  return HttpClient.put('project-status/' + id, data);
}


const PaymentHistoryDetails = async () => {
  return HttpClient.get('payment-history');
}

const balanceWithdrawal = async (data) => {
  return HttpClient.post('transfer-balance-request-wallet-bank', data);
}

const getWithdrawalRequest = async () => {
  return HttpClient.get('get-transfer-balance-request-wallet-bank');
}
const postBankDetails = async (data) => {

  return HttpClient.post('user-post-bank-detail', data);
}

const offlinePament_api = async (data, id) => {

  return HttpClient.post('task/payment-offline/' + id, data);
}


const getBankDetails = async () => {
  return HttpClient.get('user-get-bank-detail');
}

const bidedproject = async () => {
  return HttpClient.get('bided-project');
}
const sendPaymentConfirm = async (data, id) => {

  return HttpClient.post('task/confirm-reflect-payment/' + id, data);
}

const getAllNotification = async () => {
  return HttpClient.get('get-notification-list');
}

const getAllTopups = async () => {
  return HttpClient.get('top-up');
}
const buyTopup = async (data) => {

  return HttpClient.post('buy-top-up', data);
}
const verifyBankAccount = async (accountNum) => {
  return HttpClient.get('verifyBankAccount/' + accountNum);
}
const createstripeaccount = async (data) => {
  return HttpClient.post('create-stripe-account', data);
}

const SendNotification_OfflinePayment = async (data) => {
  return HttpClient.post('task/offline/notification', data);
}

const getStripeBankDetails = async () => {
  return HttpClient.get('fetchBankDetails');
}

// https://login.buildbeta.co.uk/payment?isCreate=true
// http://localhost:3001/payment?isCreate=true


export default {
  getAssignProduct,
  getallProject,
  fetchAddTask,
  fetchGetTask,
  fetchAddSubTask,
  fetchGetSubTask,
  getsingleProject,
  updateTask,

  bidProject,
  EditSubTask,
  deleteTask,
  deleteSubTask,
  statusChange,
  getAllprojectList,
  activeStatusCard,
  getPlan,
  CreateSubscription,
  buyTrailplan,
  Makepayment,
  piepayment,

  getbidder,
  getChat,
  uploadimage,
  getMybiddedProject,
  getMyPlans,
  CardCheckout,
  walletHistory,
  addbalancewallet,
  requestStatusCahange,
  requestStatusComplete,
  Task_Payment,
  Task_PaymentProceed,
  ViewTSKiNV,
  pietask,
  pietime,
  updateprojectStatus,
  PaymentHistoryDetails,
  balanceWithdrawal,
  getWithdrawalRequest,
  postBankDetails,
  getBankDetails,
  bidedproject,
  offlinePament_api,
  sendPaymentConfirm,
  getAllNotification,
  getAllTopups,
  buyTopup,
  createstripeaccount,
  verifyBankAccount,
  getStripeBankDetails,
  SendNotification_OfflinePayment
};
