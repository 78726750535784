import React, { useState } from 'react'
import profileI from "../Images/proffff.png";
import LenderModal from './Modal/LenderModal';

function LendersCard() {
    const [lendermodal,setLendermodal] =useState (false);
    const lendertoggleModal = () => {
        setLendermodal(true);
    }
    const loopdata = [
        { id: 1, name: "Mira Lipshutz", phone: "Phone: +01 123XXXXXX" },
        { id: 2, name: "Makenna Lubin", phone: "Phone: +01 123XXXXXX" },
        { id: 3, name: "Makenna Lubin", phone: "Phone: +01 123XXXXXX" },
      ];
    return (
        <>
            <section className='Lenders_card'>
                <div className='topsec'>
                    <div className='head'>
                        <h4>Lenders</h4>
                    </div>
                    <div className='Seemore_btn'>
                        <button className='btn' >See more</button>
                    </div>
                </div>
                {loopdata.map((profile) => (
                    <div className='profilecardbox'>
                        <div className='left'>
                            <div className='profilepic'>
                                <img src={profileI} className='img-fluid' alt='profile' />
                            </div>
                            <div className='details'>
                                <h4 className='name'>{profile.name}</h4>
                                <p className='text'>{profile.phone}</p>
                            </div>
                        </div>

                        <div className='right'>
                            <button className='btn Viewprofile_btn' onClick={lendertoggleModal}>View  profile</button>
                        </div>
                    </div>
                ))}
            </section>
          {lendermodal &&<LenderModal closeModal={setLendermodal}/>}
        </>
    )
}

export default LendersCard