import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import "./Custom.css";
import "../src/Component/Modal/Modal.css";
import "../src/View/Subscription/Subscrip.css";
import MainLayout from "./Layout/MainLayout";
import Homepage from "./View/HomePage/Index";
import Project from "./View/Project/Index";
import Login from "./Authentication/Login";
import { Toaster } from "react-hot-toast";
import PrivateRoute from "./privateRouter/PrivateRoute";
import { useDispatch } from "react-redux";
import CreateAccount from "./Authentication/CreateAccount";
import OtpVerification from "./Authentication/OtpVerification";
import AccountCreation from "./Authentication/AccountCreation";
import Index from "./View/Project/Index";
import { setUser } from "./Redux/Reducer/User";
import { useSelector } from "react-redux";
import ProjectSingle from "./View/HomePage/ProjectSingle";
import BidProjectDetails from "./View/HomePage/BidProjectDetails";
import Myjob from "./View/MyJob/Index";
import Applicationdetails from "./View/MyJob/Applicationdetails";
import SingleProjectDetails from "./View/HomePage/SingleProjectDetails";
import SubscripPage from "./View/Subscription/Subscripindex";
import ChartScreen from "./View/HomePage/ChartPage";
import ChatScreen from "./Chat/ChatScreen";
import { SocketConnect } from "./Socket/Socket";
import ProfileDetails from "./Component/Profile/ProfileDetails";
import Payment from "./Component/Payment";
import Proposal from "./View/Proposal/Proposal";
// import UpdateService from "./Component/Profile/UpdateService";
import ViewProjectDetails from "./View/Proposal/ViewProjectDetails";
import UpdateServiceNew from "./Component/Profile/UpdateServiceNew";
import Settings from "./View/settings/Settings";
import Changepass from "./View/Changepass/Changepass";
import TermsAndCondition from "./View/termsandCondition/TermsAndCondition";

// import Privacy from './View/PrivacyPolicy/privacy'
import Wallet from "./View/Wallet/Wallet";
// import SingleProjectDetails from "";
import Privacy from "./View/PrivacyPolicy/privacy";
import ForgetPass from "./Authentication/ForgetPass";
import { getAllNotificatios } from "./Redux/Reducer/NotificationSlice";
import ShowAllBidder from "./Component/ShowAllBidder";

function App() {
  const { login_status } = useSelector((state) => state.User);
  const userData = useSelector((state) => state.User.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userParse = JSON.parse(user);
    console.log("user from localstorage", userParse);

    if (userParse != null) {
      dispatch(setUser(userParse));
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    dispatch(getAllNotificatios());
  }, []);

  useEffect(() => {
    if (login_status) {
      SocketConnect({ id: userData._id });
    }
  }, [login_status]);

  useEffect(() => {
    console.log("logout", login_status, isLoading);
    if (!isLoading && !login_status) {
      navigate("/login");
    }

    // if (!login_status) {
    //   navigate("/login");
    // }
  }, [login_status, isLoading]);

  return (
    <React.Fragment>
      <Toaster
      // position="top-center"
      // reverseOrder={false}
      />
      <Routes>
        {login_status ? (
          <Route element={<MainLayout />}>
            <Route path="/" element={<Homepage />} />
            <Route path="/project-detail/:id" element={<Project />} />
            <Route path="/post-detail/:id" element={<ProjectSingle />} />
            <Route
              path="/single-project/:id"
              element={<SingleProjectDetails />}
            />
            <Route
              path="/single-projectdetails/:id"
              element={<ViewProjectDetails />}
            />
            <Route path="*" element={<h1>404</h1>} />
            <Route
              path="/bidProject-details/:id"
              element={<BidProjectDetails />}
            />
            <Route path="/Allbiders/:id" element={<ShowAllBidder />} />
            <Route path="/myjob" element={<Myjob />} />
            <Route
              path="/application_details/:bid/:post"
              element={<Applicationdetails />}
            />

            <Route path="/subscription" element={<SubscripPage />} />
            <Route path="/chart-screen/:id" element={<ChartScreen />} />
            <Route path="/chat-screen" element={<ChatScreen />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/Proposal" element={<Proposal />} />
            <Route path="/Settings" element={<Settings />} />
            <Route path="/profiledetails" element={<ProfileDetails />} />
            <Route path="/Changepass" element={<Changepass />} />
            <Route path="/termsAndcondition" element={<TermsAndCondition />} />

            <Route path="/Privacy" element={<Privacy />} />
            <Route path="/Wallet" element={<Wallet />} />
          </Route>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/ForgetPass" element={<ForgetPass />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/otp-verification" element={<OtpVerification />} />
            <Route path="/account-creation" element={<AccountCreation />} />
          </>
        )}

        {/* <Route path="/update_service" element={<UpdateService />} /> */}
        <Route path="/update_service" element={<UpdateServiceNew />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
