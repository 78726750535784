import React, { useState } from "react";
import Sidebar from "./Sidebar";
import profileP from "../Images/profile-pic.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { removeUser } from "../Redux/Reducer/User";
import { useDispatch } from "react-redux";
import AddProjects from "../../src/View/Project/AddProjects";
import HttpClient from "../utils/HttpClient";
import NotificationModal from "../Component/Modal/NotificationModal";
import toast from "react-hot-toast";
import AddownAddressModel from "../Component/Modal/AddownAddressModel";

const MainHeader = () => {
  const [notificationmodal, setNotificationmodal] = useState(false);
  const [CreatePostModal, setCreatePostModal] = useState(false);
  const OpenCreatePost = () => {
    setCreatePostModal(!CreatePostModal);
  };

  const { userData } = useSelector((state) => state.User);
  const [Searchmodal, setSearchmodal] = useState(false);
  const [addOwnAddressflag, setaddOwnAddressflag] = useState(false);
  const localdata = localStorage.getItem("user");
  const userdata1 = JSON.parse(localdata);
  // const myuserData = JSON.parse(userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { AllNotifications } = useSelector(
    (state) => state.NotificationReducer
  );
  console.log("AllNotifications", AllNotifications.length);
  console.log("userData", userData);
  const logouthandeler = () => {
    localStorage.clear();

    dispatch(removeUser());
    // navigate("/login");
  };

  return (
    <>
      <section className="mainheader_sec">
        <button
          className="responsive_menu"
          id="responsiveMenu"
          // onClick={handClick}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
        <button className="responsSearch_btn">
          <i className="fa-solid fa-magnifying-glass"></i>
        </button>

        <div className="Search_box">
          <form className="form-inline">
            <input
              className="form-control"
              type="search"
              placeholder="Search Services"
              aria-label="Search"
            />
            <button className="Search_icon" type="submit">
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </form>
        </div>
        {/* login button */}
        {/* <div className="loginbtn">
          <button
            style={{ color: "red", borderRadius: "10px", width: "100px" }}
            onClick={() => {
              navigate("/login/");
            }}
          >
            {" "}
            Login
          </button>
        </div> */}
        <div className="rightcontent">
          {/* Create Post */}
          {userData?.jobRoleData?.filter((it) => it?.name == "Financer")
            ?.length == 0 ? (
            <div className="Createpost_btn">
              <button
                className="btn"
                // onClick={() => {
                //   navigate("/add-projects");
                // }}
                onClick={OpenCreatePost}
              >
                <i class="fa-solid fa-plus"></i> Service Request
              </button>
            </div>
          ) : (
            <div className="Createpost_btn"></div>
          )}
          <div className="actionBtn_wrap">
            <div className="notification_btn">
              <button
                className="btn"
                onClick={() => {
                  setNotificationmodal(true);
                }}
              >
                <i class="far fa-bell"></i>
              </button>
              <div className="active">
                {AllNotifications.length > 0 ? (
                  <i className="fa-solid fa-circle"></i>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* account Details */}
            <div className="Accountdetails">
              <div
                style={{ marginRight: "7px", width: "32px", height: "32px" }}
              >
                {!userData?.image ? (
                  <img
                    width={100}
                    height={100}
                    src={
                      "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    }
                    className="img-fluid"
                    alt="user"
                  />
                ) : (
                  // <img
                  //   src="https://www.rotarybangkok.org/wp-content/uploads/2017/11/user-placeholder.d2a3ff8.png"
                  //   className="img-fluid"
                  //   alt="user"
                  // />
                  <img src={userData?.image} className="img-fluid" alt="user" />
                )}
              </div>
              <div className="namearea">
                <div className="dropdown">
                  <Link
                    className="dropdown-toggle"
                    href="#"
                    id="accountDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <strong>{userData?.username}</strong>
                    <span>{userData?.email}</span>
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="accountDropdown"
                  >
                    {/* <Link
                      to={"/profiledetails"}
                      className="dropdown-item"
                      // style={{ fontWeight: "400" }}
                    >
                      Profile
                    </Link> */}
                    {/* <Link
                      to={"/update_service"}
                      className="dropdown-item"
                      style={{
                        display:
                          userdata1?.jobRole[0] == "64dca6a1a63f41ccadf38c3c"
                            ? "none"
                            : "block",
                        fontWeight: "400",
                      }}
                    >
                      Service
                    </Link> */}
                    {/* 
                    <h6 className="dropdown-item" style={{ cursor: "pointer" }}>
                      About
                    </h6>
                    <h6 className="dropdown-item" style={{ cursor: "pointer" }}>
                      Help
                    </h6>
                    <h6 className="dropdown-item" style={{ cursor: "pointer" }}>
                      Faq
                    </h6>
                    <h6 className="dropdown-item" style={{ cursor: "pointer" }}>
                      Terms & Condition
                    </h6>
                    <h6 className="dropdown-item" style={{ cursor: "pointer" }}>
                      Change Password
                    </h6>
                    <h6 className="dropdown-item" style={{ cursor: "pointer" }}>
                      Delete Account
                    </h6> */}
                    <Link
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        logouthandeler();
                      }}
                    >
                      Logout
                    </Link>
                    {/* <Link
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setaddOwnAddressflag(!addOwnAddressflag);
                      }}
                    >
                      Add own Address
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {Searchmodal && (
        <div className="responsiveSearch_box">
          <form className="form-inline">
            <input
              className="form-control"
              type="search"
              placeholder="Search here...."
              aria-label="Search"
            />
            <button
              className="Search_icon"
              type="submit"
              onClick={() => setSearchmodal(false)}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </form>
        </div>
      )}

      {notificationmodal && (
        <NotificationModal closeModal={setNotificationmodal} />
      )}

      {CreatePostModal && <AddProjects closeModal={setCreatePostModal} />}
      {/* {addOwnAddressflag && (
        <AddownAddressModel setaddOwnAddressflag={setaddOwnAddressflag} />
      )} */}

      <Sidebar />
    </>
  );
};

export default MainHeader;
