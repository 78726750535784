import React from "react";
import ConstructionProject from "../Component/ConstructionProject";
import { Link } from "react-router-dom";

function MyProject(props) {
  const { productData } = props;
  console.log("keekk", productData)
  return (
    <>
      <section className="myProject_sec">
        <div className="head_tag">
          {/* <div className="name">
            <h4>My Project</h4>
          </div> */}
          {/* <div className="SeeAll_btn">
            <button className="btn">See All</button>
          </div> */}
        </div>



        <div className="row">
          {productData?.map((item) => (
            <div className="col-lg-6 col-md-6 col-12">

              <Link to={`/project-detail/${item?._id}`} style={{ textDecoration: 'none' }}>
                <ConstructionProject item={item} />
              </Link>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default MyProject;
