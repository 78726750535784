import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import succ from "../../Images/check_img.avif";
import moment from 'moment';
import ProductServices from '../../services/ProductServices';
import { Document, Page } from 'react-pdf';

function PdfviewModel(props) {
    const { invurl, setpdfmodel, singleTaskData } = props
    console.log("nmg", invurl)

    const [invlink, setinvLink] = useState("")
    //fetch invoice
    const fetchingInvoice = async () => {
        ProductServices.ViewTSKiNV(
            singleTaskData?._id
            // route.params.TaskId,
            //   route.params.activityDetailsId,
        )
            .then(res => {
                console.log('res ----:":""', res);
                if (res && res.status) {
                    // invurl = res.data[0].invoiceUrl
                    setinvLink(res.data[0].invoiceUrl)
                    // getDownload(res.data[0].invoiceUrl);
                    // // setUrl(res.data[0].invoiceUrl);
                    // console.log('res.data==============',res.data[0]);
                }
            })
            .catch(err => {
                console.log('err124', err);
            });
    }
    useEffect(() => { fetchingInvoice() }, [])

    return (
        <>
            <section className="success">
                <div className="success_page">

                    <div className="success_title">
                        {/* <iframe
                            src={`https://docs.google.com/gview?url=${encodeURIComponent(invurl)}&embedded=true`}
                            title="PDF Viewer"
                            style={{ width: '100%', height: '500px', border: 'none' }}
                        ></iframe> */}

                        <a href={invlink} target="_blank" rel="noopener noreferrer">
                            Open PDF
                        </a>
                        {/* <Document
                            file={invurl}
                            options={{ workerSrc: "/pdf.worker.js" }}
                        // onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page pageNumber={1} />
                        </Document> */}

                    </div>
                    <div className="home_btn">
                        <button className="back_home_btn" onClick={() => {
                            // PayTaskValues();
                            // PaymentProceed()
                            setpdfmodel(false)
                        }
                        }>close</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PdfviewModel