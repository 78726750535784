import React, { useState } from 'react'
import profileIm from "../Images/profile-pic.png";
import SupplierModal from './Modal/SupplierModal';
import CommentModal from './Modal/CommentModal';
function SupplierCard() {
  const [modal,setModal]= useState(false);

  const toggleModal = () => {
    setModal(true);
  
    
  };
 
  const loopdata = [
    { id: 1, name: "Mira Lipshutz", details: "Plumber", phone: "Phone: +01 123XXXXXX" },
    { id: 2, name: "Makenna Lubin", details: "Carpenter", phone: "Phone: +01 123XXXXXX" },
    { id: 3, name: "Makenna Lubin", details: "Electrician", phone: "Phone: +01 123XXXXXX" },
  ];
  return (
    <>
      <section className='Supplier_Card'>
        <div className='topsec'>
          <div className='head'>
            <h4>Supplier</h4>
          </div>
          <div className='Seemore_btn'>
            <button className='btn' >See more</button>
          </div>
        </div>

        {loopdata.map((profile) => (
          <div className='profilecardbox'>
            <div className='left'>
              <div className='profilepic'>
                <img src={profileIm} className='img-fluid' alt='profile' />
              </div>
              <div className='details'>
                <h4 className='name'>{profile.name}</h4>
                <p className='text'>{profile.details}</p>
                <p className='text'>{profile.phone}</p>
              </div>
            </div>

            <div className='right'>
              <button className='btn Viewprofile_btn'  onClick={toggleModal} >View  profile</button>
            </div>
          </div>
        ))}

      </section>
      {modal &&<SupplierModal closeModal={setModal}/>}
       
   
    </>
  )
}

export default SupplierCard