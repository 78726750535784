import React, { useState } from "react";
import projectimg from "../Images/project1.png";
import profile1 from "../Images/profile-pic.png";
import HttpClient from "../utils/HttpClient";
import moment from "moment";
function ConstructionProject({ item }) {
  console.log("baler item", item);

  const task_persentage = item?.taskData[0]?.pendingTaskCount > 0 ?
    (item?.taskData[0]?.completeTaskCount) / (item?.taskData[0]?.pendingTaskCount) * 100 : 0
  const completecount = item?.taskData.reduce((sum, ele) => {
    if (ele?.completedStatus === "Complete") {
      sum = sum + 1;
    }
    return sum; // Return the accumulator in each iteration
  }, 0)
  const totallength = item?.taskData.length
  const percentage = (completecount / totallength) * 100
  console.log("hdmjhsdj", totallength,)
  return (
    <>
      <div className="projectwrapcard_box">
        <div className="img_wrap">
          {/* <img src={projectimg} className='img-fluid' alt='project' /> */}
          <img
            src={item?.image[0]}
            className="img-fluid"
            alt="project"
          />

          <div className="status">
            <button className="btn">Show</button>
          </div>
        </div>
        <div className="content_area">
          <div className="name">
            <h4>{item?.name.slice(0, 100)}</h4>
          </div>
          <div className="michaelpeople">
            <p>
              {item?.projectData?.name} - {moment(item?.createdAt).format("LL")}
            </p>
            <div className="d-flex align-items-center">
              {/* <div className='img_wrap'>
                                <img src={profile1} className='img-fluid' alt='profile' />
                            </div>
                            <div className='img_wrap'>
                                <img src={profile1} className='img-fluid' alt='profile' />
                            </div>
                            <div className='img_wrap'>
                                <img src={profile1} className='img-fluid' alt='profile' />
                            </div> */}
            </div>
          </div>
          {/* <div style={{margin:'10px 0px 10px'}}>
                    {
                        item?.categories.map((it,ind)=>{
                            return(
                                <span style={{color:'#066DE6'}} key={ind}>{it},</span>
                            )
                        })
                        
                    }
                    </div> */}
          <div className="progress">
            <div className="progress-done" style={{ width: `${percentage ? percentage : 0}%` }}>
              {percentage ? percentage.toFixed(2) : 0} %
            </div>
          </div>


          <div className='bottom_area'>
            <div className='left'>
              <div className='d-flex align-items-center'><span>{completecount}
              </span> / <span>{totallength}</span> <p>Total Tasks</p></div>
            </div>

            <div className='right'>
              <div className='d-flex align-items-center'>

                <p>{task_persentage}%</p>
              </div>
            </div>
          </div>

          <div className="bottom_area">
            <div className="left">
              <div className="d-flex align-items-center"> Bidded Cost</div>
            </div>

            <div className="right">
              <div className="d-flex align-items-center">
                <span>£ {item?.startCost} - {item?.endCost}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConstructionProject;
