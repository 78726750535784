import React, { useEffect, useState } from "react";
import WelcomeJerremy from "../../Component/WelcomeJerremy";
import MyProject from "../../Component/MyProject";
import Recentlybiddedproject from "../../Component/Recentlybiddedproject";
import PaymentDetailsCard from "../../Component/PaymentDetailsCard";
import SupplierCard from "../../Component/SupplierCard";
import LendersCard from "../../Component/LendersCard";
import Projecttrello from "../../Component/Projecttrello";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CustomLoader from "../../Component/loader/CustomLoader";
import { reactLocalStorage } from "reactjs-localstorage";
import ProductServices from "../../services/ProductServices";
import { useParams } from "react-router-dom";
import { getAllProduct, getProduct } from "../../Redux/Reducer/ProductSlice";
import { SocketConnect } from "../../Socket/Socket";
import { getAllNotificatios } from "../../Redux/Reducer/NotificationSlice";

function Index() {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.User);
  const [project, setproject] = useState([]);
  const [myproject, setMyProject] = useState([]);

  const { productData, loading, AllProduct } = useSelector(state => state.productReducer)
  const isFinance = userData?.jobRole?.some(it => it == "65531d1de9465ce15d48648e")

  useEffect(() => {
    dispatch(getProduct());
    dispatch(getAllProduct());
    getAllProjects();
    getMyProjects();
    dispatch(getAllNotificatios())
  }, []);

  console.log('AllProrrduct', AllProduct,)

  const getAllProjects = () => {
    ProductServices.getallProject()
      .then((res) => {
        // console.log("baler res", res);
        if (res && res.status) {
          setproject(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMyProjects = () => {
    ProductServices.getAssignProduct()
      .then((res) => {
        console.log("baler amar project res", res);
        if (res && res.status) {
          setMyProject(res.data);
        }
      })
      .catch((err) => { });
  };

  const getroledata = () => {
    // console.log('roledata',userData?.jobRoleData?.findIndex(it=>it.name == 'Homeowner'))
    if (
      userData?.jobRoleData?.length == 1 &&
      userData?.jobRoleData?.findIndex((it) => it.name == "Homeowner") == 0
    ) {
      return "Homeowner";
    } else {
      return null;
    }
  };
  // return false;
  return (
    <>
      <CustomLoader loading={loading} />
      <div className="main_wrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">

              <div className="middlepartwrap">
                {/* <WelcomeJerremy /> */}
                {/* {myproject?.length > 0 ? (
                  <MyProject productData={productData} />
                ) : null} */}

                <Recentlybiddedproject
                  productData={AllProduct}
                  userData={userData}
                  getroledata={() => getroledata()}
                  isFinance={isFinance}
                />
              </div>

            </div>
            <div className="col-lg-4 col-md-4 col-12 pr-0">
              <div className="rightsidebar">
                {/* <PaymentDetailsCard
                  Payment="Payment Received"
                  text="Lorem ipsum dolor sit amet consectetur"
                  amout="£30,000"
                  btnname="View Details"
                  bgcolor="#04B600"
                  Receivedbtn={true}
                />
                <PaymentDetailsCard
                  Payment="Payment Due"
                  text="Lorem ipsum dolor sit amet consectetur"
                  amout="£25,000"
                  btnname="View Details"
                  bgcolor="#7F6DF0"
                  Duebtn={true}
                /> */}

                <SupplierCard />

                <LendersCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
