import React, { useEffect, useRef, useState } from "react";
import "./profileUpdate.css";
// import "../../Authentication/Login.css"
import { toast } from "react-hot-toast";
import HttpClient from "../../utils/HttpClient";
import AuthServices from "../../services/AuthServices";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../loader/CustomLoader";
import OtpVerification from "../../Authentication/OtpVerification";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import country from "../../Authentication/CountryCode";

import logo from "../../logo.svg";
import userPic from "../../Images/dp2.jpeg"
import imguploadicon from "../../Images/file-upload-icon.jpg"

import { useFormik } from "formik";
import * as Yup from "yup";
import { MultiSelect } from "react-multi-select-component";
import LendersCard from "../LendersCard";
const ProfileDetails = () => {
    const localdata = localStorage.getItem("user")
    const userdata = JSON.parse(localdata)
    const navigate = useNavigate()
    console.log("userdata", userdata)




    const [image, setImage] = useState(userdata?.image ?? userPic);

    // const [name, setName] = useState(userdata?.username ? userdata?.username : "")
    const [name, setName] = useState(userdata.username ?? '');
    const [phno, setPhno] = useState(userdata?.phone ? userdata?.phone : "")
    const [selectedRole, setSelectedRole] = useState(userdata?.jobRole ?? []);
    console.log("hgsdjg", selectedRole, userdata?.jobRole)
    const [roles, setRoles] = useState([]);
    console.log("rolestt", roles)
    const [updateModal, setUpdateModal] = useState(false);
    // const [postCode, setpostCode] = useState(userdata?.postCode ? userdata?.phone : "")
    const [] = useState("")
    const [emailid, setemailid] = useState(userdata?.email ? userdata?.email : "")
    const [jobrole, setjobrole] = useState(userdata?.roleData.name ? userdata?.roleData.name : "")
    const [selected, setSelected] = useState(userdata.categories.length > 0 ?
        userdata.categories?.map((label) => ({
            label,
            value: label,
        })) : []);
    const keySkill = [
        "Soundproofing",
        "Air Conditioning Installation",
        "Door Installation",
        "Garage Installation",
        "Garage Door Repairs",
        "Kitchen Renovation",
        "Bathroom Remodeling",
        "Installing New Flooring",
        "Painting (Interior/Exterior)",
        "Replacing Windows or Doors",
        "Roof Repair or Replacement",
        "Double Glazing",
        "Adding Insulation",
        "Upgrading Electrical or Plumbing Systems",
        "Building a New Room",
        "Loft Conversions",
        "Home Office and Annex",
        "Adding a Sunroom or Conservatory",
        "Expanding the Garage",
        "Building a Deck or Patio",
        "Constructing a New Porch",
        "Extending the Living Space",
        "Building an Additional Floor",
        "Constructing a New House",
        "Lawn Care and Maintenance",
        "Garden Cleanup",
        "Shed Creation",
        "Outdoor Lighting",
        "Irrigation and Sprinkler Systems",
        "Develop a site",
        "other",
    ];
    const options = keySkill.map((label) => ({
        label,
        value: label,
    }));
    const [flag, setflag] = useState(true)
    useEffect(() => {
        getAlldatas()
    }, [])

    useEffect(() => {
        getRoles()

    }, [])
    //get users data:
    const getAlldatas = async () => {
        const res = await AuthServices.getUserDatas()

        if (res && res.status) {
            console.log("jhhgdhgh", res.data[0].image)
            if (res.data[0].image) {
                setImage(res.data[0].image)
            }

            setName(res.data[0].username)
            setPhno(res.data[0].phone)
            setemailid(res.data[0].email)
            setSelectedRole(res.data[0].jobRole)
        } else {
            // toast.error("Server Error")
        }


        // setSelected(temparr)
        // console.log("jkhdgdhkg", res)
    }
    const handleImage = async (e) => {
        // setIsLoading(true)

        let arr = [];
        let file = e.target.files;

        for (let element of file) {

            let data = new FormData();
            data.append("img", element);
            let res = await HttpClient.fileUplode("project-image", "POST", data);

            if (res && res?.status) {
                toast.success("Uploaded Successfully");
                console.log("imagesrr", res);
                //   arr.push(res?.data);
                setImage(res.data)

            } else {
                toast.error(res?.message);
            }

        }


    };

    const getRoles = async () => {
        let res = await AuthServices.getRoles();

        setRoles(res?.data.filter((ele) => ele._id != "65531d1de9465ce15d48648e"));
    }
    const update_validate = () => {
        if (name == "") {
            toast.error("Please enter your name");

            return false
        } else if (phno == "") {
            toast.error("Please enter your phone number");

            return false

        } else if (selectedRole == "") {
            toast.error("Please Select your Role");

            return false
        } else {
            // let res = await AuthServices.getRoles();
            // setRoles(res?.data);
            // setUpdateModal(!updateModal);
            // alert("1")
            return true;
        }


    }
    const handleUpdate2 = () => {

        if (update_validate()) {



            let data = {
                // ServiceType: selected.map((ele, id) => ele.value),
                // areaMiles: Area,
                // postCode: Number(PostCode),
                image: image,
                username: name,
                // companyName: name2,
                email: emailid,
                phone: Number(phno),
                jobRole: selectedRole,
                // crn: crn,
                // website: website,
            };

            AuthServices.profileUpdate(data)
                .then((res) => {
                    // setLoderStatus(false);
                    if (res && res.status) {
                        toast.success("Profile sucsessfully updated ")
                        // window.history.back();
                        getAlldatas()
                    }
                    console.log("res", res);
                })
                .catch((err) => {
                    // setLoderStatus(false)

                });
        }
        else {

        }

    };


    const handleRoleChange = (e) => {
        if (e.target.checked) {
            if (!selectedRole.includes(e.target.value)) {
                setSelectedRole([...selectedRole, e.target.value]);
            }
        } else {
            if (selectedRole.includes(e.target.value)) {
                let index = selectedRole.indexOf(e.target.value);
                let newRole = [...selectedRole];
                newRole.splice(index, 1);
                setSelectedRole([...newRole]);
            }
        }
    };

    const fileInputRef = useRef(null);

    // const handleImage = (e) => {
    //   // Handle image upload logic here
    //   console.log('Image uploaded:', e.target.files[0]);
    // };

    const openFileInput = () => {
        // Trigger a click on the file input when the label is clicked
        // if (fileInputRef.current) {
        fileInputRef.current.click();
        // }
    };

    return (



        <>
            <div className="main_wrap">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            {/* <div className="middlepartwrap"> */}
                            {/* <div className="job_dtls"> */}
                            {/* <div className="posted_icon_txt_main"> */}
                            <Link onClick={() => window.history.back()} to="/" className="posted_icon">
                                <i className="fa-solid fa-arrow-left"></i>

                            </Link>
                            {/* </div> */}
                            <div className="login-page">
                                <div className="logincard">

                                    {/* <div className='close_btn' onClick={() => { window.history.back(); }}>
    <i className="fa-solid fa-xmark"></i>
</div> */}

                                    <h4 style={{ display: "flex", justifyContent: "center" }}>Profile Details</h4>
                                    <div className="logoarea">
                                        <img src={image} style={{ borderRadius: "60%", }} alt="" />
                                        <label htmlFor="image" style={{ cursor: 'pointer' }} onClick={() => openFileInput}>
                                            {/* <img src={imguploadicon} style={{
                                                borderRadius: '20%', height: '40%', width:
                                                    '40%', float: 'right'
                                            }} alt="" /> */}
                                            <span className="uploadCamera"><i class="fa-solid fa-camera"></i></span>
                                        </label>
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            accept="image/*"
                                            onChange={(e) => handleImage(e)}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }} // Hide the default file input style
                                        />
                                    </div>


                                    <div className="form">

                                        <div className="form-group">
                                            <label htmlFor="">User Name</label>

                                            <input
                                                type="text"

                                                placeholder="Enter your full name"
                                                name="username"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="">Phone No</label>

                                            <input

                                                type="text"
                                                placeholder="Enter your full name"
                                                name="username"
                                                value={phno}
                                                onChange={(e) => setPhno(e.target.value)}
                                            />
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="">Email Id</label>

                                            <input
                                                disabled
                                                type="text"
                                                placeholder="Enter your full name"
                                                name="username"
                                                value={emailid}
                                            // onChange={(e) => setWebsite(e.target.value)}
                                            />
                                        </div>


                                        <label>Job Role</label>
                                        <div className="modalcontent cust_modal">


                                            {/* <select>
            {roles.map((ele, item) => {
                return (
                    <option value={ele?._id}>{ele.name}</option>
                )
            })}
        </select> */}


                                            {roles.map((item, index) => {
                                                console.log("items", selectedRole, selectedRole.includes(item._id));
                                                return (
                                                    <div className="cardbox">
                                                        <div className="form-check" key={index}>
                                                            {/* <input
                            type="checkbox"
                            className="form-check-input"
                            name="jobRole"
                            value={item._id}
                            onChange={handleRoleChange}
                            id=""
                        /> */}
                                                            <input type="checkbox"
                                                                name="jobRole"
                                                                className="form-check-input"
                                                                id="exampleCheck1"
                                                                checked={selectedRole.includes(item._id)}
                                                                value={item._id}
                                                                onChange={handleRoleChange} />

                                                            <label className="form-check-label" htmlFor="">
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                        </div>

                                        <button
                                            className="loginhere"
                                            style={{ width: "30%", float: "right" }}
                                            onClick={() => { handleUpdate2() }}
                                            type="button"
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>



                            {/* </div> */}
                            {/* </div> */}
                        </div>


                    </div>
                </div>
            </div>





        </>
        // </div>
    );
}

export default ProfileDetails;
