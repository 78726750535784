import React from 'react'
import { useNavigate, useNavigation } from 'react-router-dom';
import succ from "../../Images/check_img.avif";
import { removeUser } from '../../Redux/Reducer/User';
import { useDispatch } from 'react-redux';
import AuthServices from '../../services/AuthServices';

function DeleteAccountModel(props) {
    const { setdeletModal } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logouthandeler = () => {
        localStorage.clear();
        dispatch(removeUser());
        navigate("/login");
    };
    async function deleteAccount() {

        AuthServices.deleteUser()
            .then(res => {
                console.log("res", res);
                if (res && res.status) {

                    logouthandeler()
                }
            })
            .catch(err => {
                console.log("err", err);
            })


    }
    return (
        <>
            <section className="success">
                <div className="success_page">

                    <div className="success_title">
                        <p>Do you want to delete your account</p>

                    </div>
                    <div className="home_btn" style={{ display: "flex", justifyContent: "space-between" }} >
                        <button className="back_home_btn" onClick={() => { setdeletModal(false) }}>No</button>
                        <button className="back_home_btn" onClick={() => { deleteAccount() }} style={{ backgroundColor: "red" }}>Yes</button>
                    </div>



                </div>
            </section>
        </>
    )
}

export default DeleteAccountModel