import React, { useEffect, useState } from "react";
import "./login.css";
import { toast } from "react-hot-toast";
import HttpClient from "../utils/HttpClient";
import AuthServices from "../services/AuthServices";
import { reactLocalStorage } from "reactjs-localstorage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../Component/loader/CustomLoader";
import OtpVerification from "./OtpVerification";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import country from "./CountryCode";
import logo from "../Images/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MultiSelect } from "react-multi-select-component";
const AccountCreation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedemail = useSelector((state) => state.User.useremail);
  const location = useLocation();
  console.log("location", location.state);
  // console.log('fucking email',storedemail)

  const initialValues = {
    email: "",
    phone: "",
    password: "",
    conPassword: "",
    phone_country_code: "",
    primary_register_using: "",
    username: "",
    jobRole: "",
  };

  console.log("country", country);

  const [formValue, setFormValue] = useState(initialValues);
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showpass, setShowPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const defaultcountrycode = {
    name: "United Kingdom",
    flag: "🇬🇧",
    code: "GB",
    dial_code: "+44",
  };

  console.log("lhkhkjh", selectedRole);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [pass, setPass] = useState("");
  const [confirmpass, setConfirmPass] = useState("");
  const [PasswordFocus, setPasswordFocus] = useState(false);

  //..for updating account with job role

  const [regFlag, setregFlag] = useState(false);
  const [succMsg, setsuccMsg] = useState("");
  const [name2, setName2] = useState("");
  const [crn, setCRN] = useState("");
  const [website, setWebsite] = useState("");
  console.log("regFlag", regFlag);
  const [selected, setSelected] = useState([]);
  console.log("jjldd", selected);
  const keySkill = [
    "Soundproofing",
    "Air Conditioning Installation",
    "Door Installation",
    "Garage Installation",
    "Garage Door Repairs",
    "Kitchen Renovation",
    "Bathroom Remodeling",
    "Installing New Flooring",
    "Painting (Interior/Exterior)",
    "Replacing Windows or Doors",
    "Roof Repair or Replacement",
    "Double Glazing",
    "Adding Insulation",
    "Upgrading Electrical or Plumbing Systems",
    "Building a New Room",
    "Loft Conversions",
    "Home Office and Annex",
    "Adding a Sunroom or Conservatory",
    "Expanding the Garage",
    "Building a Deck or Patio",
    "Constructing a New Porch",
    "Extending the Living Space",
    "Building an Additional Floor",
    "Constructing a New House",
    "Lawn Care and Maintenance",
    "Garden Cleanup",
    "Shed Creation",
    "Outdoor Lighting",
    "Irrigation and Sprinkler Systems",
    "Develop a site",
    "other",
  ];
  const options = keySkill.map((label) => ({
    label,
    value: label,
  }));

  const handleUpdate2 = () => {
    // alert("new");

    let data = {
      // ServiceType: FinalCategoryData.filter(i => i.selected).map(it => it.name),
      categories: selected.map((ele, id) => ele.value),
      // areaMiles: Area,
      // postCode: Number(PostCode),
      // cName: name2,
      companyName: name2,
      // cEmail: EmailAddress,
      // cPhoneNo: Number(MobileNumber),
      crn: crn,
      website: website,
    };

    AuthServices.profileUpdate(data)
      .then((res) => {
        // setLoderStatus(false);
        if (res && res.status) {
          // alert("done");
          toast.success(succMsg);
          navigate("/login/");
          // dispatch(setuser(route.params.data));
          // AuthService.setAccount(route.params.data);
        }
        console.log("res", res);
      })
      .catch((err) => {
        // setLoderStatus(false)
        // console.log('err', err);
      });
    console.log("keySkillee", data);
  };

  //..........

  function isPasswordValid(password) {
    const validationPoints = [];

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      validationPoints.push({
        isValid: false,
        error: "Password must contain at least one lowercase letter",
      });
    } else {
      validationPoints.push({
        isValid: true,
        error: "Password must contain at least one lowercase letter",
      });
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      validationPoints.push({
        isValid: false,
        error: "Password must contain at least one uppercase letter",
      });
    } else {
      validationPoints.push({
        isValid: true,
        error: "Password must contain at least one uppercase letter",
      });
    }

    // Check for at least one number (0-9)
    if (!/[0-9]/.test(password)) {
      validationPoints.push({
        isValid: false,
        error: "Password must contain at least one number",
      });
    } else {
      validationPoints.push({
        isValid: true,
        error: "Password must contain at least one number",
      });
    }

    // Check for at least one special character (!@#$%^&*)
    if (!/[!@#$%^&*]/.test(password)) {
      validationPoints.push({
        isValid: false,
        error:
          "Password must contain at least one special character (!@#$%^&*)",
      });
    } else {
      validationPoints.push({
        isValid: true,
        error:
          "Password must contain at least one special character (!@#$%^&*)",
      });
    }

    // Check if the password is at least 8 characters long
    if (password.length < 8) {
      validationPoints.push({
        isValid: false,
        error: "Password must be at least 8 characters long",
      });
    } else {
      validationPoints.push({
        isValid: true,
        error: "Password must be at least 8 characters long",
      });
    }

    return validationPoints;
  }
  const cheakPassword = isPasswordValid(pass).every(
    (item) => item.isValid == true
  );
  // handle change form
  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setFormValue((prev) => {
      return { ...prev, [name]: value };
    });
  };

  console.log("selectedRole", selectedRole);

  const handleUpdateModal = async () => {
    console.log("formValue?.username", name, phone, pass);
    if (name == "") {
      return toast.error("Please enter your name");
    } else if (phone == "") {
      return toast.error("Please enter your phone number");
    } else if (pass == "") {
      return toast.error("Please enter your password");
    } else if (confirmpass == "") {
      return toast.error("confirm your password");
    } else if (pass !== confirmpass) {
      return toast.error("password and confirm password not matched");
    } else {
      let res = await AuthServices.getRoles();
      setRoles(res?.data);
      setUpdateModal(!updateModal);
    }
  };
  const handleUpdateModalgoogle = async () => {
    console.log("formValue?.username", name, phone, pass);
    if (name == "") {
      return toast.error("Please enter your name");
    } else if (phone == "") {
      return toast.error("Please enter your phone number");
    } else {
      let res = await AuthServices.getRoles();
      setRoles(res?.data);
      setUpdateModal(!updateModal);
    }
  };
  // change role selection
  const handleRoleChange = (e) => {
    if (e.target.checked) {
      if (!selectedRole.includes(e.target.value)) {
        setSelectedRole([...selectedRole, e.target.value]);
      }
    } else {
      if (selectedRole.includes(e.target.value)) {
        let index = selectedRole.indexOf(e.target.value);
        let newRole = [...selectedRole];
        newRole.splice(index, 1);
        setSelectedRole([...newRole]);
      }
    }
  };

  function checkingRole(selectedRoles, idsToCheck) {
    for (let roleId of idsToCheck) {
      if (!selectedRoles.includes(roleId)) {
        return false;
      }
    }
    return true;
  }

  // handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const sendData = {
      email: location.state.google ? location.state.emailId : storedemail,
      password: pass,
      // conPassword: formValue?.conPassword,
      phone: phone,
      phone_country_code: countryCode,
      primary_register_using: location.state.google
        ? location.state.emailId
        : storedemail,
      username: name,
      jobRole: selectedRole,
    };

    // if (
    //   selectedRole.some(
    //     (it) =>
    //       it == "64dca6a1a63f41ccadf38c40" || it == "64e7d68519180fe26e3b0d66"
    //   )
    // ) {
    //   alert("contactor");
    //   return;
    // } else {
    //   alert("home owner");
    //   return;
    // }

    // if (!validate()) return;
    setLoading(true);

    const res = await AuthServices.userRegister(sendData);
    console.log("registrationrr", selectedRole);
    // alert("ghgjh");

    if (res && res?.status) {
      let tokenData = { token: res?.data?.[0]?.token };
      let userId = { userId: res?.data?.[0]?._id };
      localStorage.setItem("constructionToken", JSON.stringify(tokenData));
      localStorage.setItem("user", JSON.stringify(res.data[0]));
      HttpClient.requestData2("customer", "POST", {
        name: name,
        email: location.state.google ? location.state.emailId : storedemail,
      })
        .then((stripeData) => {
          console.log("stripeData", stripeData);
        })
        .catch((err) => {
          console.log("Login error", err);
        });
      toast.success(res?.message);
      setsuccMsg(res?.message);
      setLoading(false);
      // ....new key skillpage open
      if (
        selectedRole.some(
          (it) =>
            it == "64dca6a1a63f41ccadf38c40" || it == "64e7d68519180fe26e3b0d66"
        )
      ) {
        setregFlag(true);
      } else {
        navigate("/login/");
      }

      // if (checkingRole(selectedRole, rolesToCheck)) {
      //   setregFlag(true);
      // } else {
      //   navigate("/login/");
      // }

      // if (selectedRole.some((role) => selectedRole.includes(role))) {
      //   setregFlag(true);
      //   alert("jjj");
      // } else {
      //   navigate("/login/");
      // }
    } else {
      toast.error(res?.message);
      setLoading(false);
    }
  };

  if (regFlag) {
    return (
      <div className="login-page">
        <div className="logincard">
          {/* <div className="service_input"> */}
          <label>what service do you provide?</label>
          <form>
            <div className="service_input">
              <MultiSelect
                options={options}
                value={selected}
                onChange={(e) => {
                  if (e.length > 2) {
                    toast.error("You can select upto 2 Services");
                    return;
                  } else {
                    setSelected(e);
                  }
                }}
                labelledBy="Select"
              />
            </div>
          </form>
          {/* </div> */}
          <div className="form">
            <div className="form-group">
              <label htmlFor="">Company Name(optional)</label>

              <input
                type="text"
                placeholder="Enter your full name"
                name="username"
                value={name2}
                onChange={(e) => setName2(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="">CRN No</label>

              <input
                type="text"
                placeholder="Enter your full name"
                name="username"
                value={crn}
                onChange={(e) => setCRN(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Website</label>

              <input
                type="text"
                placeholder="Enter your full name"
                name="username"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
            <button
              className="loginhere"
              style={{ width: "30%", float: "right" }}
              onClick={(e) => {
                handleUpdate2(e);
              }}
              type="button"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div>
          <CustomLoader loading={loading} />
          <div className="login-page">
            <div className="logincard">
              <div className="logoarea">
                <img src={logo} className="img-fluid" alt="" />
              </div>

              <div className="form">
                <h2 className="head">Create Account</h2>
                <form className="login-form">
                  {/* full name */}
                  <div className="form-group">
                    <label htmlFor="">Full Name</label>
                    {formError ? <p>{formError}</p> : ""}
                    <input
                      type="text"
                      placeholder="Enter your full name"
                      name="username"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      // onKeyDown={(event) => {
                      //   const key = event.key;
                      //   if (!/[a-zA-Z ]/.test(key)) {
                      //     event.preventDefault();
                      //   }
                      // }}
                    />
                    {/* <p style={{color:'red'}}>{errors.username}</p> */}
                  </div>

                  {/* Phone Number */}
                  <div className="form-group">
                    <label htmlFor="">Phone Number</label>
                    <div className="country-code d-flex">
                      <select
                        name="flag"
                        id="flag"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                      >
                        <option
                          style={{ display: countryCode ? "none" : "block" }}
                          value={defaultcountrycode?.dial_code}
                        >
                          {defaultcountrycode?.flag}
                          {defaultcountrycode?.name}
                          {defaultcountrycode?.dial_code}
                        </option>
                        {country?.map((item, index) => {
                          return (
                            <>
                              <option value={item?.dial_code}>
                                {item?.flag}
                                {item?.name}
                                {item?.dial_code}
                              </option>
                            </>
                          );
                        })}
                      </select>

                      <div style={{ position: "relative", width: "100%" }}>
                        <input
                          type="number"
                          placeholder="Enter phone number"
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />

                        <div className="countvalue">
                          <p>{countryCode}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {location.state.google ? (
                    <div className="form-group">
                      <label htmlFor="">Email</label>
                      <input
                        type={"text"}
                        placeholder="Enter Password"
                        name="password"
                        value={location.state.emailId}
                        disabled={true}
                      />
                    </div>
                  ) : null}
                  {/* Password */}
                  {location.state.google ? null : (
                    <>
                      <div className="form-group">
                        <label htmlFor="">Password</label>
                        <div style={{ position: "relative" }}>
                          <input
                            type={showpass ? "text" : "password"}
                            placeholder="Enter Password"
                            name="password"
                            value={pass}
                            onChange={(e) => setPass(e.target.value)}
                            // onKeyDown={(event) => {
                            //   const currentValue = event.target.value;
                            //   const key = event.key;

                            //   // Allow Backspace and Delete keys
                            //   if (key === "Backspace" || key === "Delete") {
                            //     return;
                            //   }

                            //   // Test if the pressed key is numeric
                            //   if (!/^[0-9]*$/.test(key)) {
                            //     event.preventDefault();
                            //   }

                            //   // If the input already contains non-numeric characters, prevent input
                            //   if (!/^[0-9]*$/.test(currentValue)) {
                            //     event.preventDefault();
                            //   }
                            // }}
                            onFocus={() => setPasswordFocus(true)}
                          />
                          {!cheakPassword && PasswordFocus ? (
                            <div style={{ marginHorizontal: "15px" }}>
                              {isPasswordValid(pass).map((item) => (
                                <h6 style={{ color: "red" }}>{item.error}</h6>
                              ))}
                            </div>
                          ) : null}
                          <div className="eyebtn">
                            {showpass ? (
                              <i
                                onClick={() => setShowPass(false)}
                                class="fa-solid fa-eye"
                              ></i>
                            ) : (
                              <i
                                onClick={() => setShowPass(true)}
                                class="fa-solid fa-eye-slash"
                              ></i>
                            )}
                          </div>
                        </div>
                        {/* <p style={{color:'red'}}>{errors.password}</p> */}
                      </div>

                      {/* Confirm Password */}
                      <div className="form-group">
                        <label htmlFor="">Confirm Password</label>
                        <div style={{ position: "relative" }}>
                          <input
                            type={showConPass ? "text" : "password"}
                            placeholder="Confirm your password"
                            name="conPassword"
                            value={confirmpass}
                            onChange={(e) => setConfirmPass(e.target.value)}
                            // onKeyDown={(event) => {
                            //   const currentValue = event.target.value;
                            //   const key = event.key;

                            //   // Allow Backspace and Delete keys
                            //   if (key === "Backspace" || key === "Delete") {
                            //     return;
                            //   }

                            //   // Test if the pressed key is numeric
                            //   if (!/^[0-9]*$/.test(key)) {
                            //     event.preventDefault();
                            //   }

                            //   // If the input already contains non-numeric characters, prevent input
                            //   if (!/^[0-9]*$/.test(currentValue)) {
                            //     event.preventDefault();
                            //   }
                            // }}
                          />
                          <div className="eyebtn">
                            {showConPass ? (
                              <i
                                onClick={() => setShowConPass(false)}
                                class="fa-solid fa-eye"
                              ></i>
                            ) : (
                              <i
                                onClick={() => setShowConPass(true)}
                                class="fa-solid fa-eye-slash"
                              ></i>
                            )}
                            {/* <i class="fa-solid fa-eye"></i>
                      <i class="fa-solid fa-eye-slash"></i> */}
                          </div>
                        </div>
                        {/* <p style={{color:'red'}}>{errors.conPassword}</p> */}
                      </div>
                    </>
                  )}
                </form>

                <button
                  className="loginhere"
                  // onClick={(e) => {
                  //   handleLogin(e);
                  // }}
                  type="button"
                  onClick={() =>
                    location.state.google
                      ? handleUpdateModalgoogle()
                      : handleUpdateModal()
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        {updateModal && (
          <section className="pageupdatemodal">
            <div className="modalbody">
              <div className="closebtn">
                <i
                  className="fa-solid fa-xmark"
                  onClick={() => setUpdateModal(!updateModal)}
                ></i>
              </div>

              <h3 className="head">Choose Profile Type</h3>

              <div className="modalcontent">
                {/****map */}
                {roles.map((item, index) => {
                  console.log("items", item);
                  return (
                    <div className="cardbox">
                      <div className="form-check" key={index}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="jobRole"
                          value={item._id}
                          onChange={handleRoleChange}
                          id=""
                        />
                        <label className="form-check-label" htmlFor="">
                          {item.name}
                        </label>
                      </div>
                    </div>
                  );
                })}
                {/****map */}
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
};

export default AccountCreation;
