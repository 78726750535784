import { createContext, useContext, useState } from "react";

const AuthContext = createContext(null);

export const AuthCtx = () => useContext(AuthContext);

export const CreateContext = ({ children }) => {
  const [ownAddress, setownAddress] = useState("");
  console.log(ownAddress, "kkkkkkkk");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState({
    latlng: {},
    area: "",
  });

  return (
    <AuthContext.Provider
      value={{
        email,
        setEmail,
        address,
        setAddress,
        ownAddress,
        setownAddress,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default CreateContext;
