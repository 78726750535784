import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import PieChart from "../../Component/Charts/PieChart";
import ProductServices from "../../services/ProductServices";

function ChartScreen() {
  const [LoderStatus, setLoderStatus] = useState(true);
  const [PieData, setPieData] = useState([]);
  const { id } = useParams();
  function getAllData() {
    setLoderStatus(true);
    //"65290d4067c48e09af0856dc"
    ProductServices.piepayment(id)
      .then((res) => {
        console.log("res", res);
        if (res && res.status) {
          let data = [
            {
              name: "Completed ",
              population: res.data.completeTaskAmount.totalAmount,
              color: "#4ABFF4",
              legendFontColor: "#4ABFF4",
              legendFontSize: 12,
            },
            {
              name: "Remaning",
              population:
                res.data.totalTaskAmount.totalAmount -
                res.data.completeTaskAmount.totalAmount,
              color: "#3C3C3B",
              legendFontColor: "#3C3C3B",
              legendFontSize: 11,
            },
          ];
          setPieData(data);
        }
        setLoderStatus(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoderStatus(false);
      });
  }
  useEffect(() => {
    getAllData();
    return () => {};
  }, []);
  return (
    <>
      <div className="main_wrap">
        <div className="container-fluid">
          <section className="Projectpage_area">
            <div className="toppart">
              <div className="head">
                <h4>Task Charts</h4>
              </div>

              <ul className="actionpart">
                <li className="nav-item dropdown">
                  {/* <Link
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="NewestDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <span>Sort by:</span> Newest Project
                                    </Link> */}
                  <div
                    className="dropdown-menu"
                    aria-labelledby="NewestDropdown"
                  >
                    <Link className="dropdown-item" href="#">
                      demo
                    </Link>
                    <Link className="dropdown-item" href="#">
                      demo
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
        <div className="d-flex">
          <div style={{ height: "300px", width: "400px" }}>
            <PieChart />
          </div>
          <div style={{ height: "300px", width: "400px" }}>
            <PieChart />
          </div>
          <div style={{ height: "300px", width: "400px" }}>
            <PieChart />
          </div>
        </div>
      </div>
    </>
  );
}

export default ChartScreen;
