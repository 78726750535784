import React, { useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';

const ImageDownloader = ({ imageUrl, isfulldownload, setisfulldownload, extName }) => {

    const [isdownload, setisloading] = useState(false)
    console.log("jhgjhfgj", imageUrl, extName)
    const hasImageWord = imageUrl?.includes("backend/image");
    // alert(extName == "mp4" || !hasImageWord)

    const downloadImage = async () => {
        setisloading(true)
        try {
            const response = await fetch(imageUrl.data);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            // Creating a temporary link element
            const link = document.createElement('a');
            link.href = url;
            // if (hasImageWord) {
            //     link.download = 'downloaded_image.jpg';
            // } else {

            //     link.download = 'downloaded_video.mp4';
            // }

            if (extName == "mp4" || !hasImageWord) {
                link.download = 'downloaded_video.mp4';

            } else {

                link.download = 'downloaded_image.jpg';
            }


            document.body.appendChild(link);

            // Triggering the download
            link.click();

            // Removing the temporary link element
            document.body.removeChild(link);
            setisloading(false)
            setisfulldownload(true)
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    return (
        <div>
            <span onClick={downloadImage} style={{ cursor: "pointer" }}>
                {isdownload ? <p style={{ position: "absolute", top: "0px", left: "-5px" }}><RotatingLines
                    strokeColor="black"
                    height="40"
                    width="40" /> </p> : <i className="fa fa-download"
                        aria-hidden="true"></i>}

            </span>
        </div>
    );
};

export default ImageDownloader;
