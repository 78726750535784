import React from "react";
import { toast } from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../src/Images/logo.png";
import { changeUpdateState } from "../Redux/Reducer/UpdateServiceSlice";
import { useDispatch } from "react-redux";

function Sidebar() {
  const localdata = localStorage.getItem("user");
  const userdata = JSON.parse(localdata);
  console.log("jkgghfgj", userdata)
  const navigate = useNavigate();
  const isFinance = userdata?.jobRole?.some(it => it == "65531d1de9465ce15d48648e")
  const handleClick = () => {
    if (document.querySelector("#DarkSwitch").checked) {
      document.body.classList.add("drakmode");
    } else {
      document.body.classList.remove("drakmode");
    }
  };
  const dispatch = useDispatch()

  const handlClick = () => {
    if (document.querySelector("#backbtnsidebar").click) {
      document.querySelector(".sidebarwrap").classList.remove("show");
    } else {
      document.querySelector(".sidebarwrap").classList.remove("show");
    }
  };

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/login");
    toast.success("Logout SuccessFull");
  };

  return (
    <>
      <section className="sidebarwrap">
        <div className="top_area">
          <button id="backbtnsidebar" onClick={handlClick}>
            <i className="fa-solid fa-arrow-left"></i>
          </button>

          <ul className="logo_area">
            <div className="logo" style={{ cursor: "pointer" }} onClick={() => { navigate('/') }}>
              <img src={logo} className="img-fluid" alt="logo" />
            </div>

            {/* <li className="dropdown">
                            <Link
                                className="dropdown-toggle"
                                href="#"
                                id="sidebarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Menu
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="sidebarDropdown">
                                <Link to="/" className="dropdown-item">
                                    <i className="fa-solid fa-gauge-high mr-2"></i>
                                    <span>Dashboard</span>
                                </Link>

                            </div>
                        </li> */}
          </ul>
        </div>

        <div className="sidebar_menu">
          <ul className="nav Menu_Nav">
            <NavLink to="/" className="" onClick={handlClick}>
              <i className="fa-solid fa-bars"></i>
              <span>Home</span>
            </NavLink>
            <NavLink style={{ display: isFinance ? "none" : "block" }} to="/myjob" className="" id="job">
              <i className="fa-regular fa-building"></i>
              <span>Dashboard</span>
            </NavLink>

            {/* <NavLink to="/" className="" onClick={handlClick}>
                            <i className="fa-solid fa-folder-closed"></i>
                            <span>Projects</span>
                        </NavLink> */}

            {/* <NavLink to="/page1" className="">
              <i className="fa-solid fa-clipboard"></i>
              <span>Portfolio</span>
            </NavLink> */}

            <NavLink style={{ display: isFinance ? "none" : "block" }} to="/payment" className="">
              <i className="fa-solid fa-money-check-dollar"></i>
              <span>Payment</span>
            </NavLink>


            <NavLink style={{ display: isFinance ? "none" : "block" }} to="/Proposal" className="">
              <i className="fa-solid fa-briefcase"></i>
              <span>Services </span>
            </NavLink>


            {/* <NavLink
              onClick={() => dispatch(changeUpdateState(false))}
              style={{
                display: (
                  userdata?.jobRole[0] == '64dca6a1a63f41ccadf38c3c') ? "none" : "block"
              }} to="/subscription" className="">
              <i className="fa-solid fa-clipboard-check"></i>
              <span>My Plan</span>
            </NavLink> */}


            <NavLink style={{ display: isFinance ? "none" : "block" }} to="/Settings" className="" id="activity">
              <i className="fa-solid fa-list-check"></i>
              <span>Settings</span>
            </NavLink>




            {/* <div onClick={handleLogOut} style={{ width: "100%" }}>
              <Link>
                <i className="fa-solid fa-right-from-bracket mr-2"></i>
                <span>Logout</span>
              </Link>
            </div> */}

          </ul>

          <ul className="nav Account_Nav">
            {/* <div
              className="d-flex align-items-center justify-content-between"
              style={{ padding: "14px 14px", margin: "5px 0", width: "100%" }}
            >
              <div className="d-flex align-items-center">
                <i className="fa-solid fa-moon mr-2"></i>
                <span>Dark Mode</span>
              </div>

              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="DarkSwitch"
                  onClick={handleClick}
                />
                <label
                  className="custom-control-label"
                  htmlFor="DarkSwitch"
                ></label>
              </div>
            </div> */}

            {/* <NavLink to="/page5">
                            <i className="fa-solid fa-key"></i>
                            <span>Lorem Ipsum</span>
                        </NavLink> */}

          </ul>
        </div>
      </section>
    </>
  );
}

export default Sidebar;
