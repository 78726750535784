import React, { useEffect, useState } from "react";

import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { setDefaults, geocode, RequestType } from "react-geocode";
import { useGeolocated } from "react-geolocated";
import { AuthCtx } from "../../context/CreateContext";

const containerStyle = {
  width: "520px",
  height: "400px",
  // marginTop: "10px",
};

const GoogleMapNew = ({ userLatLng, setUserLatLng, setMyAddress }) => {
  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
    setownArea,
    iamFrom,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  console.log("coords", coords, coords?.latitude, coords?.longitude);

  const [searchPlace, setSearchPlace] = useState();
  const [coordinates, setCoordinates] = useState([]);
  const [jobsiteDetail, setJobsiteDetial] = useState(null);
  const [areaName, setareaName] = useState("");
  console.log(areaName, "kjgjhhk");
  const [location, setLocation] = useState("");
  const [map, setMap] = useState(null);
  const [zoomNo, setZoomNo] = useState(0);
  const [center, setCenter] = useState({
    lat: 37.0902,
    lng: 95.7129,
  });
  const { setAddress, address } = AuthCtx();

  const [markerlatlng, setMarkerlatLng] = useState({
    lat: 10,
    lng: 15,
  });
  const [zipcode, setZipcode] = useState("");
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC-ki3ImgxYzo8K2OCH9yDthHWIWV1yfj4",
    // libraries: ["places"],
  });

  const onLoad = React.useCallback(
    function callback(map) {
      // map.setZoom(12);
      setZoomNo(16);

      setMap(map);

      console.log("address", `${center.lat},${center.lng}`);

      let obj = { ...markerlatlng };
      obj.lat = coords?.latitude;
      obj.lng = coords?.longitude;
      setMarkerlatLng(obj);
      // console.log("coords", obj);
      setCenter(obj);

      geocode(RequestType.LATLNG, `${center.lat},${center.lng}`).then(
        ({ results }) => {
          const address = results[0].formatted_address;
          setMyAddress(address);
          setareaName(address);
        }
      );
    },
    [center, coords]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
    setCenter({
      lat: 37.0902,
      lng: 95.7129,
    });
  }, []);

  console.log("markerlatlng", markerlatlng);

  setDefaults({
    key: "AIzaSyC-ki3ImgxYzo8K2OCH9yDthHWIWV1yfj4",
    language: "en",
    region: "es",
  });

  console.log(
    "location",
    markerlatlng?.lat,
    markerlatlng?.lng,
    coords?.latitude,
    coords?.longitude
  );

  useEffect(() => {
    let obj = {
      latLng: markerlatlng,
      area: areaName,
      zipcode: zipcode,
    };
    setAddress(obj);
  }, [areaName, markerlatlng]);

  console.log("abdgshs", address);

  // useEffect(() => {
  //   let obj = { ...markerlatlng };
  //   obj.lat = coords?.latitude;
  //   obj.lng = coords?.longitude;
  //   setMarkerlatLng(obj);
  //   // console.log("coords", obj);
  //   // setCenter(obj);
  // }, [coords]);

  useEffect(() => {
    console.log("hgfdkjglfk", center, userLatLng);

    setCenter(userLatLng);
    setMarkerlatLng(userLatLng);

    // let obj = {lat:"",lng:"" };
    // obj.lat = userLatLng?.latitude;
    // obj.lng = userLatLng?.longitude;
    // ,
  }, [userLatLng, center]);

  if (!isLoaded) return <div>...loading</div>;
  return (
    <>
      <div
        className="map_area"
        style={{ width: "100%", height: "400px", overflow: "hidden" }}
      >
        {/* MAp area */}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={userLatLng}
          // zoom={userLatLng ? 11 : 0}
          zoom={zoomNo}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={(ev) => {
            let data = {
              lat: ev.latLng.lat(),
              lng: ev.latLng.lng(),
            };
            geocode(RequestType.LATLNG, `${data.lat},${data.lng}`).then(
              ({ results }) => {
                const address = results[0].formatted_address;
                const formatedaddress = results[0].address_components;
                let zipCode = "";
                formatedaddress.forEach((component) => {
                  if (component.types.includes("postal_code")) {
                    zipCode = component.long_name;
                  }
                });

                setZipcode(zipCode);
                console.log("addrew21ss", address);
                if (iamFrom == "ownAddress") {
                  setownArea(address);
                }

                setareaName(address);
              }
            );
            setMarkerlatLng(data);
            setUserLatLng(data);
            setCenter(data);
          }}
        >
          <MarkerF position={markerlatlng} />
          {/* <MarkerF position={center} /> */}
        </GoogleMap>
        {/* <img src={mapImg} className="img-fluid" alt='map' style={{ width: "100%", height: "100%", }} /> */}
      </div>
    </>
  );
};

export default React.memo(GoogleMapNew);
