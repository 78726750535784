import React, { useEffect, useState } from "react";
import "./login.css";
import { toast } from "react-hot-toast";
import AuthServices from "../services/AuthServices";
import { reactLocalStorage } from "reactjs-localstorage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../Component/loader/CustomLoader";
import OtpVerification from "./OtpVerification";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import logo from "../Images/logo.png";
import google from "../Images/google-2-1@2x.png";
import { setEmail } from "../Redux/Reducer/User";
// import { setEmail } from "../context/CreateContext";

const CreateAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // console.log("emailState", emailState);

  const initValue = {
    address_id: "",
    address_type: "email",
  };
  const [formValue, setFormValue] = useState(initValue);
  const [formError, setFormError] = useState();
  const [click, setClick] = useState(false);
  const [otp, setOtp] = useState("");
  const [verifyotp, setVerifyOtp] = useState(false);

  const [loading, setLoading] = useState(false);

  const categoryData = [
    "Soundproofing",
    "Air Conditioning Installation",
    "Door Installation",
    "Garage Installation",
    "Garage Door Repairs",
    "Kitchen Renovation",
    "Bathroom Remodeling",
    "Installing New Flooring",
    "Painting (Interior/Exterior)",
    "Replacing Windows or Doors",
    "Roof Repair or Replacement",
    "Double Glazing",
    "Adding Insulation",
    "Upgrading Electrical or Plumbing Systems",
    "Building a New Room",
    "Loft Conversions",
    "Home Office and Annex",
    "Adding a Sunroom or Conservatory",
    "Expanding the Garage",
    "Building a Deck or Patio",
    "Constructing a New Porch",
    "Extending the Living Space",
    "Building an Additional Floor",
    "Constructing a New House",
    "Lawn Care and Maintenance",
    "Garden Cleanup",
    "Shed Creation",
    "Outdoor Lighting",
    "Irrigation and Sprinkler Systems",
    "Develop a site",
    "other",
  ];

  // handle change
  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setFormValue((prev) => {
      return { ...prev, [name]: value };
    });
  };

  //   useEffect(() => {
  //     if (id) {
  //       logindirect();
  //     }
  //   }, []);

  //   const logindirect = async () => {
  //     if (id) {
  //       setLoading(true);
  //       const res = await AuthServices.getprofile(id);
  //       console.log("resLogin", res);
  //       if (res && res?.status) {
  //         let tokenData = { token: "token" };
  //         // console.log("tokenDatadd", tokenData)
  //         // return
  //         reactLocalStorage.setObject("constructionToken", tokenData);
  //         reactLocalStorage.setObject("loginid", res.data[0]?.userData._id);

  //         setLoading(false);
  //         navigate("/");
  //       } else {
  //         toast.error(res?.message);
  //         setLoading(false);
  //       }
  //     }
  //   };
  // validation
  const validate = () => {
    const email_regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!formValue.address_id) {
      toast.error("Email is Required");
      return true;
    } else if (!email_regex.test(formValue.address_id)) {
      toast.error("Please Enter Valid Email");
      return true;
    }

    return false;
  };

  // handleSubmit
  const handleLogin = async (e) => {
    e.preventDefault();

    if (validate()) return;

    const sendData = {
      address_id: formValue.address_id,
      address_type: "email",
    };

    setLoading(true);

    const res = await AuthServices.sendotp(sendData);

    if (res && res?.success) {
      dispatch(setEmail(formValue.address_id));
      setOtp(res?.otp);
      setVerifyOtp(true);

      toast.success(res?.message);

      setLoading(false);
    } else {
      toast.error(res?.message);
      setLoading(false);
    }
  };

  return (
    <>
      {verifyotp ? (
        <OtpVerification otp={otp} />
      ) : (
        <div>
          <CustomLoader loading={loading} />
          <div className="login-page">
            <div className="logincard">
              <div className="logoarea">
                <img src={logo} className="img-fluid" alt="" />
              </div>
              <div className="form">
                <h2 className="head">Create Account</h2>
                <p className="text">Please Sign in to continue</p>
                <form className="login-form">
                  {/* Email */}
                  <div className="form-group">
                    <label htmlFor="">Email address</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="address_id"
                      value={formValue.address_id}
                      onChange={(e) => handleChangeForm(e)}
                    />
                  </div>
                </form>

                <button
                  className="loginhere"
                  onClick={(e) => {
                    handleLogin(e);
                  }}
                >
                  Next
                </button>

                <div>
                  <div className="divider">
                    <h6>OR</h6>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button type="submit" className="btn continuewithgoogle">
                      <span className="mr-1">
                        <img src={google} className="img-fluid" alt="" />
                      </span>
                      Continue with Google
                    </button>
                  </div>

                  <h6 className="Alreadyhaveaccount">
                    Already have an account ?{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/login/");
                      }}
                    >
                      Login
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateAccount;
