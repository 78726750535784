import React, { useEffect, useState } from "react";
import PaymentDetailsCard from "../../Component/PaymentDetailsCard";
import SupplierCard from "../../Component/SupplierCard";
import LendersCard from "../../Component/LendersCard";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import "./Changepass.css";
import dp from "../../Images/dp.png";
import HttpClient from "../../utils/HttpClient";
import AuthServices from "../../services/AuthServices";
import ProductServices from "../../services/ProductServices";
import moment from "moment";
import { useParams } from "react-router-dom";
import const1 from "../../Images/cons1.png"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css';
import Recentlybiddedproject from "../../Component/Recentlybiddedproject";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { removeUser } from "../../Redux/Reducer/User";
const Changepass = () => {
    const Toast = toast;
    const [showpass, setShowPass] = useState(false);
    const [showpass1, setShowPass1] = useState(false);
    const [showpass2, setShowPass2] = useState(false);
    const [oldpass, setoldPass] = useState("");
    const [newpass, setNewPass] = useState("");
    const [conpass, setConPass] = useState("");

    function isPasswordValid(password) {
        const validationPoints = [];

        // Check for at least one lowercase letter
        if (!/[a-z]/.test(password)) {
            validationPoints.push({
                isValid: false,
                error: 'Password must contain at least one lowercase letter',
            });
        } else {
            validationPoints.push({
                isValid: true,
                error: 'Password must contain at least one lowercase letter',
            });
        }

        // Check for at least one uppercase letter
        if (!/[A-Z]/.test(password)) {
            validationPoints.push({
                isValid: false,
                error: 'Password must contain at least one uppercase letter',
            });
        } else {
            validationPoints.push({
                isValid: true,
                error: 'Password must contain at least one uppercase letter',
            });
        }

        // Check for at least one number (0-9)
        if (!/[0-9]/.test(password)) {
            validationPoints.push({
                isValid: false,
                error: 'Password must contain at least one number',
            });
        } else {
            validationPoints.push({
                isValid: true,
                error: 'Password must contain at least one number',
            });
        }

        // Check for at least one special character (!@#$%^&*)
        if (!/[!@#$%^&*]/.test(password)) {
            validationPoints.push({
                isValid: false,
                error: 'Password must contain at least one special character (!@#$%^&*)',
            });
        } else {
            validationPoints.push({
                isValid: true,
                error: 'Password must contain at least one special character (!@#$%^&*)',
            });
        }

        // Check if the password is at least 8 characters long
        if (password.length < 8) {
            validationPoints.push({
                isValid: false,
                error: 'Password must be at least 8 characters long',
            });
        } else {
            validationPoints.push({
                isValid: true,
                error: 'Password must be at least 8 characters long',
            });
        }

        return validationPoints;
    }
    const cheakPassword = isPasswordValid(newpass).every(
        item => item.isValid == true,
    );
    function ChangePassword() {
        if (oldpass == '') {
            Toast.error("Please enter Old Password", Toast.SHORT);
            return
        }
        if (newpass == '') {
            Toast.error("Please enter Password", Toast.SHORT);
            return
        }
        if (newpass != conpass) {
            Toast.error("password doesn't match", Toast.SHORT);
            return;
        }

        AuthServices.userchangepass({
            "oldPass": oldpass,
            "newPass": conpass
        })
            .then(res => {
                // setLoderStatus(false)
                console.log("res", res);
                if (res && res.status) {
                    Toast.success("password Updated Successfully", Toast.SHORT);
                    // NavigationService.back()
                    setoldPass("")
                    setNewPass("")
                    setConPass("")
                } else {
                    Toast.error(res.msg);
                }
            })
            .catch(err => {

                Toast.error(err.msg,);
                // setLoderStatus(false)
                if (err && err.msg && typeof err.msg == "string") Toast.error(err.msg,);
            })
    }





    return (
        <>
            <div className="main_wrap">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="middlepartwrap">

                                <div className="job_dtls ">
                                    <div className="passwordcss">

                                        <input
                                            value={oldpass}
                                            onChange={(e) => { setoldPass(e.target.value) }}
                                            placeholder="Old Password" type={showpass ? "text" : "password"}></input>
                                        {showpass ? (
                                            <i
                                                onClick={() => setShowPass(false)}
                                                class="fa-solid fa-eye mr-2"
                                            ></i>
                                        ) : (
                                            <i
                                                onClick={() => setShowPass(true)}
                                                class="fa-solid fa-eye-slash mr-2"
                                            ></i>
                                        )}

                                    </div>


                                </div>

                                <div className="job_dtls addclass1">

                                    {/* <p style={{ color: "red" }}>not at tha rkjhfjsh</p>
                                    <p style={{ color: "red" }}>not at tha rkjhfjsh</p>
                                    <p style={{ color: "red" }}>not at tha rkjhfjsh</p> */}

                                    {newpass && isPasswordValid(newpass).map(item => {


                                        if (item.isValid) {
                                            return ""

                                        } else {
                                            return (<p style={{ color: "red" }}>{item.error}</p>)


                                        }
                                    })}

                                    <div className="passwordcss">

                                        <input
                                            value={newpass}
                                            onChange={(e) => { setNewPass(e.target.value) }}
                                            placeholder="New Password" type={showpass1 ? "text" : "password"}></input>
                                        {showpass1 ? (
                                            <i
                                                onClick={() => setShowPass1(false)}
                                                class="fa-solid fa-eye mr-2"
                                            ></i>
                                        ) : (
                                            <i
                                                onClick={() => setShowPass1(true)}
                                                class="fa-solid fa-eye-slash mr-2"
                                            ></i>
                                        )}

                                    </div>


                                </div>




                                {/* <div > */}
                                <div className="passwordcss">

                                    <input
                                        value={conpass}
                                        onChange={(e) => { setConPass(e.target.value) }}
                                        placeholder="Confirm Password" type={showpass2 ? "text" : "password"}></input>
                                    {showpass2 ? (
                                        <i
                                            onClick={() => setShowPass2(false)}
                                            class="fa-solid fa-eye mr-2"
                                        ></i>
                                    ) : (
                                        <i
                                            onClick={() => setShowPass2(true)}
                                            class="fa-solid fa-eye-slash mr-2"
                                        ></i>
                                    )}

                                </div>


                                {/* </div> */}

                                <div style={{ marginTop: '20px', display: "flex", justifyContent: "center" }}>
                                    <button
                                        className="editButton"
                                        onClick={() => {
                                            ChangePassword()
                                            // dispatch(changeUpdateState());
                                            // navigate("/update_service");
                                        }}
                                    >
                                        Change
                                    </button>
                                </div>

                            </div>

                        </div>


                        <div className="col-lg-4 col-md-4 col-12 pr-0">
                            <div className="rightsidebar">


                                <LendersCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Changepass