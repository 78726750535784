import React from 'react'
import ReactApexChart from "react-apexcharts";
function MybarChart() {
    const state = {
        series: [
          {
            name: "SKU Name DOI",
            data: [12, 42, 54, 70, 78, 80, 81, 83, 87, 101],
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
          },
      
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [
              "Org Growth Enhance...",
              "Coil Spring Set",
              "Wheel Hub Assembly",
              "Clutch Master Cylinder",
              "Hood Release Cable",
              "Battery Cable Set",
              "Travel Enthusiast",
              "Trim Shears Model 6",
              "Roof Rack Kit",
              "PlayStation 5",
            ],
          },
          fill: {
            colors: ['#f0e199']
          }
        },
      };
  return (
    <div id="chart">
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="bar"
      height={350}
    />
  </div>
  )
}

export default MybarChart