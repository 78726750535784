import React, { useEffect, useState } from "react";
import PaymentDetailsCard from "../../Component/PaymentDetailsCard";
import SupplierCard from "../../Component/SupplierCard";
import LendersCard from "../../Component/LendersCard";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import "./settings.css";
import dp from "../../Images/dp.png";
import HttpClient from "../../utils/HttpClient";
import AuthServices from "../../services/AuthServices";
import ProductServices from "../../services/ProductServices";
import moment from "moment";
import { useParams } from "react-router-dom";
import const1 from "../../Images/cons1.png"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css';
import Recentlybiddedproject from "../../Component/Recentlybiddedproject";
import { useSelector } from "react-redux";
import DeleteAccountModel from "../../Component/Modal/DeleteAccountModel";
import AddownAddressModel from "../../Component/Modal/AddownAddressModel";
const Settings = () => {
    const [deletModal, setdeletModal] = useState(false)
    const [addOwnAddressflag, setaddOwnAddressflag] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="main_wrap">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="middlepartwrap">
                                <div className="job_dtls">
                                    <div className="setting_botton"><Link to=''>About </Link> </div>

                                    <div className="setting_botton"><Link to=''>Help</Link> </div>
                                    <div className="setting_botton"><Link to=''>Faq</Link> </div>
                                    <div className="setting_botton"><Link to='/profiledetails'> Profile</Link> </div>
                                    <div className="setting_botton"

                                    ><Link to="/subscription">  My Plan</Link> </div>
                                    <div className="setting_botton"><Link to='/termsAndcondition'> Terms & Condition</Link> </div>


                                    <div className="setting_botton"><Link to='/Changepass'>  Change Password</Link> </div>
                                    <div className="setting_botton"><Link to='/privacy'>  Privacy Notice</Link> </div>
                                    <div className="setting_botton"><Link to='' onClick={() => {
                                        setaddOwnAddressflag(!addOwnAddressflag);
                                    }}>Add Own Address </Link> </div>
                                    <div className="setting_botton" onClick={() => { setdeletModal(true) }}><Link to=''>   Delete Account</Link> </div>


                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4 col-md-4 col-12 pr-0">
                            <div className="rightsidebar">


                                <LendersCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deletModal && <DeleteAccountModel setdeletModal={setdeletModal} />}
            {addOwnAddressflag && (
                <AddownAddressModel setaddOwnAddressflag={setaddOwnAddressflag} />
            )}


        </>
    );
}
export default Settings