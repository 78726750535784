import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./Wallet.css"
import { useSelector, } from 'react-redux'
import ProductServices from '../../services/ProductServices'
import { autoBatchEnhancer } from '@reduxjs/toolkit'
import AuthServices from '../../services/AuthServices'

function Wallet() {

    const userData = useSelector(state => state.User.userData)
    const userdata = userData
    const [AllBank, setAllBank] = useState([])
    const [flag, setflag] = useState(false)
    const [Loder, setLoder] = useState(false)
    const [SelectedBank, setSelectedBank] = useState({})
    const [Price, setPrice] = useState(0)
    const [MainPrice, setMainPrice] = useState('')

    const [ModalVisable, setModalVisable] = useState(false)


    useEffect(() => {
        AuthServices.getprofile()
            .then(res => {
                if (res && res.status) {
                    console.log("")
                    setPrice(res?.data?.[0]?.walletBalance ?? 0)
                }
            })
            .catch(err => {

            })

        ProductServices.walletHistory()
            .then(res => {
                console.log("restty", res);
                if (res && res.status) {
                    setAllBank(res.data)
                }
            })
            .catch(err => {
                console.log("err", err);
            })


    }, [])
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const addAmountTowallet = () => {
        //     const data = {
        //         success_url: "http://localhost:3000",
        //         userName: userdata?.username,
        //         planId: item._id,
        //         planBuyPrice: item?.price,
        //         limitAvailable: item?.bidLimit,
        //         skillLimit: item?.skillLimit,
        //         deductionPercentage: item?.deductionPercentage,
        //         customer: stripeCustomerId,
        //         priceId: item.stripePriceId,
        //     };
        // }
    }

    return (
        <div className="main_wrap">
            <div className="container-fluid">
                <Link to="/payment" className="posted_icon">
                    <i className="fa-solid fa-arrow-left"></i>
                </Link>

                <div className='wallet'>

                    <div className='showAmount' >Total Amount <span className='boxspace'>{Price}</span>£</div>
                    <div> <button className='button' onClick={() => {
                        setflag(true)
                    }} style={{ display: flag ? "none " : "block" }}>Add</button></div>


                </div>
                <div className='wallet '>

                    <div className='inputPrice' style={{ display: flag ? "block" : "none" }}>
                        <div className='close_btn'>
                            <button className='btn' onClick={() => { setflag(false) }}><i className="fa-solid fa-xmark"></i></button>
                        </div>
                        <div className='userInput_payment'>
                            <input type='text' placeholder='Enter Amount'></input>
                            <p>£</p>
                        </div>

                        <div> <button onClick={() => {
                            setflag(false)
                        }} className='addbutton'>Add</button></div>
                    </div>




                </div>

                <div >
                    <h3>Balance History:</h3>
                    {
                        AllBank?.map((ele, id) => {
                            return (<div className='rederhistry'>
                                <h5>id:{ele.paymentId}</h5>
                                <h5>Credit</h5>
                                <h5>amount:{ele.amount}</h5>
                            </div>)
                        })
                    }



                </div>
            </div>
        </div>
    )
}

export default Wallet