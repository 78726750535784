import React, { useState } from 'react'
import profile1 from "../../Images/profile-pic.png";
import moment from 'moment';
import HttpClient from '../../utils/HttpClient';
const videoIcon = require("../../Images/videoIcon.png")
const pdfIcon = require('../../Images/pdf.png')
function TaskDetail({ item }) {
    console.log(item?.image, "rw35h")
    const defaultImage = "https://buildbetastorage.blob.core.windows.net/backend/image/projectimg1699955607671?sp=r&st=2023-11-13T09:51:53Z&se=2033-11-01T17:51:53Z&spr=https&sv=2022-11-02&sr=c&sig=uueWf3lxzBIyTsPNhc%2B0pWFMyBa2kTjiRKFaBP8No2c%3D"
    return (
        <>
            <div className='projectwrapcard_box'>
                <div className='img_wrap'>
                    {/* <img src={projectimg} className='img-fluid' alt='project' /> */}

                    {

                        item?.image.length > 0 ? item?.image?.map((ele, id) => {

                            if (ele.extName == "jpg" || ele.extName == "png" || ele.extName == "jpeg") {
                                return (
                                    <img src={ele?.data ?? defaultImage} className='img-fluid' alt='project' />

                                )

                            } else

                                if (ele.extName == "mp4") {
                                    return (
                                        <img src={videoIcon ?? defaultImage} />
                                    )

                                } else


                                    if (ele.extName == "pdf") {
                                        return (
                                            <img src={pdfIcon ?? defaultImage} />
                                        )
                                    } else {
                                        return (<img src={defaultImage} />)
                                    }

                        }) : <img src={defaultImage} />

                    }
                    {/* <img src={item?.image[0]?.data ?? "https://buildbetastorage.blob.core.windows.net/backend/image/projectimg1699955607671?sp=r&st=2023-11-13T09:51:53Z&se=2033-11-01T17:51:53Z&spr=https&sv=2022-11-02&sr=c&sig=uueWf3lxzBIyTsPNhc%2B0pWFMyBa2kTjiRKFaBP8No2c%3D"} className='img-fluid' alt='project' /> */}

                    <div className='status'>
                        <button className='btn'
                        >Show</button>
                    </div>
                </div>

                <div className='content_area'>
                    <div className='name'>
                        <h4>{item?.taskName}</h4>
                    </div>
                    <div className='michaelpeople'>
                        {/* <p>{item?.description}</p> */}
                    </div>
                    <div className='michaelpeople'>
                        <p>{moment(item?.startDate).format("LL")} - {moment(item?.endDate).format("LL")}</p>
                    </div>

                    {/* <div className="progress">
                        <div className="progress-done" style={{ width: "60%" }}>
                            60%
                        </div>
                    </div> */}

                    {/* <div className='bottom_area'>
                        <div className='left'>
                            <div className='d-flex align-items-center'><span>15</span> / <span>48</span> <p>Total Tasks</p></div>
                        </div>

                        <div className='right'>
                            <div className='d-flex align-items-center'>
                                <span>£5,000/ £ 10,000</span>
                                <p>49%</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>




        </>
    )
}

export default TaskDetail