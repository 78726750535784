import React from 'react'
import proffff from "../../Images/proffff.png"
export default function LenderModal({closeModal}) {
  return (
  <>
   <section className='supplier_modal_main'>
                <div className='supplier_modal'>
                    <div className='supplier_overlay'></div>
                    <div className='supplier_content lender_content'>
                        <div>
                        <div className='supplier_img'>
                            <img src={proffff} alt="/" />
                        </div>
                        <div className='closemark' onClick={()=>closeModal(false)}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                        </div>
                        
                        
                        <div className='supplier_details_main'>

                            <div className='row'>
                                <div className='col-lg-7 col-md-7 col-8'>
                                    <h5 className='supplier_details_txt'>Financial Institution/ Bank Name </h5>
                                </div>
                                <div className='col-lg-5 col-md-5 col-4'>
                                    <p className='supplier_details'>: City Bank</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-5 col-md-5 col-7'>
                                    <h5 className='supplier_details_txt'>Contact Person Name </h5>
                                </div>
                                <div className='col-lg-7 col-md-7 col-5'>
                                    <p className='supplier_details'>: Mira lipshutz</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-3 col-md-3 col-4'>
                                    <h5 className='supplier_details_txt'>Email Address </h5>
                                </div>
                                <div className='col-lg-9 col-md-9 col-8'>
                                    <p className='supplier_details'>: abcd@gmail.com</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-3 col-md-3 col-4'>
                                    <h5 className='supplier_details_txt'>Branch Name </h5>
                                </div>
                                <div className='col-lg-9 col-md-9 col-8'>
                                    <p className='supplier_details'> : AbCd BAnk</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-3 col-md-3 col-5'>
                                    <h5 className='supplier_details_txt'>Phone Number </h5>
                                </div>
                                <div className='col-lg-9 col-md-9 col-7'>
                                    <p className='supplier_details'> : +44 123 123 1234</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-5'>
                                    <h5 className='supplier_details_txt'>Physical Address  </h5>
                                </div>
                                <div className='col-lg-8 col-md-8 col-7'>
                                    <p className='supplier_details'>: 45 Prince Consort Road, Kemback, Zip Code: KY15 1SJ, United Kingdom</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  
  </>
  )
}
