import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cons1 from '../../Images/cons1.png';
import cons2 from '../../Images/cons2.png';
import cons3 from '../../Images/cons3.png';
import cons4 from '../../Images/cons4.png';
import HttpClient from '../../utils/HttpClient';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import ProductServices from '../../services/ProductServices';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { PiXCircleDuotone } from 'react-icons/pi';
import CustomLoader from '../loader/CustomLoader';
import { getSubTask } from '../../Redux/Reducer/SubTaskSlice';
import { useSelector } from 'react-redux';
import Select from 'react-select';


// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
// import 'react-accessible-accordion/dist/fancy-example.css';

export default function CreatesubtaskModal(props) {
    const { userData } = useSelector((state) => state.User);
    const { closeModal, projectId, taskId, singleProjectData, singleTaskData, setModalA } = props
    const [startDate, setStartDate] = useState(new Date());
    const dispatch = useDispatch();

    const [formLoading, setFormLoading] = useState(false);

    const initValue = {
        taskName: "",
        description: "",
        startDate: "",
        endDate: "",
        taskBudget: "",
        cPercentage: '',
        image: [],

        // PaymentFequencey: ""
    }
    const [formValue, setFormValue] = useState(initValue)
    const [projectyes, setProjectYes] = useState(false)
    const [projectno, setProjectNo] = useState(true)
    const [projectdata, setAllprojectData] = useState([])
    const [selectPId, setSelectPId] = useState('')
    const [imgerrflag, setimgerrflag] = useState(false);

    const [isleftDatePickerOpen, setleftDatePickerOpen] = useState(false);
    const [isrightDatePickerOpen, setrightDatePickerOpen] = useState(false);

    console.log("formVeealue", singleTaskData)

    // handleChange
    const handleChange = (e) => {
        if (e.target.name == "taskBudget") {
            if (e.target.value > singleTaskData.taskBudget) {
                toast.error("Sub Task Budget can't be gretar than TaskBudget ")
                return;
            }
        }
        const { name, value } = e.target;
        setFormValue(prev => { return { ...prev, [name]: value } });
    }

    //file validater
    function getFileExtensionFromMimeType(mimeType) {

        const parts = mimeType.split('.').pop().toLowerCase();
        const nextparts = mimeType.split("/");
        console.log("kkjjr", parts, nextparts)
        if (nextparts.length === 2) {
            if (nextparts[1] == "pdf" ||
                nextparts[1] == "jpg" || nextparts[1] == "png" || nextparts[1] == "mp4" || nextparts[1] == "jpeg"
            )
                return nextparts[1];
        }


        return parts;

    }





    // image upload
    const handleUploadImage = async (e) => {

        let file = e.target.files;
        // console.log("imgghj", file)

        let imgArr = formValue.image
        for (let img of file) {
            if (

                getFileExtensionFromMimeType(img.type) === "jpg" ||
                getFileExtensionFromMimeType(img.type) === "jpeg" ||
                getFileExtensionFromMimeType(img.type) === "png" ||
                getFileExtensionFromMimeType(img.type) === "pdf"
                // ||
                // getFileExtensionFromMimeType(img.type) === "mp4"

            ) {

                let data = new FormData();
                data.append("img", img);
                let res = await HttpClient.fileUplode("task-image", "POST", data);
                console.log("resImage", res)
                if (res && res.status) {
                    imgArr.push(res?.data)
                    setFormValue((prev) => { return { ...prev, image: imgArr } })
                } else {
                }

                setimgerrflag(false)
            } else {
                setimgerrflag(true)
            }
        }
    }

    //delete single image
    const deleteSingleImage = (index) => {
        let newArr = formValue.image.filter((item, i) => i !== index)
        setFormValue((prev) => { return { ...prev, image: newArr } });
    }

    // valiidate
    const validate = () => {
        if (!formValue.taskName) {
            toast.error("Task Name is Required");
            return true
        }
        if (!formValue.description) {
            toast.error("Description is Required");
            return true
        }
        if (!formValue.startDate) {
            toast.error("Timeline is Required");
            return true
        }
        if (!formValue.endDate) {
            toast.error("Timeline is Required");
            return true
        }
        // if (formValue.image.length === 0) {
        //     toast.error("Image is Required");
        //     return true
        // }
        if (!formValue.taskBudget) {
            toast.error("Task Budget is Required");
            return true
        }
        // if (!formValue.PaymentFequencey) {
        //     toast.error("Payment Frequency is Required");
        //     return true
        // }

        return false
    }

    // create sub task
    const handleCreateSubTask = async () => {
        if (validate()) {
            return;
        }

        const data = {
            projectId: projectId,
            taskId: taskId,
            name: formValue.taskName,
            description: formValue.description,
            startDate: formValue.startDate,
            endDate: formValue.endDate,
            taskBudget: formValue.taskBudget,
            percentage: formValue.cPercentage,
            image: formValue.image,

            // PaymentFequencey: formValue.PaymentFequencey
            addProject: projectyes == 'Yes'
        }
        if (selectPId != '') {
            data.addProjectId = selectPId;
        }
        setFormLoading(true);
        const res = await ProductServices.fetchAddSubTask(data);
        console.log("resSubb", res)
        if (res && res?.status) {
            toast.success(res?.message);
            setFormValue(initValue);
            setFormLoading(false);
            dispatch(getSubTask(projectId))
            closeModal()
        }
        else {
            toast.error(res?.message);
            setFormLoading(false);
        }
    }

    useEffect(() => {
        ProductServices.getAllprojectList({ id: userData._id })
            .then(res => {
                if (res && res.status) {
                    let Arr = []
                    console.log('all project123', res);
                    res.data?.forEach(element => {
                        let data = {
                            label: element.name,
                            value: element._id
                        }
                        Arr.push(data)
                    });
                    setAllprojectData(Arr);
                }
            })
            .catch(err => { });

        return () => { };
    }, []);

    return (
        <section className='createsubtaskmodal'>

            <CustomLoader loading={formLoading} />

            <div className='create_modal'>
                <div className='create_overlay' onClick={() => {
                    closeModal(false)
                }}></div>
                <div className='createmodal_content' onClick={(e) => { e.stopPropagation() }}>

                    {/* back arrow */}
                    <AiOutlineArrowLeft
                        style={{ cursor: "pointer", fontSize: "22px" }}
                        onClick={() => {
                            setModalA(true);
                            closeModal(false);
                        }}
                    />

                    <div className='modal_project_content'>
                        <p className='notification_txt'>
                            {singleProjectData?.name}/ {singleTaskData?.taskName}/ Create Subtask</p>
                        <div className='closemark' onClick={() => closeModal(false)}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                        <div className='project_form projectppp'>

                            {/* Task Name */}
                            <div className='project_tsk'>
                                <label for="" className='tsk_label'>Subtask Name</label>
                                <input
                                    type="text"
                                    placeholder=''
                                    id=""
                                    className="project_input"
                                    name="taskName"
                                    value={formValue?.taskName}
                                    onChange={(e) => handleChange(e)}
                                />                            </div>

                            {/* description */}
                            <div className='project_desc projectppp'>
                                <label for="" className='tsk_label'>Description</label>
                                <textarea
                                    rows="4"
                                    cols="50"
                                    className="project_input"
                                    name="description"
                                    value={formValue.description}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>

                            {/* start Date */}
                            <div className='timeline projectppp'>
                                <label for="" className='tsk_label'>Timeline</label>
                                <div className='timeline_flx'>

                                    <div className='calender_datepicker_icon'>
                                        <div className='calender_datepicker' onClick={() => setleftDatePickerOpen(!isleftDatePickerOpen)}>
                                            <DatePicker


                                                open={isleftDatePickerOpen}
                                                className='datepickerown'
                                                minDate={new Date()}
                                                value={formValue.startDate ? moment(formValue.startDate).format('DD-MM-YYYY') : "DD-MM-YYYY"}
                                                onSelect={(date) => {
                                                    let startDate = moment(date).format();
                                                    if (formValue.endDate) {
                                                        if (startDate < formValue.endDate) {
                                                            setFormValue(prev => { return { ...prev, startDate: startDate } })
                                                        } else {
                                                            toast.error("Start Date Should be Less Than End Date")
                                                        }
                                                    } else {
                                                        setFormValue(prev => { return { ...prev, startDate: startDate } })
                                                    }
                                                }
                                                }
                                            />                                            <div className='calender_own'>
                                                <i class="fa-regular fa-calendar-days"></i>
                                            </div>
                                        </div>
                                    </div>

                                    {/* /End Date */}
                                    <div className='calender_datepicker_icon'>
                                        <div className='calender_datepicker' onClick={() => { setrightDatePickerOpen(!isrightDatePickerOpen) }}>
                                            <DatePicker
                                                open={isrightDatePickerOpen}
                                                className='datepickerown'
                                                selected={formValue.startDate ? moment(formValue.startDate).toDate() : undefined}
                                                minDate={formValue.startDate ?
                                                    moment(formValue.startDate).toDate() : new Date()}
                                                value={formValue.endDate ? moment(formValue.endDate).format('DD-MM-YYYY') : "DD-MM-YYYY"}
                                                onSelect={(date) => {
                                                    let endDate = moment(date).format();
                                                    if (formValue.startDate) {
                                                        if (endDate >= formValue.startDate) {
                                                            setFormValue(prev => { return { ...prev, endDate: endDate } })
                                                        } else {
                                                            toast.error("End Date Should be Greater Than Start Date")
                                                        }
                                                    } else {
                                                        setFormValue(prev => { return { ...prev, endDate: endDate } })
                                                    }
                                                }
                                                }
                                            />                                            <div className='calender_own2'>
                                                <i class="fa-regular fa-calendar-days"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* image section */}
                            <div className='inpt_image projectppp'>
                                <p>Attach File</p>
                                <span style={{ display: "block", color: imgerrflag ? "red" : "green" }}>
                                    (Only "jpg","png","pdf" format accepted)</span>
                                <label for="input-file" className='inpt_img_lbl'>
                                    <input
                                        type="file"
                                        id=""
                                        name=""
                                        // accept="image/*"
                                        className="inpt_file_own"
                                        multiple
                                        onChange={(e) => handleUploadImage(e)}
                                    />
                                    <div className='drg_photo'>
                                        <p className="own_para">
                                            Drag, and photo, or <span>Browse</span>
                                        </p>
                                        <p className="own_para_sml">Support all file, max size 60 Mb</p>
                                    </div>
                                </label>
                            </div>

                            <div className='all_cons_img'>
                                {
                                    formValue.image.length !== 0 && formValue.image.map((item, i) =>
                                        <div className='all_cons_imgmain d-flex' >
                                            <img src={item} accept="image/*" alt="/" />
                                            <i
                                                className="fa-regular fa-circle-xmark ml-1 img-del-cross"
                                                onClick={() => deleteSingleImage(i)}
                                            ></i>
                                        </div>
                                    )
                                }
                            </div>

                            {/* Task Budget */}
                            <div className='tskbudget_maain'>
                                <p>Sub Task Budget</p>
                                <div className='task_icon_btn'>
                                    <p>£</p>
                                    <div className='tsk_btn'>
                                        {/* <p>00</p> */}
                                        <input
                                            type='number'
                                            style={{ width: "100%" }}
                                            name="taskBudget"
                                            value={formValue.taskBudget}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Completation Percentage */}
                            <div className='tskbudget_maain'>

                                <div className='task_icon_btn'>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                        <p>Completation Percentage </p>
                                        <small style={{ color: "black", fontWeight: "bold" }}>(Set the percent of work that will be completed if
                                            this task is completed,and be aware that all tasks within this project cannot exceed 100%)</small>
                                    </div>
                                    <div className='tsk_btn'>
                                        {/* <p>00</p> */}
                                        <input
                                            type='number'
                                            style={{ width: "100%" }}
                                            name="cPercentage"
                                            value={formValue.cPercentage}
                                            onChange={(e) => {

                                                handleChange(e)
                                            }}
                                        />
                                        <p>%</p>
                                    </div>
                                </div>
                            </div>





                            <div className='tskbudget_maain'>
                                <p>Add Project</p>
                                <div className='task_icon_btn'>
                                    <div className='tsk_btn'>
                                        <input
                                            type='radio'
                                            style={{ width: "100%" }}
                                            name="yes"
                                            checked={projectyes}
                                            onClick={(e) => {
                                                setProjectYes(true)
                                                setProjectNo(false)
                                            }}
                                        />
                                        <p style={{ marginLeft: '5px' }}>Yes</p>

                                    </div>
                                    <div className='tsk_btn'>
                                        <input
                                            type='radio'
                                            style={{ width: "100%" }}
                                            name="no"
                                            checked={projectno}
                                            onClick={(e) => {
                                                setProjectYes(false)
                                                setProjectNo(true)
                                            }}
                                        />
                                        <p style={{ marginLeft: '5px' }}>No</p>

                                    </div>
                                </div>
                            </div>
                            {
                                projectyes &&
                                <div className='tskbudget_maain'>
                                    <div className='task_icon_btn'>

                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            defaultValue={projectdata[0]}
                                            name="color"
                                            options={projectdata}
                                            onChange={(val) => setSelectPId(val.value)}
                                        />
                                    </div>
                                </div>
                            }
                            <div className='update_btn'>
                                <a
                                    href="#"
                                    className='update_btn_main'
                                    onClick={handleCreateSubTask}
                                >
                                    Submit Subtask
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
