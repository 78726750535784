import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import proImg from "../../src/Images/project2225.png";
import ppc from "../../src/Images/profile-pic.png";
import dp from "../../src/Images/dp.png";
import ppc3 from "../../src/Images/rply_img.png";
import atImg from "../../src/Images/cons1.png";
import pdImg from "../../src/Images/pdf.png";
import BidProjectModal from "../../src/Component/Modal/BidProjectModal";
import SuccessfullybidprojectModal from "../../src/Component/Modal/SuccessfullybidprojectModal";
import { useEffect } from "react";
import ProductServices from "../services/ProductServices";
import { useSelector } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import HttpClient from "../utils/HttpClient";
import moment from "moment";
import { Link } from "react-router-dom";
import ChattModel from "./Modal/ChattModel";

function BidViewProjectDetails({ viewflag }) {
  const { userData } = useSelector((state) => state.User);
  const [BidModal, setBidModal] = useState(false);
  const [SuccbidModal, setSuccbidModal] = useState(false);
  const [singledata, setSingledata] = useState({});
  const [chattFlag, setchattFlag] = useState(false);
  const [senderId, setsenderId] = useState("");
  const [bidderdata, setbidderdata] = useState("");
  const [biderimage, setbiderimage] = useState("");

  const [taskId, settaskId] = useState("");
  const [projectId, setprojectId] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  const OpenBidproject = () => {
    setBidModal(!BidModal);
  };
  const onclick = () => {
    setSuccbidModal(!SuccbidModal);
  };
  const gotoHome = () => {
    setSuccbidModal(!SuccbidModal);
    navigate("/");
  };

  // console.log("bidData", bidData);
  useEffect(() => {
    getsingleProject();
    // getAllbidedProject();
  }, []);

  // function getAllbidedProject() {
  //   ProductServices.bidedproject()
  //     .then((res) => {
  //       console.log("res bided project", res);
  //       // if (res && res.status) {
  //       //   setAllJobes(res.data)
  //       // }
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // }

  const getsingleProject = () => {
    ProductServices.getsingleProject(id)
      .then((res) => {
        if (res && res.status) {
          setSingledata(res?.data);
          console.log("singledawwta", res?.data);
        }
      })
      .catch((err) => {});
  };

  const handleAccept = async (e, id) => {
    e.preventDefault();

    let data = {
      acceptStatus: "Accepted",
    };

    const res = await ProductServices.statusChange(data, id);
    if (res && res.status) {
      navigate("/");
    }
    console.log("datawa", res);
  };

  console.log("singledata", singledata);

  const handlereject = async (e, id) => {
    e.preventDefault();

    let data = {
      acceptStatus: "Rejected",
    };
    const res = await ProductServices.statusChange(data, id);
    if (res && res.status) {
      window.location.reload();
    }
    console.log("datawa", res);
  };
  console.log("singledata?.userData?.image", singledata?.userData?.image);

  const sectionRef = useRef(null);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const location = useLocation();
  console.log(location.state, "lhuil");
  useEffect(() => {
    if (location.state === "showbodder") {
      scrollToSection();
    }
  }, []);

  return (
    <>
      <section className="BidViewProjectDetails">
        <div className="head_line">
          <Link
            // to={location.state === "showbodder" ? "window.historyback" : "/Proposal"}
            onClick={() => {
              window.history.back();
            }}
            className="posted_icon"
          >
            <i className="fa-solid fa-arrow-left"></i>
            View Service
          </Link>
        </div>

        <div className="projectImg_wrap">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            {singledata?.image?.map((it, ind) => {
              return (
                <SwiperSlide key={ind}>
                  <div className="projImg">
                    <img src={it} className="img-fluid" alt="" />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="d-flex align-items-center justify-content-between pt-2">
          <div className="projectName">
            <h4>{singledata?.name}</h4>
          </div>
          <div className="bookmark_icon">
            <i className="fa-regular fa-bookmark"></i>
          </div>
        </div>

        <div className="d-flex align-items-center mb-4">
          <button className="Attachment_btn btn mr-3">
            <i className="fa-regular fa-clock mr-2"></i>{" "}
            {/* {moment(singledata?.updatedAt).startOf("day").fromNow()} */}
            {moment(singledata?.createdAt).fromNow()}
          </button>
          <button className="Attachment_btn btn">
            <i className="fa-solid fa-link mr-2"></i> Attachment{" "}
            {singledata?.image?.length}
          </button>
        </div>

        <div className="ProjectPostedBy">
          <h4 className="heding">Project Posted By</h4>

          <div className="profile mb-3">
            <div className="profPic">
              <img
                src={singledata?.userData?.image ?? dp}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="details">
              <div className="d-flex align-items-center mb-1">
                <h5>{singledata?.userData?.username}</h5>
                <p>
                  Rating <span>(0.0)</span>
                </p>
              </div>
              <h6> {moment(singledata?.createdAt).fromNow()}</h6>
            </div>
          </div>

          <div
            style={{ display: viewflag ? "block" : "none" }}
            className="bottom_area"
          >
            <div className="left">
              <p
                style={{
                  fontWeight: "600",
                  color: "#000",
                  fontSize: "14px",
                  fontFamily: "Plus Jakarta Sans",
                }}
                className="heding"
              >
                My bided Price
              </p>
            </div>
            <div className="right">
              <h4 className="heding">
                {singledata?.bidData
                  ?.filter((ele) => ele.email === userData?.email)
                  .map((bid) => `${bid.price} £`)}
              </h4>
            </div>
          </div>

          <div style={{ display: viewflag ? "none" : "block" }}>
            <div className="ProjectDescription mb-3">
              <h4>Project Description:</h4>
              <p>{singledata?.description}</p>
              <h4>Project Categories:</h4>
              <ul>
                {singledata?.categories?.map((it, ind) => {
                  return <li key={ind}>{it}</li>;
                })}
              </ul>
            </div>

            <div className="ProjectDescription left mb-3">
              <h4>Total Project Price</h4>
              <p>
                £{singledata?.startCost} -£{singledata?.endCost}
              </p>
            </div>

            <h4 className="heding">Attachment:</h4>

            <div className="attachment_wrap mb-3">
              {singledata?.image?.map((it, ind) => {
                return (
                  <div className="attacImg" key={ind}>
                    <img src={it} className="img-fluid" alt="" />
                    {/* <div className='downloadbtn'>
                                            <i className="fa-solid fa-download"></i>
                                        </div> */}
                  </div>
                );
              })}
            </div>
            {/* <a id="focus_sec"></a> */}
            <div
              className="d-flex align-items-center justify-content-between"
              ref={sectionRef}
            >
              <h4 className="heding">
                Total bids for this project({singledata?.bidData?.length})
              </h4>
              {/* <button className='Seemore_btn'>See more</button> */}
            </div>

            {singledata?.bidData?.filter(
              (item) => item.acceptStatus === "Accepted"
            ).length > 0 ? (
              <>
                {" "}
                {singledata?.bidData
                  ?.filter((item) => item.acceptStatus === "Accepted")
                  ?.map((it, ind) => {
                    console.log("bidder_data", it);
                    return (
                      <>
                        <div className="profile_wrap_main" key={ind}>
                          <div className="profile">
                            <div className="profPic">
                              <img
                                // src={HttpClient.IMAGE_URL + it?.image[0]}
                                src={it?.bidderData?.image ?? dp}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div
                              style={{
                                width: "90%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="details">
                                <div className="d-flex align-items-center mb-1">
                                  <h5>{it?.bidderData?.username}</h5>
                                  <p>
                                    Rating{" "}
                                    <span style={{ color: "gray" }}>(0.0)</span>
                                  </p>
                                </div>
                                <h6>
                                  {moment(singledata?.updatedAt).fromNow()}
                                </h6>
                              </div>
                              <h6
                                style={{
                                  fontWeight: "600",
                                  color: "#000",
                                  fontSize: "14px",
                                  fontFamily: "Plus Jakarta Sans",
                                }}
                              >
                                {" "}
                                £ {it?.bidPrice}{" "}
                              </h6>
                            </div>
                          </div>
                          <div className="text">
                            <p>{it?.message}</p>
                          </div>
                        </div>

                        {it?.acceptStatus === "Rejected" ? (
                          <div>
                            <button className="vw_applctn rjct">
                              Rejected
                            </button>
                          </div>
                        ) : it?.acceptStatus === "Accepted" ? (
                          <div style={{ display: "flex" }}>
                            <Link
                              to={`/application_details/${it?._id}/${id}`}
                              className="vw_applctn"
                            >
                              View Application
                            </Link>
                            <button className="vw_applctn acptd">
                              Accepted
                            </button>
                            {/* <button
                              className="Attachment_btn btn"
                              onClick={() => {
                                // setprojectId(singledata._id);
                                setprojectId(singledata._id);
                                // // setsenderId(item.bidderId)
                                // // setchatProjectname(item.projectDetail.name)
                                setchattFlag(true);

                                console.log("lltewr", singledata);
                              }}
                            >
                              <i class="fas fa-comments"></i>
                              Chatt
                            </button> */}
                          </div>
                        ) : (
                          <div style={{ display: "flex" }}>
                            <Link
                              to={`/application_details/${it?._id}/${id}`}
                              className="vw_applctn"
                            >
                              View Application
                            </Link>
                            <button
                              className="vw_applctn acptd"
                              onClick={(e) => handleAccept(e, it?._id)}
                            >
                              Accept
                            </button>
                            <button
                              className="vw_applctn rjct"
                              onClick={(e) => handlereject(e, it?._id)}
                            >
                              Reject
                            </button>
                          </div>
                        )}
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                {singledata?.bidData?.map((it, ind) => {
                  console.log("bidder_data", it);
                  return (
                    <>
                      <div className="profile_wrap_main" key={ind}>
                        <div className="profile">
                          <div className="profPic">
                            <img
                              // src={HttpClient.IMAGE_URL + it?.image[0]}
                              src={it.bidderData?.image ?? dp}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div
                            style={{
                              width: "90%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="details">
                              <div className="d-flex align-items-center mb-1">
                                <h5>{it?.bidderData?.username}</h5>
                                <p>
                                  Rating{" "}
                                  <span style={{ color: "gray" }}>(0.0)</span>
                                </p>
                              </div>
                              <h6>
                                {moment(singledata?.updatedAt)
                                  .startOf("day")
                                  .fromNow()}
                              </h6>
                            </div>
                            <h6
                              style={{
                                fontWeight: "600",
                                color: "#000",
                                fontSize: "14px",
                                fontFamily: "Plus Jakarta Sans",
                              }}
                            >
                              {" "}
                              £ {it?.bidPrice}{" "}
                            </h6>
                          </div>
                        </div>
                        <div className="text">
                          <p>{it?.message}</p>
                        </div>
                      </div>

                      {it?.acceptStatus === "Rejected" ? (
                        <div>
                          <button className="vw_applctn rjct">Rejected</button>
                        </div>
                      ) : it?.acceptStatus === "Accepted" ? (
                        <div style={{ display: "flex" }}>
                          <Link
                            to={`/application_details/${it?._id}/${id}`}
                            className="vw_applctn"
                          >
                            View Application
                          </Link>
                          <button className="vw_applctn acptd">Accepted</button>
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <Link
                            to={`/application_details/${it?._id}/${id}`}
                            className="vw_applctn"
                          >
                            View Application
                          </Link>
                          <button
                            className="vw_applctn acptd"
                            onClick={(e) => handleAccept(e, it?._id)}
                          >
                            Accept
                          </button>
                          <button
                            className="vw_applctn rjct"
                            onClick={(e) => handlereject(e, it?._id)}
                          >
                            Reject
                          </button>
                          <button
                            className="Attachment_btn btn"
                            onClick={() => {
                              //CHATT FOR HOME OWNER
                              setprojectId(singledata._id);
                              console.log(it, "wwq");
                              setsenderId(it?.bidderData?._id);
                              // setsenderId(singledata?.userData._id);
                              // // setchatProjectname(item.projectDetail.name)
                              setchattFlag(true);
                              setbiderimage(it?.bidderData.image);
                              setbidderdata("659d1050c68d2a374ee1e76f");
                              const ownbiddata = singledata?.bidData.filter(
                                (ele) => (ele._id = it._id)
                              );
                              settaskId(ownbiddata[0]?._id);
                              console.log("lltewree", it, ownbiddata);
                            }}
                          >
                            <i class="fas fa-comments"></i>
                            Chat
                          </button>
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </div>

          {/* {singledata?.bidData?.map((it, ind) => { */}
          {/* {bidData?.map((it, ind) => {
            console.log("bidder_data", it?._id);
            return (
              <>
                <div className="profile_wrap_main" key={ind}>
                  <div className="profile">
                    <div className="profPic">
                      <img
                        // src={HttpClient.IMAGE_URL + it?.image[0]}
                        src={dp}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        width: "90%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="details">
                        <div className="d-flex align-items-center mb-1">
                          <h5>{it?.bidderData?.username}</h5>
                          <p>
                            Rating <span style={{ color: "gray" }}>(0.0)</span>
                          </p>
                        </div>
                        <h6>
                          {moment(singledata?.updatedAt)
                            .startOf("day")
                            .fromNow()}
                        </h6>
                      </div>
                      <h6
                        style={{
                          fontWeight: "600",
                          color: "#000",
                          fontSize: "14px",
                          fontFamily: "Plus Jakarta Sans",
                        }}
                      >
                        {" "}
                        £ {it?.bidPrice}{" "}
                      </h6>
                    </div>
                  </div>
                  <div className="text">
                    <p>{it?.message}</p>
                  </div>
                </div>

                {it?.acceptStatus === "Rejected" ? (
                  <div>
                    <button className="vw_applctn rjct">Rejected</button>
                  </div>
                ) : it?.acceptStatus === "Accepted" ? (
                  <div style={{ display: "flex" }}>
                    <Link
                      to={`/application_details/${it?._id}/${id}`}
                      className="vw_applctn"
                    >
                      View Application
                    </Link>
                    <button className="vw_applctn acptd">Accepted</button>
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    <Link
                      to={`/application_details/${it?._id}/${id}`}
                      className="vw_applctn"
                    >
                      View Application
                    </Link>
                    <button
                      className="vw_applctn acptd"
                      onClick={(e) => handleAccept(e, it?._id)}
                    >
                      Accept
                    </button>
                    <button
                      className="vw_applctn rjct"
                      onClick={(e) => handlereject(e, it?._id)}
                    >
                      Reject
                    </button>
                  </div>
                )}
              </>
            );
          })} */}
        </div>

        <div className="">
          {/* <div className="left">
            <h6>
              $ {singledata?.startCost} - ${singledata?.endCost}
            </h6>
            <p>Total Project Price</p>
          </div> */}
          {/* {console.log(
            "singledata?.bidData?.findIndex((it) => it.bidderData._id == userData._id)",
            singledata?.bidData?.findIndex(
              (it) => it.bidderData._id == userData._id
            )
          )} */}
          {/* {singledata?.bidData?.findIndex(
            (it) => it.bidderData._id == userData._id
          ) >= 0 ? null : (
            <div className="right">
              <button className="Bidthisproject_btn" onClick={OpenBidproject}>
                Bid this project
              </button>
            </div>
          )} */}
          {/* <div className="acpt_rjct">
            <button className="btn btn-success btn-sm mr-2">Accept</button>
            <button className="btn btn-danger btn-sm">Reject</button>
          </div> */}
        </div>
        {chattFlag && (
          <ChattModel
            task_id={taskId}
            // task_id={projectId}
            Sender_Id={senderId}
            // chatProjectname={chatProjectname}
            bidderdata={bidderdata}
            biderimage={biderimage}
            projectdata={singledata}
            projectId={projectId}
            setchatFlag={setchattFlag}
            projectsenderId={singledata?.userData?._id}
          />
        )}
      </section>

      {BidModal && (
        <BidProjectModal
          closemodal={setBidModal}
          falsemodal={() => setSuccbidModal(!SuccbidModal)}
          biddeddata={singledata}
        />
      )}
      {SuccbidModal && <SuccessfullybidprojectModal closeModal={gotoHome} />}
    </>
  );
}

export default BidViewProjectDetails;
