import React, { useEffect, useState } from "react";
import PaymentreceivedModal from "./Modal/PaymentreceivedModal";
import PaymentdueModal from "./Modal/PaymentdueModal";
import PaymentDetailsCard from "./PaymentDetailsCard";
import LendersCard from "./LendersCard";
import SupplierCard from "./SupplierCard";
import wallwtlogo from "../Images/wallet.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProductServices from "../services/ProductServices";
import moment from "moment";
import toast from "react-hot-toast";
import AuthServices from "../services/AuthServices";
import { useSelector } from "react-redux";
import PaymentSucsessModel from "./Modal/PaymentSucsessModel";
import { Oval, ThreeDots, Watch } from "react-loader-spinner";
import CustomLoader from "./loader/CustomLoader";
// import logo from "./Modal/"
const stripelogo = require("../Images/stripelogo (1).png")

function Payment(props) {
  const initial = {
    IFSC: "",
    accountNo: "",
    CIF: "",
    accHolderName: "",
    routingno: "",
  };
  const [wallet, setWallet] = useState(false);
  const navigate = useNavigate();
  const [bankdetails, setBankDetails] = useState(initial);

  const [paymentreceived, setpaymentreceived] = useState(false);
  const [tasacData, settransacdata] = useState([]);
  const [amount, setAmount] = useState("");
  const [Price, setPrice] = useState(0);
  const [withdrawablePrice, setwithdrawablePrice] = useState(0)
  const { userData } = useSelector((state) => state.User);
  const [PaymentSucsessModelflaf, setPaymentSucsessModelflag] = useState(false);
  const [quaryparam, setquaryparam] = useState("");
  const [withdraflag, setWithdraflag] = useState(false);
  const [accountdetailsflag, setAccountdetailsflag] = useState(false);
  const [isBankAccountexist, setisBankAccountexist] = useState(false);
  const [withdrawalHistory, setwithdrawalHistory] = useState([]);

  console.log("hhtr", bankdetails);

  const [searchParams, setSearchParams] = useSearchParams();
  const [flagbeforeStripe, setflagbeforeStripe] = useState(false);
  const [isStripeUrlopen, setisStripeUrlopen] = useState(false)
  const [isBaccountCreated, setisBaccountCreated] = useState(false)
  console.log(isStripeUrlopen)
  // alert(isStripeUrlopen)
  const deciNo = ((Number(amount) * 1.5 / 100) + 0.2).toFixed(2)
  //for taking bank input
  const changeHandler = (e) => {
    if (e.target.name === "routingno") {
      // let tempval = e.target.value?.split("-").join('')
      // console.log(tempval)



      const tempval = e.target.value
        .replace(/\D/g, '')
        .slice(0, 6)
        .replace(/(\d{2})(?=\d)/g, '$1-');
      setBankDetails((prev) => ({ ...prev, routingno: tempval }))

    } else {
      if (e.target.name === "accHolderName") {
        const tempval = e.target.value.slice(0, 30)
        setBankDetails((prev) => ({ ...prev, accHolderName: tempval }))

      } else {
        const { name, value } = e.target;

        setBankDetails((prev) => ({ ...prev, [name]: value }));
      }

    }
  };

  //AddWalletBalance
  const AddWalletBalance = async () => {
    if (!amount) {
      toast.error("please enter some Amount");
      return;
    }

    const res = await ProductServices.addbalancewallet({
      balance: Number(amount),
      details: "Amount Credited",
    });

    if (res && res.status) {
      setAmount("");
      setWallet(false);
      get_walletBalance();
      AllBalanceHistory();
    }
  };



  //Stripe BankAccountCreate

  const handleAddAccount = () => {
    // Check if any of the required fields are empty
    if (!bankdetails.accountNo.trim() || !bankdetails.accHolderName.trim() || !bankdetails.routingno) {
      toast.error('Please fill out all fields.');
      return;
    }
    setisStripeUrlopen(true)

    ProductServices.createstripeaccount({
      "account_number": bankdetails.accountNo,
      "account_holder_name": bankdetails.accHolderName,
      "routing_number": bankdetails.routingno
    })
      .then(res => {
        console.log("res", res);
        if (res && res.status) {
          console.log("gvfhg", res)
          window.open(res.data.url)
          setisStripeUrlopen(false)
          setBankDetails(initial)
          setAccountdetailsflag(false);
          // alert('account updated successfully');
          // ProductServices.verifyBankAccount(bankdetails.accountNo)
          //   .then(result => {
          //     alert("rrr")
          //     console.log("result", result);
          //   })
          //   .catch(err => {
          //     console.log("err", err);
          //   })
        } else {
          setisStripeUrlopen(false)
          setBankDetails(initial)
          toast.error(res.data)
        }
      })
      .catch(err => {
        // alert(err.data)
        console.log("err", err);
      })


  };

  function formatString(input) {
    const chunks = input?.match(/.{1,2}/g); // Split input into chunks of two characters
    return chunks?.join('-'); // Join chunks with hyphen in between
  }


  //his own Bank AccountDetails
  const getBankDetails = async () => {
    const res = await ProductServices.getStripeBankDetails();
    if (res && res.status) {
      console.log("kkwe", res)
      const tempdata = bankdetails;
      tempdata.accHolderName = res.data?.accountHolderName;
      tempdata.accountNo = res.data?.accountNo;
      tempdata.routingno = formatString(res.data?.routingNumber)

      if (res.data?.accountNo) {
        setBankDetails(tempdata);
        setisBankAccountexist(true);
      }
      // alert(res)
    }
  }

  // const getAccountDetails = async () => {
  //   const res = await ProductServices.getBankDetails();
  //   if (res && res.status) {
  //     console.log("ppr", res.data.length);
  //     if (res.data.length != 0) {
  //       const tempdata = bankdetails;
  //       tempdata.IFSC = res.data[0].IFSC;
  //       tempdata.CIF = res.data[0].CIF;
  //       tempdata.accHolderName = res.data[0].accHolderName;
  //       tempdata.accountNo = res.data[0].accountNo;

  //       setBankDetails(tempdata);
  //       setisBankAccountexist(true);
  //       //setAccountdetailsflag(true);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   setAccountdetailsflag(true)
  // }, [bankdetails])

  //stripe Payment

  const PaymentProceed = async () => {
    if (amount > 0) {
      setisStripeUrlopen(true)
      const data = {
        price: amount,
        name: "Wallet recharge ",
        customer: userData.stripe_customer_Id,
        //success_url: `https://login.buildbeta.co.uk/project-detail/${projectId}?paymentSuccess=true&taskId=${singleTaskData._id}`,

        // success_url: `http://localhost:3000/payment?paymentSuccess=true&amount=${amount}`,
        success_url: `https://login.buildbeta.co.uk/payment?paymentSuccess=true&amount=${amount}`,


        type: "payment",
      };
      const res = await ProductServices.Task_PaymentProceed(data);
      if (res && res.status) {
        window.open(res.data.url);
        setisStripeUrlopen(false)
        setAmount("")
      }
    } else {
      setAmount("")
      toast.error("please enter some amount")
    }
  };

  //get wallet balance
  const get_walletBalance = () => {
    AuthServices.fetchProfile()
      .then((res) => {
        if (res && res.status) {
          console.log("fdghgh", res, userData);

          setPrice(res?.data?.[0]?.walletBalanceReceived ?? 0);
          setwithdrawablePrice(res?.data?.[0]?.walletBalance ?? 0)
        }
      })
      .catch((err) => {
        console.log("dfhjh", err);
      });
  };

  //balance history
  const AllBalanceHistory = () => {
    // ProductServices.walletHistory()
    //   .then(res => {
    //     console.log("restty", res);
    //     if (res && res.status) {
    //       settransacdata(res.data.reverse())
    //     }
    //   })
    //   .catch(err => {
    //     console.log("err", err);
    //   })

    //get payment history
    ProductServices.PaymentHistoryDetails()
      .then((res) => {
        console.log("reeestty", res);
        if (res && res.status) {
          // setAllBank(res.data)
          settransacdata(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //requestforWithdrawal
  const requestforWithdrawal = async () => {
    // setWithdraflag(false)
    // setAccountdetailsflag(true)
    // return;

    if (!amount) {
      toast.error("please enter some Amount");
      return;
    }
    try {
      setisStripeUrlopen(true)
      const res = await ProductServices.balanceWithdrawal({
        balance: Number(amount),
        details: "Withdrawal ",
      });

      if (res && res.status) {

        setWithdraflag(false);
        get_walletBalance();
        AllBalanceHistory();
        setisStripeUrlopen(false)
        // getResult();
        setAmount("")
      } else {
        setAmount("");
        console.log("ooorrr", res.msg);
        toast.error(res.msg);
        // setWithdraflag(false)
        // setWallet(true)
        setisStripeUrlopen(false)
      }
    } catch (err) {
      setisStripeUrlopen(false)
      setAmount("");
      console.log(err);
      // setWallet(true)

    }
  };

  //withdrawal Request list
  // const getResult = async () => {
  //   const res = await ProductServices.getWithdrawalRequest();
  //   if (res && res.status) {
  //     setwithdrawalHistory(res.data);
  //   }
  // };

  useEffect(() => {
    const qr = searchParams.get("paymentSuccess");
    const payedamount = searchParams.get("amount");
    const isCreateBankdetails = searchParams.get("isCreate")
    if (isCreateBankdetails) {
      setisBaccountCreated(true)
      setPaymentSucsessModelflag(true)
    }
    setAmount(payedamount);
    setquaryparam(qr);
    getBankDetails()
    // getAccountDetails();
    // getResult();
    console.log(isCreateBankdetails, "qr");
  }, []);

  useEffect(() => {
    if (quaryparam == "true") {
      // alert("true")

      setPaymentSucsessModelflag(true);
      AddWalletBalance();
    }
  }, [quaryparam]);

  useEffect(() => {
    get_walletBalance();
    AllBalanceHistory();
    // getResult();
  }, []);


  //dashed input
  // function formatString(input) {
  //   const chunks = input?.match(/.{1,2}/g) || [];
  //   console.log("lkjekjk", input, chunks.join('-'))
  //   let tempdata = chunks.join('-')
  //   return tempdata;
  // }





  return (
    <>
      <section className="main_wrap">
        <div
          className="row-lg-4 row-md-4 row-12 pr-0 "
          style={{ display: "flex", flexDirection: "column" }}
        >
          {/* <div style={{ margin: "10px 50px 30px 50px", float: "right" }} >
                        <div style={{
                            float: "right", display: "flex", flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                           
                            border: "2px solid black",
                            padding: "30px",
                            borderRadius: "10%"
                        }}>
                            <strong onClick={() => navigate("/Wallet")}>My Wallet</strong>
                            <img onClick={() => navigate("/Wallet")} style={{ height: "50px", width: "50px", float: "right", }} src={wallwtlogo}></img></div>

                    </div> */}

          {/* <div className="rightsidebar">
                        <div style={{width:'38%'}}>
                        <PaymentDetailsCard
                            Payment="Payment Received"
                            text="Lorem ipsum dolor sit amet consectetur"
                            amout="£0"
                            btnname="View Details"
                            bgcolor="#04B600"
                            Receivedbtn={true}
                        /></div>
                        <div style={{width:'38%'}}>
                        <PaymentDetailsCard
                            Payment="Payment Due"
                            text="Lorem ipsum dolor sit amet consectetur"
                            amout="£0"
                            btnname="View Details"
                            bgcolor="#7F6DF0"
                            Duebtn={true}
                        />
                        </div>


                    </div> */}

          <div className="credit_history">
            <div className="wrap mb-5">
              {/* <a href="#" className="top">

              </a> */}
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setWithdraflag(true);
                }}
              >
                <a href="#" className="top">
                  Request for withdrawal
                </a>
              </div>
              <div style={{ cursor: "pointer" }}>
                <a
                  href="#"
                  className="top"
                  onClick={() => {
                    setAccountdetailsflag(true);
                  }}
                  style={{ display: isBankAccountexist ? "none" : "block" }}
                >
                  Add Bank Details
                </a>
                <a
                  href="#"
                  className="top"
                  onClick={() => {
                    // getAccountDetails();
                    setAccountdetailsflag(true);
                  }}
                  style={{ display: isBankAccountexist ? "block" : "none" }}
                >
                  Update Bank Details
                </a>
              </div>


              <div>
                <h5>
                  <i class="fa-solid fa-sterling-sign"></i>{" "}
                  {Math.abs(withdrawablePrice + Price).toFixed(2)}
                </h5>
                <div
                  className="my_wallet"
                  onClick={() => {
                    setWallet(true);
                  }}
                >
                  <p style={{
                    color: "red", paddingBottom: "5px",
                    fontWeight: "30px", fontSize: "12px"
                  }}>withdrawable Amount:    <i class="fa-solid fa-sterling-sign"></i>  {Math.abs(Price).toFixed(2)}</p>
                  <strong>My Wallet</strong>
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "10px",
                    }}
                    src={wallwtlogo}
                  ></img>
                </div>
              </div>

            </div>
            <div
              style={{
                display: "flex",
                gap: "30px",
                justifyContent: "space-between",
              }}
            >
              <div className="table-responsive  ">
                <div>
                  <h4>Payment History</h4>
                  <table className="">
                    <thead>
                      <tr>
                        <th>Payment ID</th>
                        <th>Date</th>
                        <th className="text-center">Amount</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tasacData?.map((ele, id) => {
                        return (
                          <tr>
                            <td>{ele.paymentId}</td>
                            <td>
                              {moment(ele.createdAt).format("DD/MM/YYYY")}
                              <p>{ele.paymentPurpose}</p>
                            </td>
                            <td className="text-center">
                              {" "}
                              <i class="fa-solid fa-sterling-sign"></i>{" "}
                              {Math.abs(ele.amount)}
                            </td>
                            <td className="text-center">
                              <button>{ele.historyType}</button>
                            </td>
                          </tr>
                        );
                      })}

                      {/* <tr>
                    <td>001</td>
                    <td>
                      12-10-23
                      <p>Lorem Ipsum is simply dummy</p>
                    </td>
                    <td className="text-center">200$</td>
                    <td className="text-center">
                      <button>Credit</button>
                    </td>
                  </tr>
                  <tr>
                    <td>001</td>
                    <td>
                      12-10-23
                      <p>Lorem Ipsum is simply dummy</p>
                    </td>
                    <td className="text-center">200$</td>
                    <td className="text-center">
                      <button>Credit</button>
                    </td>
                  </tr>
                  <tr>
                    <td>001</td>
                    <td>
                      12-10-23
                      <p>Lorem Ipsum is simply dummy</p>
                    </td>
                    <td className="text-center">200$</td>
                    <td className="text-center">
                      <button>Credit</button>
                    </td>
                  </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
          {withdraflag && (
            <div className="wallet_bg">
              {isBankAccountexist ? (
                <div className="wallet_mine account">
                  <a
                    href="#"
                    onClick={() => {
                      setWithdraflag(false);
                    }}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </a>
                  <h5>Request for withdrawal</h5>
                  <form className="wrapForm">
                    <div className="userInput_payment">
                      <p>£</p>
                      <input
                        value={amount}
                        type="number"
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                        placeholder="Enter Amount"
                      ></input>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
                      {/* <small >Powered by <strong>STRIPE</strong></small> */}
                      <img style={{ height: 150, width: 150, objectFit: "contain", position: "absolute", bottom: "-4px" }} src={stripelogo} />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          requestforWithdrawal();
                          setWithdraflag(false);
                        }}
                      >
                        Request
                      </button>
                    </div>

                  </form>
                  {/* {
                    isStripeUrlopen && <div className="loadingBar"><ThreeDots
                      visible={true}
                      height="60"
                      width="1600"
                      color="green"
                      ariaLabel="progress-bar-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                    </div>
                  } */}
                </div>
              ) : (
                <div className="wallet_mine for_mine">
                  <a
                    href="#"
                    onClick={() => {
                      setWithdraflag(false);
                    }}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </a>
                  <div>
                    <p className="text-center">Please Add Your Bank Details</p>
                    <button
                      className="mt-3"
                      onClick={(e) => {
                        e.preventDefault();
                        setAccountdetailsflag(true);
                      }}
                    >
                      Add Bank Details
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}

          {accountdetailsflag && (
            <div className="wallet_bg">
              <div className="wallet_mine account">
                <a
                  href="#"
                  onClick={() => {
                    setWithdraflag(false);
                    setAccountdetailsflag(false);
                  }}
                >
                  <i class="fa-solid fa-xmark"></i>
                </a>
                <h5>
                  {isBankAccountexist
                    ? "Update Bank Details"
                    : "Add Bank Details"}
                </h5>

                <form className="wrapForm">
                  <p>Sort Code:</p>
                  <div className="userInput_payment">

                    <input
                      // value={formatString(bankdetails.routingno)}
                      value={bankdetails.routingno}

                      type="text"
                      name="routingno"
                      onChange={changeHandler}
                    // placeholder="Routing No"
                    ></input>
                  </div>
                  <p>Account No:</p>
                  <div className="userInput_payment">

                    <input
                      value={bankdetails.accountNo}
                      type="text"
                      name="accountNo"
                      onChange={changeHandler}
                    // placeholder="Account No"
                    ></input>
                  </div>


                  <p>Accountholder Name:</p>
                  <div className="userInput_payment">
                    <input
                      style={{ zIndex: 99 }}
                      value={bankdetails.accHolderName}
                      type="text"
                      name="accHolderName"
                      onChange={changeHandler}
                    // placeholder="Account holder Name"
                    ></input>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", position: "relative", bottom: "59px" }}>
                    {/* <small >Powered by <strong>STRIPE</strong></small> */}
                    <img style={{ height: 150, width: 150, objectFit: "contain", position: "absolute", bottom: "-150px" }} src={stripelogo} />
                    <button
                      style={{ marginBottom: "20px", position: "absolute", bottom: "-112Px", left: 250 }}
                      className="mt-5 "
                      onClick={(e) => {
                        e.preventDefault();
                        // SendAccountDetails();
                        handleAddAccount()
                        setWithdraflag(false);
                        // setAccountdetailsflag(false);
                      }}
                    >
                      {isBankAccountexist ? "Go" : "Go"}
                    </button>
                  </div>
                </form>
              </div>

            </div>
          )}
          {wallet && (
            <div className="wallet_bg">

              <div className="wallet_mine">


                <a
                  style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "2rem", marginBottom: "20rem" }}
                  href="#"
                  onClick={() => {
                    setAmount("")
                    setWallet(false);
                  }}
                >
                  {/* <img className="img-container" src={"https://login.buildbeta.co.uk/static/media/logo.5c2d5185f35d839266bc.png"}></img> */}

                  <i class="fa-solid fa-xmark"></i>
                </a>
                <h5>Add To wallet</h5>
                <form className="wrapForm" >
                  <div className="userInput_payment">
                    <p>£</p>
                    <input
                      value={amount}
                      type="number"
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      placeholder="Enter Amount"
                    ></input>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", fontSize: "13px" }}>

                      <p>Transaction Fees &nbsp;</p>
                      <p>£</p>
                      <p>{amount ? ((Number(amount) * 1.5 / 100) + 0.2).toFixed(2) : '0:00'}</p>

                    </div>

                    <div style={{ display: "flex", fontSize: "13px" }}>

                      <p>Payable Amount &nbsp;</p>
                      <p>£</p>
                      <p>{amount ? ((Number(Number(amount) + Number(deciNo)))).toFixed(2) : '0:00'}</p>

                    </div>

                  </div>




                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {/* <small>Powered by <strong>STRIPE</strong></small> */}
                    <img style={{ height: 150, width: 150, objectFit: "contain", position: "absolute", bottom: "-23px" }} src={stripelogo} />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        PaymentProceed();
                        setWallet(false);
                        // setflagbeforeStripe(true)
                      }}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
              {/* {
                isStripeUrlopen && <div className="loadingBar"><ThreeDots
                  visible={true}
                  height="60"
                  width="1600"
                  color="green"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
                </div>
              } */}
            </div>
          )}


        </div>
        {PaymentSucsessModelflaf && (
          <PaymentSucsessModel
            iamFrom={"paymentHistoryPage"}
            isAccountCreate={isBaccountCreated ? "Created" : ""}
            setPaymentSucsessModelflag={setPaymentSucsessModelflag}
          />
        )}


      </section>
      {
        isStripeUrlopen &&


        <div className="loadingBar"><Oval
          height={100}
          width={1200}
          color="green"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}

        />
        </div>
      }


    </>
  );
}

export default Payment;
