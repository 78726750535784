import React, { useEffect } from 'react'
import a from "../../Images/bell.png";
import b from "../../Images/Icon.png"
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getAllNotificatios } from '../../Redux/Reducer/NotificationSlice';
import moment from 'moment';
export default function NotificationModal({ closeModal }) {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllNotificatios())
    }, [])
    const { userData } = useSelector((state) => state.User);
    const { AllNotifications } = useSelector(state => state.NotificationReducer)
    const MyNotification = AllNotifications.filter((ele, id) => {
        // console.log(ele.receiverId, userData._id, "kljklj")
        return ele.receiverId == userData._id
    })
    console.log(MyNotification, "khgjhhjh")

    return (
        <>
            <section className='notificationmodal_main'>
                <div className='notification_modal'>
                    <div className='notification_overlay'></div>
                    <div className='notification_content'>
                        <p className='notification_txt'>Notification</p>
                        <div className='closemark' onClick={() => closeModal(false)}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                        <div className={`notification_all_main ${MyNotification.length < 0 ? 'hghgjg' : 'null'}`}
                        >



                            {MyNotification.length > 0 ? MyNotification.map((ele, id) => {


                                return (
                                    <div className='notification_all_flx'>
                                        <div className='notification_img'>
                                            {/* <img src={a} alt="/" /> */}
                                            <i class="fa-regular fa-bell"></i>
                                        </div>
                                        <div className='notification_details'>
                                            <h3>Buildbeta</h3>
                                            <p>{ele.data}</p>
                                            <p className='time'> {moment(ele.createdAt).format("MMM D, YYYY [at] h:mm A")}
                                            </p>

                                        </div>
                                    </div>
                                )

                            }) :
                                <div className='notification_all_flx' style={{ marginLeft: "4rem", marginRight: "4rem" }}>
                                    <div className='notification_img'>
                                        {/* <img src={a} alt="/" /> */}
                                        <i class="fa-regular fa-bell"></i>
                                    </div>
                                    <div className='notification_details'>

                                        <p>No message found</p>


                                    </div>
                                </div>
                            }


                            {/* 
                            <div className='notification_all_flx'>
                                <div className='notification_img'>
                                    <img src={a} alt="/" />
                                </div>
                                <div className='notification_details'>
                                    <h3>Buildbeta</h3>
                                    <p>You have requested a partial payment of £200 for Project Name 1</p>
                                    <p className='time'>Jun 23, 2023 at 21:22 Pm</p>

                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
