import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
const initialState = {
    updateflag: false,
    tabid: 1,

}

const ServiceSlice = createSlice({
    name: "update_Service",
    initialState,
    reducers: {
        changeUpdateState: (state, action) => {
            // alert(action.payload)
            state.updateflag = action.payload;

        },

    },

})

export const { changeUpdateState, } = ServiceSlice.actions;
export const ServiceReducer = ServiceSlice.reducer;