import React from 'react'
import { useNavigate } from 'react-router-dom';
import succ from "../../Images/check_img.avif";

function SuccessfullybidprojectModal(props) {
    const { closeModal } = props;
    return (
        <>
            <section className="success">
                <div className="success_page">
                    <div className="success_img">
                        <img src={succ} className='img-fluid' alt="check_img" />
                    </div>
                    <div className="success_title">
                        <p>You Successfully bidded this project</p>
                        {/* <span>Lorem ipsum repudiandae aperiam harum quaerat illo eaque!</span> */}
                    </div>
                    <div className="home_btn">
                        <button className="back_home_btn" onClick={() =>
                            closeModal()
                        }>Back To Home</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SuccessfullybidprojectModal