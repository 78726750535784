import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'userData',
  initialState: {
    userData: {},
    login_status: false,
    useremail: '',
  },
  reducers: {
    setUser(state, action) {
      const user = action.payload;
      console.log('mypayload', user)
      return { ...state, userData: user, login_status: true }
    },
    setImg: (state, action) => void (state.userData.image = action.payload),
    removeUser(state, action) {
      return { ...state, userData: {}, login_status: false }
    },
    setEmail: (state, action) => {
      void (state.useremail = action.payload)
    },
  }
})

export const { setUser, setImg, removeUser, setEmail, setAllProduct } = userSlice.actions;

export default userSlice.reducer;
