import React, { useEffect, useState } from "react";
import PaymentDetailsCard from "../../Component/PaymentDetailsCard";
import SupplierCard from "../../Component/SupplierCard";
import LendersCard from "../../Component/LendersCard";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import "./termsAndcondition.css";

const TermsAndCondition = () => {
  return (
    <>
      <div className="main_wrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <h2
                style={{ marginLeft: "8rem", marginBottom: "2rem" }}
                className="mt-3"
              >
                Terms and Conditions
              </h2>
              <div className="middlepartwrap">
                <div>
                  <h4 className="mb-3">Who we are</h4>
                  <p>
                    BuildBeta is a site, service and platform that provides
                    services to maximize productivity, minimize time and costs
                    in building projects. The site and platform is owned and the
                    service is provided by NEXTSPACE CONSTRUCTION UK ('Nextspace
                    Construction', “us” and “we” below),)(Company Number
                    14731346, a limited company registered in England and Wales
                    and our office address is Boundary House, Cricket Field
                    Road, Uxbridge, United Kingdom, UB8 1QG. To contact us,
                    please email contact@BuildBeta.co.uk.
                  </p>
                </div>

                <div className="mt-3">
                  <h4 className="mb-3">When these terms apply</h4>
                  <p>
                    These Terms and Conditions (“Terms”) apply when you (“your”
                    and “user” below) create an account to use any features,
                    services, products or tools offered on our platform at
                    https://buildbeta.co.uk
                    <br />
                    To use our services you must agree to these Terms so please
                    read them carefully. We recommend that you download these
                    Terms for future reference. We’ll ask you to confirm that
                    you agree to these Terms when you create an account to use
                    the platform and services and a contract will come into
                    existence between you and us when you confirm you accept the
                    Terms. If you use any of our services before you have
                    confirmed that you accept these Terms then, by using our
                    services, you agree that you have read, understood and
                    accepted these Terms and our Privacy Policy.
                    <br />
                    You are responsible for ensuring that all persons who use
                    our services through your internet connection are aware of
                    these terms and our privacy policy and that they comply with
                    them.
                  </p>
                </div>

                <div>
                  <h4 className="mb-3">Accessing our site</h4>
                  <p>
                    Access to our site is permitted on a temporary basis, and we
                    reserve the right to withdraw or amend the service we
                    provide on our site without notice. We will not be liable if
                    for any reason our site is unavailable at any time or for
                    any period.
                  </p>
                </div>

                <div>
                  <h4 className="mb-3">Your data</h4>
                  <p>
                    Your personal and company data is sensitive and entitled to
                    protection. Any personal, company and user-generated
                    information will only ever be uploaded voluntarily by you
                    and you confirm that you have obtained the necessary consent
                    and have given appropriate notices to lawfully upload any
                    individual’s personal data to our platform for the duration
                    and purposes of these Terms. We’ll never share or make your
                    data or information available to anyone without your
                    explicit permission (other than being legally required such
                    as by a court order).
                  </p>
                </div>

                <div>
                  <h4 className="mb-3">Account creation</h4>
                  <p>
                    If you establish an account on the platform, you are
                    responsible for maintaining the confidentiality of your user
                    ID and password, and you are responsible for all activities
                    that occur under your password or user ID. You agree to: (i)
                    log out from your account at the end of each session; and
                    (ii) immediately notify us at contact@BuildBeta.co.uk of any
                    unauthorised use of your password or user ID or any other
                    breach of security.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-12 pr-0">
              <div className="rightsidebar">
                <LendersCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TermsAndCondition;
