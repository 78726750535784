import React, { useEffect, useState } from "react";
import PaymentDetailsCard from "../../Component/PaymentDetailsCard";
import SupplierCard from "../../Component/SupplierCard";
import LendersCard from "../../Component/LendersCard";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import "./proposal.css";
import dp from "../../Images/dp.png";
import HttpClient from "../../utils/HttpClient";
import AuthServices from "../../services/AuthServices";
import ProductServices from "../../services/ProductServices";
import moment from "moment";
import { useParams } from "react-router-dom";
import const1 from "../../Images/cons1.png"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css';
import Recentlybiddedproject from "../../Component/Recentlybiddedproject";
import { useSelector } from "react-redux";
import ChattModel from "../../Component/Modal/ChattModel";
import { useDispatch } from "react-redux";
import { getAllProduct, getProduct } from "../../Redux/Reducer/ProductSlice";
const Proposal = () => {
    const dispatch = useDispatch()
    const [onclickshw, setOnclickshw] = useState(false);

    const [projectData, setProjectData] = useState([]);
    const { productData, loading, AllProduct } = useSelector(state => state.productReducer)
    console.log("jkrhgf", projectData,)
    const localdata = localStorage.getItem("user")
    const userData = JSON.parse(localdata)
    const [chattFlag, setchatFlag] = useState(false)
    const [taskid, settaskid] = useState('');
    const [senderId, setsenderId] = useState('');
    const [chatProjectname, setchatProjectname] = useState("")
    const [projectId, setprojectId] = useState("")
    const [projectsenderId, setprojectsenderId] = useState("")





    const allProducts = AllProduct && userData
        ? AllProduct.filter((ele) => ele.userData?._id === userData?._id)
        : [];



    const handleClickShow = () => {
        setOnclickshw(!onclickshw);
        // setDefltshw(!defltshw)
    };


    const navigate = useNavigate();




    const fetAllMybiddedProject = async () => {

        let res = await ProductServices.getMybiddedProject();
        console.log("projectrdataeee", res?.data);

        if (res && res.status) {
            // alert
            setProjectData(res?.data.reverse());
        }


    };
    console.log("AllProductfff", allProducts,)
    useEffect(() => {
        window.scrollTo(0, 0)
        fetAllMybiddedProject()
        // dispatch(getProduct());
        dispatch(getAllProduct());
    }, []);



    return (
        <>
            <div className="main_wrap">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="middlepartwrap">
                                <div className="job_dtls">
                                    <div className="tabsjobs w-100">
                                        {/* <Link to="/" className="posted_icon">
                                            <i className="fa-solid fa-arrow-left"></i>
                                            My posted jobs
                                        </Link> */}
                                        {/* <div><h2>Service posted</h2></div>
                                        <div><h2>Service bided</h2></div> */}
                                        <Tabs>
                                            <TabList>
                                                <Tab>Service posted</Tab>
                                                {userData?.jobRole[0] != "64dca6a1a63f41ccadf38c3c" &&
                                                    <Tab >Service Bidded</Tab>}
                                            </TabList>

                                            <TabPanel>

                                                <Recentlybiddedproject
                                                    productData={allProducts}
                                                    userData={userData}
                                                    setprojectId={setprojectId}
                                                    setchatFlag={setchatFlag}

                                                    setsenderId={setsenderId}
                                                    setchatProjectname={setchatProjectname}

                                                    setprojectsenderId={setprojectsenderId}

                                                    settaskid={settaskid}



                                                //   getroledata={() => getroledata()}
                                                // isFinance={isFinance}
                                                />
                                                {/* {allProducts?.map((item, index) => {
                                                    console.log("id12", item);
                                                    return (
                                                        <div className="job_image_description_main" key={index}>
                                                            <div className="nameDetails">
                                                                {item?.name.slice(0, 200)}
                                                            </div>
                                                            <div className="job_image">
                                                                {item?.image?.[0] ? <img
                                                                    src={item?.image?.[0]}
                                                                    alt=""
                                                                /> : <img
                                                                    src={const1}
                                                                    alt=""
                                                                />}

                                                            </div>
                                                            <div className="prjct_dscrptn">
                                                                <div className="pname">
                                                                    <h5>{item?.name}</h5>
                                                                    <div className="projct_hrs_attach_main">
                                                                        <button class="Attachment_btn btn">
                                                                            <i className="fa-regular fa-clock mr-2"></i>
                                                                            {moment(item?.createdAt)
                                                                                .fromNow()}
                                                                        </button>
                                                                        <button class="Attachment_btn btn">
                                                                            <i className="fa-solid fa-paperclip mr-2"></i>
                                                                            Attachment {item?.image?.length}
                                                                        </button>
                                                                    </div>
                                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                                        <div>
                                                                            <h4> Project Description: </h4>
                                                                            <p>{item?.description}</p>
                                                                        </div>
                                                                        <div className="action_fun">
                                                                            <button
                                                                                className="Viewprofile_btn"
                                                                                style={{ border: "0px" }}
                                                                                onClick={() => navigate("/single-projectdetails/" +
                                                                                    item?._id)}

                                                                            >
                                                                                View Project
                                                                            </button>

                                                                        </div>

                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                    );
                                                })} */}


                                            </TabPanel>
                                            <TabPanel>
                                                {projectData?.map((item, index) => {
                                                    console.log("id12 --->", item);
                                                    return (
                                                        <div className="job_image_description_main" key={index} style={{ padding: '20px' }}>

                                                            <div className="d-flex ">

                                                                <img
                                                                    src={
                                                                        item?.projectDetail?.projectUserId?.image ??
                                                                        "http://localhost:3000/static/media/dp.51d1952afe4317cb1412.png"
                                                                    }
                                                                    style={{
                                                                        height: "50px",
                                                                        width: "50px",
                                                                        borderRadius: "50px",
                                                                        border: "1px solid",
                                                                    }}
                                                                    alt=""
                                                                />
                                                                <div>
                                                                    <h6 style={{ margin: "5px 0px 0px 5px" }}>
                                                                        {item?.projectDetail?.projectUserId?.username}
                                                                    </h6>
                                                                    <h6 style={{ fontSize: "10px", marginLeft: "8px" }}>
                                                                        <i className="fa-regular fa-clock mr-2"></i>
                                                                        {moment(item?.createdAt).fromNow()}
                                                                    </h6>
                                                                </div>
                                                            </div>

                                                            <div className="nameDetails">
                                                                {item?.projectDetail.name.slice(0, 200)}
                                                            </div>
                                                            <div className="">
                                                                {item?.projectDetail.image?.[0] ? <img
                                                                    src={item?.projectDetail.image?.[0]}
                                                                    alt=""
                                                                    style={{
                                                                        height: '250px',
                                                                        width: '100%',
                                                                        borderRadius: '5px',
                                                                        marginTop: '10px'
                                                                    }}
                                                                /> : <img
                                                                    src={const1}
                                                                    alt=""
                                                                    style={{
                                                                        height: '250px',
                                                                        width: '100%',
                                                                        borderRadius: '5px',
                                                                        marginTop: '10px'
                                                                    }}
                                                                />}

                                                            </div>


                                                            <div className="prjct_dscrptn">
                                                                <div className="pname">
                                                                    <h5>{item?.name}</h5>
                                                                    <div className="projct_hrs_attach_main">
                                                                        <button class="Attachment_btn btn">
                                                                            <i className="fa-regular fa-clock mr-2"></i>
                                                                            {moment(item?.createdAt)
                                                                                .fromNow()}
                                                                        </button>
                                                                        <button class="Attachment_btn btn">
                                                                            <i className="fa-solid fa-paperclip mr-2"></i>
                                                                            Attachment {item?.image?.length}
                                                                        </button>
                                                                        <button className="Attachment_btn btn" onClick={() => {
                                                                            //CHATT FOR CONTACTUR
                                                                            setprojectId(item.projectDetail._id)
                                                                            setsenderId(item.projectOwnerId)
                                                                            setchatProjectname(item.projectDetail.name)
                                                                            setchatFlag(true)
                                                                            setprojectsenderId(item.projectOwnerId)
                                                                            // setprojectId(item.)
                                                                            // if(item?._id==item.bidderId)
                                                                            settaskid(item?._id)
                                                                            // const temparr = AllProduct.filter((ele) => ele._id == item.projectId)
                                                                            console.log("temparr11", item)
                                                                        }}>
                                                                            <i class="fas fa-comments"></i>
                                                                            Chatt
                                                                        </button>
                                                                    </div>
                                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                                        <div>
                                                                            <h4> Project Description: </h4>
                                                                            <p>{item?.projectDetail?.description}</p>
                                                                        </div>
                                                                        <div className="action_fun">
                                                                            <button
                                                                                className="Viewprofile_btn"
                                                                                style={{ border: "0px" }}
                                                                                onClick={() => navigate("/single-projectdetails/" + item?.projectDetail._id)}

                                                                            >
                                                                                View Project
                                                                            </button>

                                                                        </div>

                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                            </TabPanel>
                                        </Tabs>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-12 pr-0">
                            <div className="rightsidebar">


                                <LendersCard />
                            </div>
                        </div>
                    </div>
                </div>

                {chattFlag &&
                    <ChattModel
                        task_id={taskid}
                        Sender_Id={senderId}
                        chatProjectname={chatProjectname}
                        projectId={projectId}
                        projectownerId={projectsenderId}

                        setchatFlag={setchatFlag} />}

            </div>
        </>
    );
}
export default Proposal