import React, { useEffect, useState } from "react";
import PaymentDetailsCard from "../../Component/PaymentDetailsCard";
import SupplierCard from "../../Component/SupplierCard";
import LendersCard from "../../Component/LendersCard";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import "./privacy.css";

function privacy() {
  return (
    <div className="main_wrap">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-12">
            <h2
              style={{ marginLeft: "8rem", marginBottom: "2rem" }}
              className="mt-3"
            >
              Privacy Notice
            </h2>
            <div className="middlepartwrap">
              <div>
                <h4 className="mb-3">Introduction</h4>
                <p>
                  This privacy notice provides you with details of how we
                  collect and process your personal data through your use of our
                  platformBuildBeta, also accessible via www.buildbeta.co.uk.
                  NEXTSPACE CONSTRUCTION UK LIMITED ('Nextspace Construction')
                  has created this Privacy Policy to inform you how it manages,
                  collects, processes, and uses your Personal Information (as
                  defined below). This Privacy Policy further applies to the use
                  of Our subscription service, including use of Our associated
                  mobile applications. Read this Privacy Policy carefully. For
                  the purposes of this Privacy Policy, “We”, “Us” and “Our”
                  means Nextspace Construction
                </p>
              </div>

              <div className="mt-3">
                <h4 className="mb-3">About</h4>
                <p>
                  Nextspace Construction provide service through a cloud-based
                  solution product called BuildBeta to Homeowners, contractors,
                  and suppliers platform to connect for approved Subscribers
                  through a cloud-based Application accessible through an
                  internet browser or smart phone. For the purpose of this
                  Privacy Policy Nextspace Construction is the data controller
                  and its address is Boundary House, Cricket Field Road,
                  Uxbridge, United Kingdom, UB8 1QG. You can contact Us by
                  writing to us at this address or emailing us at
                  contact@BuildBeta.co.uk
                  <br />
                  We collect information from the Subscribers through our
                  website, Device, Accessories and Application.
                  <br />
                  If any of your personal information changes (eg when you have
                  changed your email address, moved house or maybe changed your
                  name because you have got married), please email us at the
                  above email address and let us know how it has changed. From
                  time to time we may email you to check that the personal data
                  we hold for you is accurate and up to date.
                  <br />
                  We reserve the right to review and revise this Privacy Policy.
                  Any changes made to the Privacy Policy will appear here.  If
                  We make material changes to this Privacy Policy, we will
                  notify you before they take effect either through the Site or
                  by sending you a notification through Our Application. By
                  making use of Our Services, you acknowledge you have read and
                  understood this Privacy Policy.
                </p>
              </div>

              <div>
                <h4 className="mb-3">Collection of Personal Information</h4>
                <h5>Definition of Personal Information</h5>
                <p>
                  This refers to any information about an individual from which
                  that person can be identified, whether directly or indirectly,
                  including information, such as the individual's name, e-mail,
                  address, phone number, and other information about the
                  individual (‘Personal Information’).
                </p>
              </div>

              <div>
                <h4 className="mb-3">
                  What Personal Information Do We collect about you?
                </h4>
                <p>
                  <strong>Onboarding Details:</strong> You will be asked to
                  provide Us with certain details about you such as your first
                  name, last name, email address, gender, date of birth,
                  physical address, business address. We shall verify and check
                  the validity of such consent before processing your personal
                  data.
                </p>
                <p>
                  <strong>Payment Details:</strong> We will ask you to provide
                  Us with your credit/debit card and bank account details to
                  process your payment for the Services.
                </p>
                <p>
                  <strong>Application:</strong> The information we collect
                  through Application is Personal Information about the
                  Subscriber that you have entered into the SaaS platform system
                  and provided Us as part of the setup of User/Subscriber
                  profile in the Application. This may be Full Name, Home
                  Address, Gender, DoB/Age, Profile Image, Email Address, Phone
                  Number, We also request your mobile phone number to allow us
                  to authenticate your registration, through a One Time Password
                  (OTP) process. This is necessary for accessing the BuildBeta
                  app and safeguards our platform from unsolicited access.
                </p>
                <p>
                  <strong>Internet and Network Activity Data:</strong> Such as
                  information required to provide access to our IT systems and
                  networks, including IP addresses, log files, and login
                  information, the type of web browser you use; device type;
                  your operating system; your web request; your Internet Service
                  Provider and cookies; referring/exit pages and URLs; payload
                  errors, the pages you view and how you interact with links;
                  the time and duration of your visits to the sites or use of
                  the platform.
                </p>
              </div>

              <div>
                <h4 className="mb-3">
                  Information We collect on you from other sources
                </h4>
                <p>
                  We may collect Personal Information about you from other
                  sources such as social networks, credit agencies or other
                  third-party source which is relevant to the provision of Our
                  Services.
                </p>
              </div>

              <div>
                <h4 className="mb-3">
                  What if you fail to provide Personal Information?
                </h4>
                <p>
                  Where We need to collect Personal Information by law, or under
                  the terms of a contract We have with you, and you fail to
                  provide that Personal Information when requested, we may not
                  be able to perform the contract (for example, to provide you
                  with the Services). In this case, we may have to cancel a
                  Service you have with Us, but We will notify you if this is
                  the case at the time.
                </p>
              </div>

              <div>
                <h4 className="mb-3">
                How do We use the Personal Information?
                </h4>
                <p>
                We will only use your Personal Information when the law allows Us to and have your consent and we use the information with a good sense of purpose to provide you with the information and reassurance provided by the Service. Most commonly, we will use your Personal Information in the following circumstances:
                </p>
                <ul className="pl-5">
                  <li>	Where We need to perform the contract, we are about to enter into or have entered into with you.</li>
                  <li>	Where it is necessary for Our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
                  <li>	Where We need to comply with a legal obligation.</li>
                  <li>	Where We receive your consent to process your Personal Information, such as to send you marketing communications. You have the right to withdraw your consent at any time by contacting Us at contact@BuildBeta.co.uk but please note that withdrawal of your consent will not affect the lawfulness of any processing of Personal Information based on your consent before its withdrawal.</li>
                </ul>
              </div>

              <div>
                <h4 className="mb-3">
                Purposes for which We will use your Personal Information
                </h4>
                <p>
                We use the Personal Information for the following purposes:
                </p>
                <ul className="pl-5">
                  <li>	1. To configure the Services which We provide to the Subscriber</li>
                  <li>	2. To communicate with the approved Subscribers and keep you informed of any changes which may have an impact on the Services which We provide to you</li>
                  <li>	3. To customise Our Services to the needs of the Subscriber</li>
                  <li>	4. To enable Us to develop and improve Our Services through data collected through analytics</li>
                  <li>	5. To communicate with the Subscriber and respond to your queries</li>
                  <li>	6. To provide the Subscriber with promotional communication relating to the Services if We have your consent to send you marketing communications</li>
                  <li>	7. Process financial transactions to enable you to purchase our goods our products or services</li>
                  <li>	8. To comply with applicable laws and regulation</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-12 pr-0">
            <div className="rightsidebar">
              <LendersCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default privacy;
