
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cons1 from '../../Images/cons1.png';
import cons2 from '../../Images/cons2.png';
import cons3 from '../../Images/cons3.png';
import cons4 from '../../Images/cons4.png';
import plus from "../../Images/plus.png"
import AOS from "aos";
import "aos/dist/aos.css";
import CommentModal from '../Modal/CommentModal';
import HttpClient from '../../utils/HttpClient';
import moment from 'moment';
import ShowSubtaskCard from '../ShowSubtaskCard';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import ProductServices from '../../services/ProductServices';
import CustomLoader from '../loader/CustomLoader';
import { PiXCircleDuotone } from 'react-icons/pi';
import { getSubTask } from '../../Redux/Reducer/SubTaskSlice';
import { getTask } from '../../Redux/Reducer/TaskSlice';
import QueryPayment from './QueryPayment';
import PdfviewModel from './PdfviewModel';
import ImageDownloader from '../../View/ImageDownloader';
import { getAllNotificatios } from '../../Redux/Reducer/NotificationSlice';
import pdflogo from "../../Images/pdf.png"
const docAndpdflogo = require("../../Images/pdf.png")
var invurl = null

function ProjectUpdateModal(props) {
    const { closeModal,
        singleTaskData,
        projectId,
        singleProjectData,
        openSubTaskModal,
        setModalE,
        setSubTaskId,
        deletetask,
        deleteSubTast,
        approvedForChange,
        ContuctorApprovePrice,
        setTaskId,
        setPaymentSucsessModelflag
    } = props;
    console.log("kjhhklkl", singleTaskData)
    const { subTaskData, loading } = useSelector(state => state.subTtaskReducer)
    const { userData } = useSelector((state) => state.User);

    const localdata = localStorage.getItem("user")
    const userdata = JSON.parse(localdata)
    const [invlink, setinvLink] = useState("")
    const [startDate, setStartDate] = useState(new Date());
    const [modalcoment, setmodalcoment] = useState(false);
    const [openmodal, setopenmodal] = useState(false);
    const [dropdowna, setDropdowna] = useState(false);
    const [imgerrflag, setimgerrflag] = useState(false);
    const [invoicelink, setinvoicelinks] = useState("");
    const [pdfmodel, setpdfmodel] = useState(false);
    const [senderId, setSenderId] = useState("")
    const [isHebidder, setHeisbidder] = useState(false)
    const [bidderimage, setBidderimage] = useState("")
    const [dontUpdateFlag, setdontUpdateFlag] = useState(false)
    const [workStatus, setWorkStatus] = useState("")
    const [isleftDatePickerOpen, setleftDatePickerOpen] = useState(false);
    const [isrightDatePickerOpen, setrightDatePickerOpen] = useState(false);

    const [amount, setAmount] = useState("")
    const [wallet, setWallet] = useState(false);
    // const [offlinepayflag, setofflinepayflag] = useState( ? true : false)

    console.log("khgjcfdfdf", projectId,)
    console.log("singleTaskDeeerrata", singleTaskData,)


    const dispatch = useDispatch();

    function getFileExtensionFromMimeType(mimeType) {

        const parts = mimeType.split('.').pop().toLowerCase();
        console.log("kkjjr", parts)
        const nextparts = mimeType.split("/");
        if (nextparts.length === 2) {
            if (nextparts[1] == "pdf")
                return nextparts[1];
        }
        return parts;

    }

    // //fetch invoice
    // const fetchingInvoice = async () => {
    //     ProductServices.ViewTSKiNV(
    //         singleTaskData?._id
    //         // route.params.TaskId,
    //         //   route.params.activityDetailsId,
    //     )
    //         .then(res => {
    //             console.log('res ----:":""', res);
    //             if (res && res.status) {
    //                 // invurl = res.data[0].invoiceUrl
    //                 setinvLink(res.data[0].invoiceUrl)
    //                 // getDownload(res.data[0].invoiceUrl);
    //                 // // setUrl(res.data[0].invoiceUrl);
    //                 // console.log('res.data==============',res.data[0]);
    //             }
    //         })
    //         .catch(err => {
    //             console.log('err124', err);
    //         });
    // }
    // form
    const [formLoading, setFormLoading] = useState(false);

    const initValue = {
        taskName: "",
        description: "",
        startDate: "",
        endDate: "",
        taskBudget: "",
        cPercentage: "",
        image: [],
        PaymentFequencey: ""
    }
    const [formValue, setFormValue] = useState(initValue);
    console.log("jkhhk", moment(formValue.endDate).toString(), new Date())


    console.log("formValue", formValue)

    const payFreqData = ["Percentage", "Weekly", "Quarterly", "Monthly"]



    const commentModal = () => {
        setmodalcoment(!modalcoment);
    };

    const handleClick = () => {
        openSubTaskModal()
    }

    const [value, setValue] = useState('Percentage');

    const dropdownModal = () => {
        setDropdowna(!dropdowna);
    }

    // handleChange
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue(prev => { return { ...prev, [name]: value } });
    }

    //fileValidater
    function getFileExtensionFromMimeType(mimeType) {

        const parts = mimeType.split('.').pop().toLowerCase();
        const nextparts = mimeType.split("/");
        console.log("kkjjr", parts, nextparts)
        if (nextparts.length === 2) {
            if (nextparts[1] == "pdf" ||
                nextparts[1] == "jpg" || nextparts[1] == "png" || nextparts[1] == "mp4" || nextparts[1] == "jpeg"
            )
                return nextparts[1];
        }


        return parts;

    }

    // image upload
    const handleUploadImage = async (e) => {

        let file = e.target.files;
        // console.log("imgghj", file)
        let imgArr = []
        for (let img of file) {
            console.log("imgghj", getFileExtensionFromMimeType(img.type))
            if (

                getFileExtensionFromMimeType(img.type) === "jpg" ||
                getFileExtensionFromMimeType(img.type) === "jpeg" ||
                getFileExtensionFromMimeType(img.type) === "png" ||
                getFileExtensionFromMimeType(img.type) === "pdf"
                ||
                getFileExtensionFromMimeType(img.type) === "mp4"

            ) {


                let data = new FormData();
                data.append("img", img);
                let res = await HttpClient.fileUplode("task-image", "POST", data);
                console.log("resImage", res)
                if (res && res.status) {

                    let temparr = [...formValue.image]
                    temparr.push(res)
                    setFormValue((prev) => { return { ...prev, image: temparr } })
                } else {
                }
                setimgerrflag(false)
            } else {
                setimgerrflag(true)
            }
        }
    }


    //delete single image
    const deleteSingleImage = (index) => {
        let newArr = formValue.image.filter((item, i) => i !== index)
        setFormValue((prev) => { return { ...prev, image: newArr } });
    }

    // valiidate
    const validate = () => {
        if (!formValue.taskName) {
            toast.error("Task Name is Required");
            return true
        }
        if (!formValue.description) {
            toast.error("Description is Required");
            return true
        }
        if (!formValue.startDate) {
            toast.error("Timeline is Required");
            return true
        }
        if (!formValue.endDate) {
            toast.error("Timeline is Required");
            return true
        }
        // if (formValue.image.length === 0) {
        //     toast.error("Image is Required");
        //     return true
        // }
        if (!formValue.taskBudget) {
            toast.error("Task Budget is Required");
            return true
        }
        // if (!formValue.PaymentFequencey) {
        //     toast.error("Payment Frequency is Required");
        //     return true
        // }

        return false
    }


    useEffect(() => {
        dispatch(getAllNotificatios())

        console.log(singleTaskData?.completedStatus, "iiee")
        if (singleTaskData?.completedStatus === "ToDo") {
            // alert("rr")
            setdontUpdateFlag(true)
            setWorkStatus(singleTaskData?.completedStatus)
            //  console.log(typeof (singleTaskData.completedStatus), "iiee")

        }

    }, [singleTaskData])

    // useEffect(() => {
    //     // if(workStatus==="")
    //     setdontUpdateFlag(true)
    //     return (() => {
    //         setWorkStatus("")
    //     })
    // }, [workStatus])

    const { AllNotifications } = useSelector(state => state.NotificationReducer)
    console.log(AllNotifications, "khgjhhjh")




    // update task
    const handleUpdateTask = async () => {
        if (validate()) {
            return;
        }

        const data = {
            projectId: projectId,
            taskName: formValue.taskName,
            description: formValue.description,
            startDate: formValue.startDate,
            endDate: formValue.endDate,
            taskBudget: formValue.taskBudget,
            percentage: formValue.cPercentage,
            image: formValue.image,
            PaymentFequencey: formValue.PaymentFequencey
        }

        setFormLoading(true);
        const res = await ProductServices.updateTask(data, singleTaskData._id);
        console.log('updated res', res)
        if (res && res?.status) {
            toast.success(res?.message);
            setFormValue(initValue);
            setFormLoading(false);
            dispatch(getTask(projectId));
            closeModal(false);
            // window.location.reload()
        }
        else {
            toast.error(res?.message);
            setFormLoading(false);
        }

    }


    //updating complete status
    function approvedStatusHomeOwner(type) {
        let data = {
            completedStatus: type,
            projectId: singleTaskData.projectId,
            projectName: singleTaskData.projectData.name
        };

        // setApprovedStatusLoser(true);
        ProductServices.requestStatusComplete(data, singleTaskData._id)
            .then(res => {
                // setApprovedStatusLoser(false);
                console.log('res s ss -->', JSON.stringify(res));
                if (res && res.status) {
                    console.log("lfhjklj", res)

                    // setinvurl(res?.data.invoiceLink)
                    // setinvoicelinks(res?.data?.invoiceLink)
                    // invurl = res?.data?.invoiceLink;
                    setinvLink(res?.data?.invoiceLink)
                    closeModal(false)
                    dispatch(getTask(projectId))
                    // NavigationService.back();
                }
            })
            .catch(err => {
                // setApprovedStatusLoser(false);
                console.log('err', err);
                console.log("lfhjklj", err)
                toast.error("'Please Complete All Subtask'")
            });
    }


    useEffect(() => {
        // fetchingInvoice()
        getBideerId()
        return (() => {
            setTaskId("")
        })
    }, [])


    //stripe Wallet Recharge Payment
    const PaymentProceed = async () => {
        if (amount > 0) {
            const data = {
                price: amount,
                name: "Wallet recharge ",
                customer: userData.stripe_customer_Id,
                //success_url: `https://login.buildbeta.co.uk/project-detail/${projectId}?paymentSuccess=true&taskId=${singleTaskData._id}`,

                // success_url: `http://localhost:3000/project-detail/${projectId}?paymentSuccess=true&amount=${amount}`,
                success_url: `https://login.buildbeta.co.uk/project-detail/${projectId}?paymentSuccess=true&amount=${amount}`,



                type: "payment",
            };
            const res = await ProductServices.Task_PaymentProceed(data);
            if (res && res.status) {
                window.open(res.data.url);
            }
        } else {
            toast.error("please enter some amount")
        }
    };



    //Task Payment
    const PayTaskValues = () => {
        const data = {
            taskId: singleTaskData?._id,
            deductionPercentage: 0,
            taskBudget: singleTaskData?.taskBudget,
            // taskName: singleTaskData?.taskName
            taskName: "Task Payment"

        }


        ProductServices.Task_Payment(data, senderId)
            .then(res => {

                if (res && res.status) {
                    closeModal(false)
                    payForTask_changeStatus("online")
                    console.log("kkkrr", res)
                    // NavigationService.back();
                } else {
                    console.log("kkkrr", res)
                    setWallet(true)
                    setopenmodal(false)
                    toast.error(res.msg);
                }
            })
            .catch(err => {
                // setApprovedStatusLoser(false);
                toast.error(err.msg);
                console.log('err', err);
                // toast.error('Please Complete All Subtask',);
            });
    }

    const offLineTaskPayment = () => {
        const data = {
            taskId: singleTaskData?._id,
            deductionPercentage: 0,
            taskBudget: singleTaskData?.taskBudget,
            // taskName: singleTaskData?.taskName
            taskName: "Task Payment"

        }


        ProductServices.offlinePament_api(data, senderId)
            .then(res => {

                if (res && res.status) {
                    closeModal(false)
                    payForTask_changeStatus("offline")
                    console.log("kkkrr", res)
                    // NavigationService.back();
                } else {
                    toast.error(res.msg);
                }
            })
            .catch(err => {
                // setApprovedStatusLoser(false);
                toast.error(err.msg);
                console.log('err', err);
                // toast.error('Please Complete All Subtask',);
            });
    }



    //find bidder
    const getBideerId = () => {
        ProductServices.getbidder(projectId)
            .then(res => {
                console.log("bider Data", res);
                if (res && res.status) {
                    if (res.data.projectOwnerId == userdata._id) {
                        setHeisbidder(true)
                        setSenderId(res.data.bidderId)
                        setBidderimage(res.data.bidderDetail.image)
                    } else {
                        setSenderId(res.data.projectOwnerId)
                    }
                }

            })
            .catch(err => {
                console.log("err", err);
            })
    }



    //Taskpay and change Status


    function payForTask_changeStatus(str) {

        let method = ''
        if (str === 'online') {
            method = 'online'
        } else {
            method = 'offline'
        }

        let data = {
            completedStatus: 'Complete',
            projectId: singleTaskData.projectId,
            projectName: singleProjectData.name,
            paymentMethod: method
            //          "completedStatus": "Complete",
            // //     "projectId": "65a7d7afd67a990205075da4",
            // //     "projectName": "Soundproofing Air Conditioning Installation-00068",
            // //     "paymentMethod": "offline"
        };

        console.log('data', data, projectId);
        // return
        // setApprovedStatusLoser(true);
        ProductServices.requestStatusCahange(data, singleTaskData._id)
            .then(res => {

                if (res && res.status) {

                    setmodalcoment(false)
                    if (str === "offline") {
                        // alert("offline")
                    } else {

                        setPaymentSucsessModelflag(true)
                    }
                    closeModal(false)
                    dispatch(getTask(projectId))

                }
            })
            .catch(err => {
                // setApprovedStatusLoser(false);
                console.log('err', err);
                toast.show('Please Complete All Subtask',);
            });
    }



    useEffect(() => {
        AOS.init();
        AOS.refresh();
        // alert("new")
    }, []);

    useEffect(() => {
        if (singleTaskData) {
            setFormValue({
                taskName: singleTaskData.taskName,
                description: singleTaskData.description,
                startDate: singleTaskData.startDate,
                endDate: singleTaskData.endDate,
                taskBudget: singleTaskData.taskBudget,
                cPercentage: singleTaskData.percentage,
                image: singleTaskData.image,
                PaymentFequencey: singleTaskData.PaymentFequencey
            })

            dispatch(getSubTask(singleTaskData._id));
        }

    }, [singleTaskData])

    const deletemysubtask = (taskId) => {
        ProductServices.deleteSubTask(taskId)
            .then((res) => {
                if (res && res.status) {
                    toast.success('Deleted Successfully')
                    dispatch(getSubTask(projectId))
                } else {
                    toast.error('Something Wrong')
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const deletemytask = (taskId) => {
        ProductServices.deleteTask(taskId)
            .then((res) => {
                if (res && res.status) {
                    toast.success('Deleted Successfully')
                    closeModal(false)
                    dispatch(getTask(projectId))
                } else {
                    toast.error('Something Wrong')
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }
    return (
        <>

            <CustomLoader loading={formLoading} />

            <section className='createtaskmodal'>
                <div className='create_modal' data-aos="fade-left" data-aos-easing="linear"
                    data-aos-duration="250">
                    <div className='create_overlay' onClick={() => {
                        closeModal(false)
                        setTaskId("")
                    }} >

                    </div>

                    <div className='createmodal_content' onClick={(e) => {
                        e.stopPropagation()
                    }} >
                        <div className='modal_project_content'>
                            <p className='notification_txt'>{singleProjectData?.name}</p>

                            <div className='closemark' onClick={() => closeModal(false)}>
                                <i class="fa-solid fa-xmark"></i>
                            </div>

                            <div className='project_form projectppp'>

                                {/* Task Name */}
                                <div className='project_tsk'>
                                    <label for="" className='tsk_label'>Task</label>
                                    <input
                                        disabled={!dontUpdateFlag}
                                        className="project_input"
                                        type="text" placeholder=''
                                        id=""
                                        name="taskName"
                                        value={formValue.taskName}
                                        onChange={(e) => handleChange(e)}

                                    />
                                </div>

                                {/* description */}
                                <div className='project_desc projectppp'>
                                    <label for="" className='tsk_label'>Description</label>
                                    <textarea
                                        disabled={!dontUpdateFlag}
                                        className="project_input"
                                        rows="4" cols="50"
                                        name="description"
                                        value={formValue.description}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>

                                <div className='timeline projectppp'>

                                    {/* start Date */}
                                    <label for="" className='tsk_label'>Timeline </label>
                                    <div className='timeline_flx'>

                                        <div className='calender_datepicker_icon'>
                                            <div className='calender_datepicker' onClick={() => setleftDatePickerOpen(!isleftDatePickerOpen)}>
                                                <DatePicker

                                                    open={isleftDatePickerOpen}
                                                    disabled={!dontUpdateFlag}
                                                    className='datepickerown'
                                                    minDate={new Date()}

                                                    value={formValue.startDate ?
                                                        moment(formValue.startDate).format('DD-MM-YYYY')
                                                        : "DD-MM-YYYY"}
                                                    onChange={(date) => {
                                                        let startDate = moment(date).format();
                                                        if (formValue.endDate) {
                                                            if (startDate < formValue.endDate) {
                                                                setFormValue(prev => { return { ...prev, startDate: startDate } })
                                                            } else {
                                                                toast.error("Start Date Should be Less Than End Date")
                                                            }
                                                        } else {
                                                            setFormValue(prev => { return { ...prev, startDate: startDate } })
                                                        }
                                                    }
                                                    }
                                                />                                                <div className='calender_own' style={{ cursor: "pointer" }}>
                                                    <i class="fa-regular fa-calendar-days"></i>
                                                </div>
                                            </div>
                                        </div>

                                        {/* End Date */}
                                        <div className='calender_datepicker_icon'>
                                            <div className='calender_datepicker' onClick={() => { setrightDatePickerOpen(!isrightDatePickerOpen) }}>
                                                <DatePicker
                                                    open={isrightDatePickerOpen}
                                                    disabled={!dontUpdateFlag}
                                                    selected={formValue.startDate ? moment(formValue.startDate).toDate() : undefined}

                                                    // minDate={new Date()}
                                                    minDate={formValue.startDate ? moment(formValue.startDate).toDate() : undefined}

                                                    // minDate={moment(formValue.startDate).toString()}
                                                    value={formValue.endDate ? moment(formValue.endDate).
                                                        format('DD-MM-YYYY') : "DD-MM-YYYY"}
                                                    onSelect={(date) => {
                                                        let endDate = moment(date).format();
                                                        if (formValue.startDate) {
                                                            if (endDate >= formValue.startDate) {
                                                                setFormValue(prev => { return { ...prev, endDate: endDate } })
                                                            } else {
                                                                toast.error("End Date Should be Greater Than Start Date")
                                                            }
                                                        } else {
                                                            setFormValue(prev => { return { ...prev, endDate: endDate } })
                                                        }
                                                    }
                                                    }
                                                    className='datepickerown'
                                                />
                                                <div className='calender_own2' style={{ cursor: "pointer" }}>
                                                    <i class="fa-regular fa-calendar-days"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* image section */}
                                <div className='inpt_image projectppp'>
                                    <p>Attach File</p>
                                    <span style={{ display: "block", color: imgerrflag ? "red" : "green" }}>
                                        (Only "jpg","png","pdf","mp4" format accepted)</span>
                                    <label for="input-file" className='inpt_img_lbl'>
                                        <input
                                            disabled={!dontUpdateFlag}
                                            type="file"
                                            id=""
                                            name=""
                                            // accept="image/*"
                                            className="inpt_file_own"
                                            multiple
                                            onChange={(e) => handleUploadImage(e)}
                                        />
                                        <div className='drg_photo'>
                                            <p className="own_para">
                                                Drag, and photo, or <span>Browse</span>
                                            </p>
                                            <p className="own_para_sml">Support all file, max size 60 Mb</p>
                                        </div>
                                    </label>
                                </div>


                                <div className='all_cons_img' style={{ display: "flex", gap: "2rem" }}>
                                    {
                                        formValue.image.length !== 0 && formValue.image.map((item, i) => {
                                            console.log(item, "ttrre")
                                            if (item.extName == 'mp4') {
                                                return (
                                                    <div className='all_cons_imgmain d-flex' style={{ position: "relative", zIndex: 6 }}>
                                                        <video width="100" height="60" controls>
                                                            <source src={item.data} />
                                                        </video>
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            <i
                                                                className="fa-regular fa-circle-xmark ml-1 img-del-cross"
                                                                onClick={() => deleteSingleImage(i)}
                                                            ></i>
                                                            <ImageDownloader imageUrl={item?.data} extName={item.extName} />
                                                        </div>
                                                    </div>)

                                            } else {
                                                return (



                                                    <div className='all_cons_imgmain d-flex '  >

                                                        {
                                                            item?.extName == "pdf" ?
                                                                <>
                                                                    <a href={item.data} style={{ display: "flex", gap: "5px" }}>
                                                                        <img src={pdflogo} accept="image/*" alt={pdflogo} />

                                                                        <i className="fa fa-download"
                                                                            aria-hidden="true"></i>
                                                                    </a> <i
                                                                        className="fa-regular fa-circle-xmark ml-1 img-del-cross"
                                                                        onClick={() => deleteSingleImage(i)}
                                                                    ></i></> :
                                                                <>
                                                                    <img src={item.data} accept="image/*" alt={pdflogo} />
                                                                    <i
                                                                        className="fa-regular fa-circle-xmark ml-1 img-del-cross"
                                                                        onClick={() => deleteSingleImage(i)}
                                                                    ></i>
                                                                    <ImageDownloader imageUrl={item.data} extName={item.extName} />
                                                                </>
                                                        }


                                                    </div>)
                                            }
                                        }
                                        )
                                    }
                                </div>

                                {/* Task Budget */}
                                <div className='tskbudget_maain'>
                                    <p>Task Budget</p>
                                    <div className='task_icon_btn'>
                                        <p>£</p>
                                        <div className='tsk_btn'>
                                            {/* <p>00</p> */}
                                            <div className='tsk_btn'>
                                                {/* <p>00</p> */}
                                                <input
                                                    disabled={!dontUpdateFlag}
                                                    type='number'
                                                    style={{ width: "100%" }}
                                                    name="taskBudget"
                                                    value={formValue.taskBudget}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Completation Percentage */}
                                <div className='tskbudget_maain'>

                                    <div className='task_icon_btn'>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                            <p>Completation Percentage </p>
                                            <small style={{ color: "black", fontWeight: "bold" }}>(Set the percent of work that will be completed if
                                                this task is completed,and be aware that all tasks within this project cannot exceed 100%)</small>
                                        </div>
                                        <div className='tsk_btn'>
                                            {/* <p>00</p> */}
                                            <input
                                                disabled={!dontUpdateFlag}
                                                type='number'
                                                style={{ width: "100%" }}
                                                name="cPercentage"
                                                value={formValue.cPercentage}
                                                onChange={(e) => {

                                                    handleChange(e)
                                                }}
                                            />
                                            <p>%</p>
                                        </div>
                                    </div>
                                </div>



                                {/* dropdown payment frequency*/}
                                {/* <div className='project_paymnt'>
                                    <div className='pymnt_frncy_icon_hdr_bdy'>
                                        <div className='pymnt_frncy_icon_hdr' onClick={dropdownModal}>
                                            <div className='pymnt_frncy'>
                                                <p>{formValue.PaymentFequencey ? 
                                                formValue.PaymentFequencey : "Payment Frequency"}</p>
                                            </div>
                                            <div className='pymntdrpdwn'>

                                                <span
                                                    className={dropdowna ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down "}
                                                />
                                            </div>
                                        </div>

                                        {dropdowna && <div className='pymnt_frncy_icon_bdy' >
                                            {payFreqData.map((item, i) =>
                                                <p
                                                    key={i}
                                                    onClick={() => {
                                                        setFormValue(prev => {
                                                            return { ...prev, PaymentFequencey: item }
                                                        })
                                                        setDropdowna(false);
                                                    }
                                                    }
                                                >
                                                    {item}
                                                </p>
                                            )
                                            }
                                        </div>}
                                    </div>
                                </div> */}
                                {/* .............for update task */}
                                <div className='updt_dlt' >
                                    <div className='update_btn' onClick={() => handleUpdateTask()}>
                                        <a href="#" className='update_btn_main'>
                                            Update Task
                                        </a>
                                    </div>
                                    <button className='update_btn_delete'
                                        type='button'
                                        onClick={() => deletemytask(singleTaskData?._id)}>
                                        Delete Task
                                    </button>
                                </div>

                                {/* Create Subtask button */}
                                <div className='sbstsk_main'>
                                    <div
                                        className='sbstsk_main_flx'
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleClick()}
                                    >
                                        <p>Create Subtask </p>
                                        <div className='sbstk_icon'>
                                            <img src={plus} alt="/" />
                                        </div>
                                    </div>


                                    {/* sub Task List */}
                                    {subTaskData.map((item, i) =>
                                        <ShowSubtaskCard
                                            item={item}
                                            index={i}
                                            setModalE={setModalE}
                                            setSubTaskId={setSubTaskId}
                                            deleteSubTast={(subId) => deletemysubtask(subId)}
                                        />
                                    )
                                    }


                                    {/* comments */}
                                    <div className='sbstsk_main_flx' style={{ cursor: "pointer" }}
                                        onClick={commentModal}>
                                        <p>Comments</p>
                                        <div className='sbstk_icon'>
                                            <p>0</p>
                                        </div>
                                    </div>
                                    <div className='updt_dlt'
                                        style={{ display: singleTaskData?.completedStatus === "Pending" && userdata._id != singleTaskData?.taskCreaterId ? "block" : "none" }}>
                                        <div className='update_btn' onClick={() => approvedForChange("ToDo")}>
                                            <a href="#" className='update_btn_main'>
                                                Agree this
                                                task
                                            </a>
                                        </div>

                                    </div>
                                    <div className='updt_dlt'
                                        style={{ display: singleTaskData?.completedStatus === "ToDo" && !isHebidder ? "block" : "none" }}>
                                        <div className='update_btn' onClick={() => approvedForChange("Request")}>
                                            <a href="#" className='update_btn_main'>
                                                Submit for
                                                Approval
                                            </a>
                                        </div>

                                    </div>


                                    <div style={{
                                        display: (singleTaskData?.completedStatus ===
                                            "Request" && isHebidder) ? "block" : "none"
                                    }}>
                                        <p className='notification_txt'>  Do you
                                            agree this
                                            task is
                                            completed?</p>
                                        <div className='updt_dlt' >



                                            <div className='update_btn'
                                                onClick={() => approvedStatusHomeOwner('Approved')}
                                            >
                                                <a href="#" className='update_btn_main'>
                                                    Approve
                                                </a>
                                            </div>
                                            <button className='update_btn_delete'
                                                type='button'
                                                onClick={() => approvedStatusHomeOwner('ToDo')}
                                            >
                                                Reject
                                            </button>
                                        </div>
                                    </div>
                                    {/* ..........Pay and invoice button */}
                                    <div style={{
                                        display: (singleTaskData?.completedStatus === "Approved")
                                            ? "block"
                                            : "none"
                                    }}>
                                        <div className='updt_dlt'  >
                                            <div className='update_btn'
                                                style={{
                                                    display: (singleTaskData?.completedStatus === "Approved" &&
                                                        isHebidder)
                                                        ? "block"
                                                        : "none"
                                                }}
                                                onClick={() => setopenmodal(true)}
                                            >
                                                <a href="#" className='update_btn_main'>
                                                    Pay
                                                </a>
                                            </div>
                                            <div className='update_btn'
                                                onClick={() => setpdfmodel(true)}

                                            >


                                                <a href="#" className='update_btn_main'>
                                                    View Invoice
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                    {/* .......view Invoice button */}
                                    <div style={{
                                        display: singleTaskData?.completedStatus
                                            === "Complete" ? "block" : "none"
                                    }}>
                                        <div className='updt_dlt' >

                                            <div className='update_btn'
                                                onClick={() => setpdfmodel(true)}

                                            >


                                                <a href="#" className='update_btn_main'>
                                                    View Invoice
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                    {/* ........Cash payment received or not button */}
                                    <div

                                        style={{
                                            marginTop: "20px",
                                            display:
                                                !isHebidder && singleTaskData?.offlinePayment && !singleTaskData?.paymentDone &&
                                                    singleTaskData?.completedStatus
                                                    === "Approved" ? "block" : "none"
                                        }}
                                    >
                                        <p className='notification_txt'>   Have you received the Cash payment??</p>

                                        <div className='updt_dlt' >
                                            <div className='update_btn'
                                                onClick={() => {
                                                    // toast.success("Task Payment Sucsess")
                                                    ContuctorApprovePrice()
                                                    payForTask_changeStatus()

                                                    // payForTask_changeStatus("offline")
                                                }}
                                            >
                                                <a href="#" className='update_btn_main'>
                                                    yes
                                                </a>
                                            </div>
                                            <button className='update_btn_delete'
                                                type='button'
                                                onClick={() => approvedStatusHomeOwner('Approved')}

                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                {wallet && (
                    <div className="wallet_bg">
                        <div className="wallet_mine">
                            <a
                                href="#"
                                onClick={() => {
                                    setWallet(false);
                                }}
                            >
                                <i class="fa-solid fa-xmark"></i>
                            </a>
                            <h5>Add To wallet</h5>
                            <form className="wrapForm">
                                <div className="userInput_payment">
                                    <p>£</p>
                                    <input
                                        value={amount}
                                        type="text"
                                        onChange={(e) => {
                                            setAmount(e.target.value);
                                        }}
                                        placeholder="Enter Amount"
                                    ></input>
                                </div>
                                <div>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            PaymentProceed();
                                        }}
                                    >
                                        Add
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

            </section >

            {modalcoment && <CommentModal
                bidderimage={bidderimage}
                singleTaskData={singleTaskData}
                closeModal={setmodalcoment}
                singleProjectData={singleProjectData} />
            }
            {
                openmodal && <QueryPayment
                    amount={amount}
                    wallet={wallet}
                    setWallet={setWallet}
                    setAmount={setAmount}
                    PaymentProceed={PaymentProceed}
                    singleTaskData={singleTaskData}
                    PayTaskValues={PayTaskValues}
                    setopenmodal={setopenmodal}
                    payForTask_changeStatus={offLineTaskPayment}
                    projectId={projectId}
                    offlinepayment={ContuctorApprovePrice}
                    monyreceverId={senderId}
                />
            }

            {pdfmodel && <PdfviewModel setpdfmodel={setpdfmodel} singleTaskData={singleTaskData} invurl={invlink} />}
        </>
    )
}

export default ProjectUpdateModal
