import { combineReducers } from 'redux';
import User from './User';
import { taskReducer } from "./TaskSlice";
import { subTtaskReducer } from "./SubTaskSlice";
import { productReducer } from "./ProductSlice";
import { ServiceReducer } from "./UpdateServiceSlice";
import { NotificationReducer } from './NotificationSlice';


export default combineReducers({
  User,
  taskReducer,
  productReducer,
  subTtaskReducer,
  ServiceReducer,
  NotificationReducer
});
