import React, { useState, useEffect } from 'react'
import rply_img from "../../Images/rply_img.png";
import cons1 from "../../Images/cons1.png";
import cons2 from "../../Images/cons2.png";
import Union from '../../Images/Union.png';
import Group from "../../Images/Group.png";
import ReportBoxModal from "../Modal/ReportBoxModal";
import PartialPaymentRequest from "../Modal/PartialPaymentRequest";
import ProductServices from '../../services/ProductServices';
import { socket } from '../../Socket/Socket';
import { useSelector } from 'react-redux';
import HttpClient from '../../utils/HttpClient';
import toast from 'react-hot-toast';
import AuthServices from '../../services/AuthServices';


export default function CommentModal(props) {
    const moment = require('moment');
    const { closeModal, bidderimage } = props;
    const { singleTaskData, singleProjectData } = props

    const [reportmodal, setReportmodal] = useState(false);
    const [senderId, setSenderId] = useState("")
    const [msgimage, setMsgimg] = useState([])
    const taskId = singleTaskData._id
    console.log("taskrrId", singleProjectData)
    const [PageCount, setPageCount] = useState(0);
    const [allMsg, setAllMsg] = useState([]);
    const [LoserStatus, setLoserStatus] = useState(true);
    const [newmessage, setnewMessage] = useState('');
    const userData = useSelector((state) => state.User.userData);
    const [ImageUrl, setImageUrl] = useState(null)

    const [projectsenderimage, setprojectsenderimage] = useState("")

    console.log("ImageUrle", senderId, userData._id, singleTaskData.userId)


    const randomUrl = "https://www.rotarybangkok.org/wp-content/uploads/2017/11/user-placeholder.d2a3ff8.png"

    const specialModal = () => {
        setReportmodal(true)
    }
    const seeProfile = async () => {
        const res = await AuthServices.getProfileById(singleTaskData.userId)
        if (res && res.status) {
            setprojectsenderimage(res.data[0].image)
            console.log("kjfshkjg", res.data.image)
        }

    }

    function handleSendMessage() {
        // alert(message)
        // if (message == "" && ImageUrl == null) return
        let data = {
            "senderId": userData._id,
            "resiverId": senderId,
            "taskId": taskId,
            "message": newmessage,
            "image": ImageUrl ? ImageUrl : "null"
            // "image": msgimage ? msgimage : "null"


        }
        socket.emit('sendMessage', data);
        // setImageModalOpen(false)
        setnewMessage("")
        setImageUrl(null)
        setMsgimg([])
        setAllMsg(s => {
            return [{ ...data, _id: new Date().toTimeString(), createdAt: new Date() }, ...s]
        })

        setReportmodal(false)
    }


    function getAllData() {
        console.log("1st time", "1st time")
        setLoserStatus(true)
        ProductServices.getChat(taskId + "/" + PageCount)
            .then(res => {
                console.log("all chat", res);
                // setLoserStatus(false)
                if (res && res.status) {
                    setAllMsg(res.data)

                }
            })
            .catch(err => {
                // setLoserStatus(false)
                console.log("err", err);
            })
    }

    const handleImage = async (e) => {
        let file = e.target.files;
        let Arr = []
        for (let element of file) {
            let data = new FormData();
            data.append("img", element);
            let res = await HttpClient.fileUplode("bid-image", "POST", data);

            if (res && res?.status) {
                toast.success("Uploaded Successfully");
                console.log("images", res);
                Arr.push(res?.data)
                setMsgimg(Arr)
                setImageUrl(res?.data)
            } else {
                toast.error(res?.message);
            }
        }
    };

    function resivedMessage(data) {
        // console.log(data.resiverId == userData._id);
        console.log("dattta", data, "ghhh");
        if (data.resiverId == userData._id) {
            setAllMsg(s => {
                return [{ ...data, _id: new Date().toTimeString(), createdAt: new Date() }, ...s]
            })
        }

    }



    function submitinEnter(e) {
        // e.preventDefault()
        if (e.key === "Enter") {
            if (newmessage == "" && ImageUrl == null) return
            handleSendMessage()
            console.log("lgjhgjh", "hjkjklj")
        }
    }


    useEffect(() => {

        getAllData()
        socket.on('resivedMessage', resivedMessage);
        return () => socket.off('resivedMessage', resivedMessage);
    }, [])

    useEffect(() => {

        seeProfile()

        ProductServices.getbidder(singleTaskData.projectId)
            .then(res => {
                console.log("bider Data", res);
                if (res && res.status) {
                    setSenderId(res.data.projectOwnerId)
                    if (res.data.projectOwnerId == userData._id) {
                        setSenderId(res.data.bidderId)
                    } else {
                        setSenderId(res.data.projectOwnerId)
                    }
                }

            })
            .catch(err => {
                console.log("err", err);
            })
    }, [])


    //...................................................................
    const [reportbox, setReportbox] = useState(false);
    const reportboxModal = () => {
        setReportbox(!reportbox);
    };

    const [PartialPayment, setPartialPayment] = useState(false);
    const PartialPaymentModal = () => {
        setPartialPayment(!PartialPayment);
    };
    console.log("kkkuu8", allMsg?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)))
    return (
        <>
            <section className='commentmodal_main'>
                <div className='commentmodal'>
                    <div className='commentmodal_overlay'></div>
                    <div className='commentmodal_content'>
                        <div className='commentmodal_project_content'>
                            <p className='notification_txt'>Project Name:{singleProjectData?.name}</p>

                            <div className='closemark' onClick={() => closeModal(false)}>
                                <i class="fa-solid fa-xmark"></i>
                            </div>


                            {
                                allMsg?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                                    ?.map((ele, id) => {


                                        if (userData._id === ele?.senderId) {
                                            console.log(ele, "lkhjh")
                                            return (
                                                <div className='commnt_all_own'>
                                                    <div className='own_rply'>
                                                        <div className='img_name_time'>
                                                            <div className='roly_img_own'>
                                                                {userData.image ? <img src={userData.image}
                                                                    style={{ borderRadius: "60%", height: "26px", width: "25px", objectFit: "contain" }} alt="/" /> :
                                                                    <img src={rply_img} alt="/" />}

                                                            </div>
                                                            <div className='name_time'>
                                                                <p>{ele?.message}</p>
                                                            </div>
                                                            <p>{moment(ele?.createdAt).format("hh:mm A")}</p>
                                                        </div>

                                                        {ele.image === "null" ?
                                                            "" :
                                                            (
                                                                <div className='commnt_image'>
                                                                    <div className='commnt_img'>
                                                                        <img height={60} width={60} src={ele.image} alt="/" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            );

                                        } else {
                                            return (
                                                <div className='commnt_all_othrs'>
                                                    <div className='own_rply'>
                                                        <div className='img_name_time'>
                                                            <div className='roly_img_own'>
                                                                {
                                                                    bidderimage ?
                                                                        <img style={{ borderRadius: "60%", height: "26px", width: "25px", objectFit: "contain" }}
                                                                            height={25} width={25} src={bidderimage} alt="/" /> : (userData._id !== singleTaskData.userId) ?
                                                                            <img height={25} width={25} src={projectsenderimage} style={{ borderRadius: "60%", height: "26px", width: "25px", objectFit: "contain" }} alt="/" /> :
                                                                            <img height={25} width={25} src={randomUrl} alt="/" />
                                                                }

                                                            </div>
                                                            <div className='name_time'>
                                                                <p>{ele?.message}</p>
                                                            </div>
                                                            <p>{moment(ele?.createdAt).format("hh:mm A")}</p>
                                                        </div>

                                                        {ele.image === "null" ?
                                                            "" :
                                                            (
                                                                <div className='commnt_image'>
                                                                    <div className='commnt_img'>
                                                                        <img height={60} width={60} src={ele.image} alt="/" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            );

                                        }


                                    })
                            }







                            <div className='commnt_section'>
                                <div className='cmnt_dot' onClick={specialModal}>
                                    <i class="fa-solid fa-ellipsis-vertical"></i>

                                </div>
                                {reportmodal && <div className='rpt_pymnt_rqst_crd'>
                                    <div className='close_btnn' onClick={() => setReportmodal(false)}>
                                        <i class="fa-solid fa-xmark"></i>
                                    </div>
                                    <div className='mainrpt'>
                                        {/* <div className='rpt_flx' style={{ cursor: "pointer" }}
                                         onClick={reportboxModal}>
                                            <div className='rpt_imf'>
                                                <img src={Union} alt="/" />
                                            </div>
                                            <div className='rpt'>
                                                <p>Report</p>
                                            </div>
                                        </div> */}

                                        {/* <div className='rpt_flx' style={{ cursor: "pointer" }} 
                                        onClick={PartialPaymentModal}>
                                            <div className='rpt_imf'>
                                                <img src={Group} alt="/" />
                                            </div>
                                            <div className='rpt'>
                                                <p>Partial Payment Request</p>
                                            </div>
                                        </div> */}

                                        <input
                                            type="file"
                                            multiple
                                            className="form-control"
                                            id="wage"
                                            aria-describedby="textHelp"
                                            onKeyDown={(e) => submitinEnter(e)}
                                            placeholder="Attach file"
                                            onChange={(e) => handleImage(e)}

                                            style={{ margin: "10px" }}
                                        />


                                        {/* {msgimage ? msgimage?.map((it, ind) => {
                                            return (
                                                <img src={it} style={{
                                                    height: '80px',
                                                    width: '80px',
                                                    borderRadius: '5px'
                                                }}
                                                    key={ind}
                                                />
                                            )
                                        }) : ""
                                        } */}

                                        <img src={ImageUrl} style={{
                                            height: '80px',
                                            width: '80px',
                                            borderRadius: '5px'
                                        }}

                                        />


                                    </div>
                                </div>}

                                <div className='inpt_commnt'>


                                    <input type="text"
                                        id="wage"
                                        value={newmessage}
                                        onChange={(e) => {
                                            setnewMessage(e.target.value);

                                        }}
                                        onKeyDown={(e) => submitinEnter(e)}
                                        placeholder='Type your message here'
                                        className='input_cmnt_txt' />



                                </div>

                                <div className='cmnt_snd' style={{ cursor: "pointer" }}
                                    onClick={() => { handleSendMessage() }}>

                                    <i class="fa-regular fa-paper-plane"></i>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </section>

            {reportbox && <ReportBoxModal closeReportBox={setReportbox} />}

            {PartialPayment && <PartialPaymentRequest closePartial={setPartialPayment} />}
        </>
    )
}
