import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from '../Reducer';
import thunk from "redux-thunk";


const Store =  configureStore({
  reducer,
  middleware: [thunk],
})

export default Store;
