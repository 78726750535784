import React, { useEffect, useState } from "react";
import "./login.css";
import { toast } from "react-hot-toast";
import AuthServices from "../services/AuthServices";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../Component/loader/CustomLoader";
import logo from "../Images/logo.png";
import google from "../Images/google-2-1@2x.png";
import facebook from "../Images/facebook.png";
import { useDispatch } from "react-redux";
import { setUser } from "../Redux/Reducer/User";
import { auth } from "../../src/Firebase/fbConfig";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { id } = useParams();
  // console.log("fucking id", id);
  const initValue = {
    email: "",
    password: "",
  };
  const [formValue, setFormValue] = useState(initValue);
  const [formError, setFormError] = useState();
  const [click, setClick] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showpass, setShowPass] = useState(false);

  // console.log("formValue", formValue);

  // handle change
  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setFormValue((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // useEffect(() => {
  //   if (id) {
  //     logindirect();
  //   }
  // }, []);
  // validation
  const validate = () => {
    const email_regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!formValue.email) {
      toast.error("Email is Required");
      return true;
    } else if (!email_regex.test(formValue.email)) {
      toast.error("Please Enter Valid Email");
      return true;
    }

    if (!formValue.password) {
      toast.error("Password is Required");
      return true;
    }

    return false;
  };

  // handleSubmit
  const handleLogin = async (e) => {
    // e.preventDefault();

    console.log("handleLogin function called.");

    if (validate()) return;

    const sendData = {
      email: formValue.email,
      password: formValue.password,
    };

    setLoading(true);
    const res = await AuthServices.fetchLogin(sendData);
    console.log("resLogin", res);
    if (res && res?.status) {
      dispatch(setUser(res?.data[0]));
      let tokenData = { token: res?.data?.[0]?.token };
      let userId = { userId: res?.data?.[0]?._id };
      localStorage.setItem("constructionToken", JSON.stringify(tokenData));
      localStorage.setItem("user", JSON.stringify(res.data[0]));
      toast.success("Login Successfull");
      setLoading(false);
      navigate("/");
    } else {
      toast.error(res?.message);
      setLoading(false);
    }

    console.log("sendData", sendData);
  };
  const handleGoogleLogin = () => {
    setLoading(true);

    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        if (result) {
          handlesociallogin(user.email);
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };
  const handleFacebookLogin = () => {
    setLoading(true);

    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        console.log("user facebook", user);
        // if (result) {
        //   handlesociallogin(user.email)
        // }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };
  const handlesociallogin = (email) => {
    AuthServices.sociallogin({ email: email })
      .then((res) => {
        if (res && res.status) {
          console.log("res from social", res);
          dispatch(setUser(res.data.data));
          localStorage.setItem(
            "constructionToken",
            JSON.stringify(res.data.data.token)
          );
          localStorage.setItem("user", JSON.stringify(res.data.data));
          toast.success("Login Successfull");
          setLoading(false);
          navigate("/");
        } else {
          navigate("/account-creation", {
            state: { userData: {}, google: true, emailId: email },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <CustomLoader loading={loading} />
      <div className="login-page">
        <div className="logincard">
          <div className="logoarea">
            <img src={logo} className="img-fluid" alt="" />
          </div>

          <div className="form">
            <h2 className="head">Login</h2>
            <p className="text">Please Sign in to continue</p>
            {/* Email */}
            <form className="login-form">
              <div className="form-group">
                <label htmlFor="">Email address</label>
                <input
                  type="email"
                  placeholder=""
                  name="email"
                  value={formValue.email}
                  onChange={(e) => handleChangeForm(e)}
                />
              </div>

              {/* password */}
              <div className="form-group">
                <label htmlFor="">Password</label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid gray",
                    borderRadius: "5px",
                  }}
                >
                  <input
                    style={{ outline: 0, border: 0 }}
                    type={showpass ? "text" : "password"}
                    placeholder=""
                    name="password"
                    value={formValue.password}
                    onChange={(e) => handleChangeForm(e)}
                  />

                  {showpass ? (
                    <i
                      onClick={() => setShowPass(false)}
                      class="fa-solid fa-eye"
                    ></i>
                  ) : (
                    <i
                      onClick={() => setShowPass(true)}
                      class="fa-solid fa-eye-slash"
                    ></i>
                  )}
                </div>
              </div>
            </form>

            <div
              className="forgotpassword"
              onClick={() => {
                navigate("/ForgetPass");
              }}
            >
              <a href="#">Forgot Password?</a>
            </div>

            <button onClick={(e) => handleLogin(e)} className="loginhere">
              login here
            </button>

            <div>
              <div className="divider">
                <h6>OR</h6>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  type="submit"
                  className="btn continuewithgoogle"
                  onClick={handleGoogleLogin}
                >
                  <span className="mr-1">
                    <img src={google} className="img-fluid" alt="" />
                  </span>
                  Continue with Google
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  type="submit"
                  className="btn continuewithgoogle"
                  onClick={handleFacebookLogin}
                >
                  <span className="mr-1">
                    <img src={facebook} className="img-fluid" alt="" />
                  </span>
                  Continue with Facebook
                </button>
              </div>
              <h6
                className="Createnewaccount"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/create-account");
                }}
              >
                Create New Account
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
