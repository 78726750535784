import React from 'react'
import { useNavigate } from 'react-router-dom';
import succ from "../../Images/check_img.avif";
import { useDispatch } from 'react-redux';
import { inCreaseCount } from '../../Redux/Reducer/TaskSlice';

function PaymentSucsessModel(props) {
    const { iamFrom, isAccountCreate } = props
    const navigate = useNavigate()
    const dispatch = useDispatch();
    console.log(iamFrom, "jhgsdhsjklq")
    const { setPaymentSucsessModelflag, projectId } = props;
    return (
        <>
            <section className="success">
                <div className="success_page">
                    <div className="success_img">
                        <img src={succ} className='img-fluid' alt="check_img" />
                    </div>
                    <div className="success_title">
                        {/* {iamFrom == "taskPayment" ?
                            <p>You Successfully Completed Your Payment</p> : <p>Funds added successfully</p>} */}
                        {isAccountCreate === "Created" ? (
                            <p>Bank Account Added Successfully </p>
                        ) : iamFrom === "taskPayment" ? (
                            <p>You Successfully Completed Your Payment</p>
                        ) : (
                            <p>Funds added successfully</p>
                        )}

                    </div>


                    <div className="home_btn">
                        <button className="back_home_btn" onClick={() => {
                            setPaymentSucsessModelflag(false)
                            if (iamFrom === "paymentHistoryPage") {
                                navigate('/payment')
                            } else {
                                navigate(`/project-detail/${projectId}`)

                            }

                            // setquaryparam("false")
                            // dispatch(inCreaseCount())
                        }
                        }>ok</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PaymentSucsessModel