import React, { useEffect, useState } from "react";
import "./login.css";
import { toast } from "react-hot-toast";
import AuthServices from "../services/AuthServices";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../Component/loader/CustomLoader";
import logo from "../Images/logo.png";
import google from "../Images/google-2-1@2x.png";
import facebook from "../Images/facebook.png";
import { useDispatch } from "react-redux";
import { setUser } from "../Redux/Reducer/User";
import { auth } from "../../src/Firebase/fbConfig";
import OtpVerification from "./OtpVerification";

const ForgetPass = () => {

    const [pass, setPass] = useState("");
    const [conpass, setconpass] = useState("");
    const [text, setText] = useState("")
    const [otpflag, setotpflag] = useState(false)
    const [eterpassflag, seteterpassflag] = useState(false)
    const [showpass, setShowPass] = useState(false);
    const [showpass1, setShowPass1] = useState(false);
    const [otp, setotp] = useState("")
    const [res, setres] = useState()
    const [PasswordFocus, setPasswordFocus] = useState(false);


    const navigate = useNavigate()


    function sundEmail() {
        const pattern =
            /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,20}[\.][a-z]{2,5}/g;
        const emailresult = pattern.test(text);
        if (!emailresult) {
            toast.error('Enter valid email');
            return false;
        }
        // setLoderStatus(true)
        AuthServices.forgototp({
            email: text
        })
            .then(res => {
                // setLoderStatus(false)
                console.log("res", res);
                if (res && res.status) {
                    setotp(res.data.otp)
                    setres(res.data)
                    setotpflag(true)
                    // AuthServices.setToken(res.data.token)
                    toast.success("Otp Send to your email")


                } else {
                    toast.error('Enter valid email',);
                }
            })
            .catch(err => {

                console.log("err", err);
            })
    }

    function isPasswordValid(pass) {
        const validationPoints = [];

        // Check for at least one lowercase letter
        if (!/[a-z]/.test(pass)) {
            validationPoints.push({
                isValid: false,
                error: "Password must contain at least one lowercase letter",
            });
        } else {
            validationPoints.push({
                isValid: true,
                error: "Password must contain at least one lowercase letter",
            });
        }

        // Check for at least one uppercase letter
        if (!/[A-Z]/.test(pass)) {
            validationPoints.push({
                isValid: false,
                error: "Password must contain at least one uppercase letter",
            });
        } else {
            validationPoints.push({
                isValid: true,
                error: "Password must contain at least one uppercase letter",
            });
        }

        // Check for at least one number (0-9)
        if (!/[0-9]/.test(pass)) {
            validationPoints.push({
                isValid: false,
                error: "Password must contain at least one number",
            });
        } else {
            validationPoints.push({
                isValid: true,
                error: "Password must contain at least one number",
            });
        }

        // Check for at least one special character (!@#$%^&*)
        if (!/[!@#$%^&*]/.test(pass)) {
            validationPoints.push({
                isValid: false,
                error:
                    "Password must contain at least one special character (!@#$%^&*)",
            });
        } else {
            validationPoints.push({
                isValid: true,
                error:
                    "Password must contain at least one special character (!@#$%^&*)",
            });
        }

        // Check if the password is at least 8 characters long
        if (pass.length < 8) {
            validationPoints.push({
                isValid: false,
                error: "Password must be at least 8 characters long",
            });
        } else {
            validationPoints.push({
                isValid: true,
                error: "Password must be at least 8 characters long",
            });
        }

        return validationPoints;
    }
    const cheakPassword = isPasswordValid(pass).every(
        (item) => item.isValid == true
    );
    function submitData() {
        if (pass == "" && conpass == "") {
            toast.error("Please enter password ",);
            return
        }
        if (pass != conpass) {
            toast.error("password doesn't match",);
            return;
        }
        // setLoderStatus(true)
        AuthServices.changepass({
            "userId": res._id,
            "newPass": conpass,
            "otp": otp
        })
            .then(res => {
                // setLoderStatus(false)
                if (res && res.status) {
                    toast.success("Password updated successfully , Please login",);
                    navigate("/login")
                } else if (res && res.msg == "OTP verified , PassWord Chnaged successfully !!") {
                    toast.success("Password updated successfully , Please login",)
                    navigate("/login")
                }
                console.log("res", res);
            })
            .catch(res => {

                console.log("err", res);
            })
    }

    if (eterpassflag) {
        return (
            <div className="login-page">
                <div className="logincard">
                    <div className="logoarea">
                        <img src={logo} className="img-fluid" alt="" />
                    </div>

                    <div className="form">
                        <h2 className="head">Login</h2>
                        <p className="text">Please Sign in to continue</p>

                        <form className="login-form">

                            <div className="form-group">
                                <label htmlFor="">Password</label>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        border: "1px solid gray",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <input
                                        style={{ outline: 0, border: 0 }}
                                        type={showpass ? "text" : "password"}
                                        placeholder=""
                                        name="password"
                                        value={pass}
                                        onChange={(e) => setPass(e.currentTarget.value)
                                        }
                                        onFocus={() => setPasswordFocus(true)}
                                    />


                                    {showpass ? (
                                        <i
                                            onClick={() => setShowPass(false)}
                                            class="fa-solid fa-eye"
                                        ></i>
                                    ) : (
                                        <i
                                            onClick={() => setShowPass(true)}
                                            class="fa-solid fa-eye-slash"
                                        ></i>
                                    )}
                                </div>
                                {!cheakPassword && PasswordFocus ? (
                                    <div style={{ marginHorizontal: "15px" }}>
                                        {isPasswordValid(pass).map((item) => (
                                            <h6 style={{ color: "red" }}>{item.error}</h6>
                                        ))}
                                    </div>
                                ) : null}
                            </div>


                            <div className="form-group">
                                <label htmlFor="">Password</label>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        border: "1px solid gray",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <input
                                        style={{ outline: 0, border: 0 }}
                                        type={showpass1 ? "text" : "password"}
                                        placeholder=""
                                        name="password"
                                        value={conpass}
                                        onChange={(e) => setconpass(e.target.value)}
                                    />


                                    {showpass ? (
                                        <i
                                            onClick={() => setShowPass1(false)}
                                            class="fa-solid fa-eye"
                                        ></i>
                                    ) : (
                                        <i
                                            onClick={() => setShowPass1(true)}
                                            class="fa-solid fa-eye-slash"
                                        ></i>
                                    )}
                                </div>
                            </div>
                        </form>
                        <button
                            className="loginhere"
                            onClick={(e) => {
                                // handleLogin(e);
                                submitData()
                            }}
                        >
                            Reset
                        </button>

                    </div>
                </div>
            </div>
        )
    } else {

        return (
            <div>
                {otpflag ? <OtpVerification otp={otp} flag={"forgotpass"} seteterpassflag={seteterpassflag} /> :
                    <div className="login-page">
                        <div className="logincard">
                            <div className="logoarea">
                                <img src={logo} className="img-fluid" alt="" />
                            </div>
                            <div className="form">

                                <form className="login-form">
                                    {/* Email */}
                                    <div className="form-group">
                                        <label htmlFor="">Email address</label>
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            name="address_id"
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                        />
                                    </div>
                                </form>

                                <button
                                    className="loginhere"
                                    onClick={(e) => {
                                        // handleLogin(e);
                                        sundEmail()
                                    }}
                                >
                                    Send OTP
                                </button>


                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

};

export default ForgetPass;
