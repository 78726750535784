import React, { useEffect, useState } from "react";
// import "./login.css";
// import "../../Authentication/Login.css"
import { toast } from "react-hot-toast";
import HttpClient from "../../utils/HttpClient";
import AuthServices from "../../services/AuthServices";
import ProductServices from "../../services/ProductServices";
import { reactLocalStorage } from "reactjs-localstorage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../loader/CustomLoader";
import OtpVerification from "../../Authentication/OtpVerification";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import country from "../../Authentication/CountryCode";

import logo from "../../logo.svg";

import { useFormik } from "formik";
import * as Yup from "yup";
import { MultiSelect } from "react-multi-select-component";
import { changeUpdateState } from "../../Redux/Reducer/UpdateServiceSlice";


const options = [
  { label: "Grapes 🍇", value: "grapes" },
  { label: "Mango 🥭", value: "mango" },
  { label: "Strawberry 🍓", value: "strawberry", disabled: true },
];

export default function UpdateServiceNew({ setTabIndex, setModalflag }) {
  const localdata = localStorage.getItem("user");
  const userdata = JSON.parse(localdata);
  const navigate = useNavigate();
  console.log("userdata", userdata);

  const [myPlan, setmyPlans] = useState([]);
  console.log("kjbjhc", myPlan[0]?.skillLimit);

  const [errflag, setErrflag] = useState(false);
  const [selected, setSelected] = useState(
    (userdata?.categories.length > 0)
      ? userdata?.categories?.map((label) => ({
        label,
        value: label,
      }))
      : []
  );
  const keySkill = [
    "Soundproofing",
    "Air Conditioning Installation",
    "Door Installation",
    "Garage Installation",
    "Garage Door Repairs",
    "Kitchen Renovation",
    "Bathroom Remodeling",
    "Installing New Flooring",
    "Painting (Interior/Exterior)",
    "Replacing Windows or Doors",
    "Roof Repair or Replacement",
    "Double Glazing",
    "Adding Insulation",
    "Upgrading Electrical or Plumbing Systems",
    "Building a New Room",
    "Loft Conversions",
    "Home Office and Annex",
    "Adding a Sunroom or Conservatory",
    "Expanding the Garage",
    "Building a Deck or Patio",
    "Constructing a New Porch",
    "Extending the Living Space",
    "Building an Additional Floor",
    "Constructing a New House",
    "Lawn Care and Maintenance",
    "Garden Cleanup",
    "Shed Creation",
    "Outdoor Lighting",
    "Irrigation and Sprinkler Systems",
    "Develop a site",
    "other",
  ];
  const options = keySkill.map((label) => ({
    label,
    value: label,
  }));
  const dispatch = useDispatch()

  //get users data:
  const getAlldatas = async () => {
    const res = await AuthServices.getUserDatas()
    const temparr = res?.data[0].categories.map((label) => ({
      label,
      value: label,
    }))
    setSelected(temparr)
    // console.log("jkhdgdhkg", res)
  }
  //my Plans..
  const myPlans = () => {
    ProductServices.getMyPlans()
      .then((res) => {
        if (res && res.status) {
          setmyPlans(res.data);
          console.log("jggfd", res.data);
        } else {
          // alert("server error");
        }
      })
      .catch((err) => {
        console.log("get my plan error: ", err.message);
      });
  };

  const handleUpdate2 = () => {

    if (selected.length > myPlan[0]?.skillLimit) {
      setErrflag(true);
      return;
    }

    let data = {
      categories: selected.map((ele, id) => ele.value),

    };

    AuthServices.profileUpdate(data)
      .then((res) => {
        // setLoderStatus(false);

        if (res && res.status) {
          toast.success("service successfully updated ");
          // dispatch(changeUpdateState(true));
          // window.history.back()
          setTabIndex(1)
          setModalflag(false)
          // getAlldatas()
        }
        console.log("res", res);
      })
      .catch((err) => {
        // setLoderStatus(false)
        // console.log('err', err);
      });
    console.log("keySkillee", data);
  };

  useEffect(() => {
    myPlans();
    getAlldatas()
  }, []);

  return (
    <div className="create-project-main" style={{ backgroundColor: '' }}>
      <div className="create-project">
        <div className="add-project">
          <h4 style={{ display: "flex", justifyContent: "center" }}>
            Services
          </h4>
        </div>
        <h5 style={{ color: "red", display: errflag ? "block" : "none" }}>Can't be exced purchase service limit({myPlan[0]?.skillLimit})
        </h5>

        <div
          className="closebtn"
          onClick={() => {
            setModalflag(false)
          }}
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
        <form style={{ height: '440px' }}>
          <div className="service_input" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <MultiSelect
              options={options}
              value={selected}
              onChange={(e) => {
                // console.log("kjbjhc", selected.length, myPlan[0]?.skillLimit, e.length);
                // if (
                //   e.length > myPlan[0]?.skillLimit || e.length > selected.length

                // ) {
                //   setErrflag(true)
                //   toast.error("Can't be exxect purchase service limit");

                // } else {
                setErrflag(false)
                setSelected(e);
                // }
              }}
              labelledBy="Select"
            />
            <button
              className="loginhere"
              style={{ width: "30%", float: "right", marginLeft: 'auto' }}
              onClick={() => {
                handleUpdate2();
              }}
              type="button"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
