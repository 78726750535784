import React from 'react'

function PartialPaymentRequest(props) {
    const { closePartial } = props;
    return (
        <>
            <section className='PartialPaymentRequest'>
                <div className='modalbody_'>
                    <div className='head'>
                        <h4>Partial Payment Request</h4>
                    </div>

                    <div className='close_btn'>
                        <button className='btn' onClick={() => closePartial(false)}><i className="fa-solid fa-xmark"></i></button>
                    </div>

                    <form>
                        <div className="form-group row">
                            <div className='col-4'>
                                <label htmlFor="">Percentage %</label>
                            </div>
                            <div className='col-8'>
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby="textHelp"
                                    placeholder=""
                                />
                            </div>
                        </div>

                        <p>OR</p>

                        <div className="form-group row">
                            <div className='col-4'>
                                <label htmlFor="">Payment Amount</label>
                            </div>
                            <div className='col-8'>
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby="textHelp"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </form>

                    <div className='modalSubmit_btn mt-4'>
                        <button className='btn'>Send Request</button>
                    </div>

                </div>
            </section>
        </>
    )
}

export default PartialPaymentRequest