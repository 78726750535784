import React, { useState } from 'react';
import supplier from "../../Images/supplier.png"
export default function SupplierModal({closeModal}) {
return (
        <>
            <section className='supplier_modal_main'>
                <div className='supplier_modal'>
                    <div className='supplier_overlay'></div>
                    <div className='supplier_content'>
                        <div>
                        <div className='supplier_img'>
                            <img src={supplier} alt="/" />
                        </div>
                        <div className='closemark' onClick={()=>closeModal(false)}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                        </div>
                        
                        
                        <div className='supplier_details_main'>

                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-2'>
                                    <h5 className='supplier_details_txt'>Name</h5>
                                </div>
                                <div className='col-lg-8 col-md-8 col-10'>
                                    <p className='supplier_details'>: Mira lipshutz</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-5'>
                                    <h5 className='supplier_details_txt'>Phone Number </h5>
                                </div>
                                <div className='col-lg-8 col-md-8 col-7'>
                                    <p className='supplier_details'>: +44 123 123 1234</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-5'>
                                    <h5 className='supplier_details_txt'>Supply Type </h5>
                                </div>
                                <div className='col-lg-8 col-md-8 col-7'>
                                    <p className='supplier_details'>: Plumber</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-5'>
                                    <h5 className='supplier_details_txt'>Email Address</h5>
                                </div>
                                <div className='col-lg-8 col-md-8 col-7'>
                                    <p className='supplier_details'> : abcd@gmail.com</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-5'>
                                    <h5 className='supplier_details_txt'>Physical Address </h5>
                                </div>
                                <div className='col-lg-8 col-md-8 col-7'>
                                    <p className='supplier_details'> : 45 Prince Consort Road, Kemback, Zip Code: KY15 1SJ, United Kingdom</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
