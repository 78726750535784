import React, { useEffect } from 'react'
import solar_wallet_linear from "../../Images/solar_wallet_linear.png"
import AOS from "aos";
import "aos/dist/aos.css";

export default function PaymentreceivedModal({ closeModal }) {
    let data = [
        {
            id: 1,
            name: 'Johh Moore',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Abdullah Farouq',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Priyanka Ansari',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Amir Khan',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Artsiom Milevski',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Valeria López',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Ali Rashid',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Mariana Díaz',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Arya Ansari',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Mariana Castro',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Varsha Sharma',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Amina Müller',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Ethan Hodžić',
            price: 500,
            date: 25,
            year: 23

        },
        {
            id: 1,
            name: 'Elizabeth Williams',
            price: 500,
            date: 25,
            year: 23

        },

    ]
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (

        <>
            <section className='PaymentreceivedModal'>
                <div className='Paymentreceived_modal' data-aos="fade-left" data-aos-easing="linear"
                    data-aos-duration="250">
                    <div className='Paymentreceived_overlay'></div>
                    <div className='Paymentreceived_content'>
                        <div className='modal_project_content'>
                            <p className='notification_txt'>Project Name / Payment history</p>

                            <div className='closemark' onClick={() => closeModal(false)}>
                                <i class="fa-solid fa-xmark"></i>
                            </div>
                            <div className='paymnt_img_txt_prc'>
                                <div className='paymnt_img_txt'>
                                    <div className='paymnt_img'>
                                        <img src={solar_wallet_linear} alt="/" />
                                    </div>
                                    <div className='paymnt_txt'>
                                        <p>Paymentreceived</p>
                                    </div>
                                </div>
                                <div className='paymnt_prc'>
                                    <p>£30,000</p>
                                </div>
                            </div>

                            <div className='rcvd_frm_amnt_flx'>
                                <p>Received from</p>
                                <p>Amount</p>
                            </div>
                            <div className='paymnt_rcvd_main'>
                                {data.map((item, index) => {
                                    return (
                                        <div className='paymnt_rcvd_main_flx'>
                                            <div className='rcvd_hldr'>
                                                <p>{item.name}</p>

                                            </div>
                                            <div className='rcvd_prc_dt'>
                                                <p>£ {item.price}</p>
                                                <p className='rcvd_dt'> {item.date} july{item.year}</p>
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className='pymnt_hstry'>
                                    <a href="#" className='pymnt_hstry_btn'>See more</a>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
