import React, { useEffect, useState } from "react";
import PaymentDetailsCard from "../../Component/PaymentDetailsCard";
import SupplierCard from "../../Component/SupplierCard";
import LendersCard from "../../Component/LendersCard";
import { Link } from "react-router-dom";
import "./Myjob.css";
import dp from "../../Images/dp.png";
import HttpClient from "../../utils/HttpClient";
import AuthServices from "../../services/AuthServices";
import moment from "moment";
import { useParams } from "react-router-dom";
import ProductServices from "../../services/ProductServices";
import MyProject from "../../Component/MyProject";
import { useSelector } from "react-redux";
import Piechart from "./Piechart";
import MybarChart from "./MybarChart";
import WelcomeJerremy from "../../Component/WelcomeJerremy";

const Myjob = () => {
  const [onclickshw, setOnclickshw] = useState(false);
  const [val, setVal] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const { productData, loading, AllProduct } = useSelector(
    (state) => state.productReducer
  );
  const [myproject, setMyProject] = useState([]);
  const [newArr, setNewArr] = useState([]);
  const { id } = useParams();

  //for pie chart
  const [seriestime, setseriestime] = useState([]);
  const [seriesPayment, setseriesPayment] = useState([]);
  const [seriesTask, setseriesTask] = useState([]);

  const handleClickShow = () => {
    setOnclickshw(!onclickshw);
    // setDefltshw(!defltshw)
  };

  //wallet balance
  const [price, setPrice] = useState("");
  const [withdrableAmount, setwithdrawablePrice] = useState("");

  const get_walletBalance = () => {
    AuthServices.fetchProfile()
      .then((res) => {
        if (res && res.status) {
          console.log("fdghgh", res);

          setwithdrawablePrice(res?.data?.[0]?.walletBalanceReceived ?? 0);

          setPrice(res?.data?.[0]?.walletBalance ?? 0);
          console.log("jhgjheyn", res?.data?.[0]?.walletBalance);
        }
      })
      .catch((err) => {
        console.log("dfhjh", err);
      });
  };
  useEffect(() => {
    get_walletBalance();
  }, []);

  //my Projects
  const getMybidedProjects = () => {
    ProductServices.getAssignProduct()
      .then((res) => {
        console.log("baler amar project res", res);
        if (res && res.status) {
          setMyProject(res.data);
        }
      })
      .catch((err) => {});
  };
  console.log(productData, projectData, "hghh");

  const fetchProjectData = async () => {
    let res = await AuthServices.getProject();
    console.log("projectdata", res?.data);
    setProjectData(res?.data);
  };

  //get Pietime data
  function getpietimeData() {
    // console.log("lkfjhkj", myproject);
    ProductServices.pietime(myproject[0]?._id)
      .then((res) => {
        if (
          res &&
          res.status &&
          res.data &&
          res.data.totalTimePercentage &&
          res.data.timeRemainingPercentage
        ) {
          const temparr = [];
          temparr.push(res.data.timePassed);
          temparr.push(res.data.totalDuraton - res.data.timePassed);
          setseriestime(temparr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  //get piepaymentdata
  function getpiePaymentData() {
    ProductServices.piepayment(myproject[0]?._id)
      .then((res) => {
        if (res && res.status) {
          const temparr = [];
          temparr.push(res.data.completeTaskAmount.totalAmount);
          temparr.push(
            res.data.totalTaskAmount.totalAmount -
              res.data.completeTaskAmount.totalAmount
          );

          setseriesPayment(temparr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  //get pietaskdata
  function getpietaskData() {
    ProductServices.pietask(myproject[0]?._id)
      .then((res) => {
        if (res && res.status) {
          console.log("bghh", res);
          const temparr = [];
          temparr.push(res.data.completeCount);
          temparr.push(res.data.incompleteCount);

          setseriesTask(temparr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  useEffect(() => {
    if (myproject[0]?._id) {
      getpietimeData();
      getpiePaymentData();
      getpietaskData();
    }

    console.log("lkfjhkj", myproject[0]?._id);
  }, [myproject[0]?._id]);

  useEffect(() => {
    fetchProjectData();
    getMybidedProjects();
  }, []);

  return (
    <>
      <div className="main_wrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <div className="middlepartwrap">
                <div className="job_dtls">
                  <div className="posted_icon_txt_main">
                    <Link to="/" className="posted_icon">
                      <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-4 col-12">
                    <Piechart
                      series={seriesPayment}
                      label={["Complete (£)", "Remaining (£)"]}
                    />
                    <h5 className="text-center">Payment Details</h5>
                  </div>
                  <div className="col-md-4 col-12">
                    <Piechart
                      series={seriestime}
                      label={["Complete (s)", "Remaining (s)"]}
                    />
                    <h5 className="text-center">Time Details</h5>
                  </div>

                  <div className="col-md-4 col-12">
                    <Piechart
                      series={seriesTask}
                      label={["Complete ", "Remaining "]}
                    />
                    <h5 className="text-center ">Task Details</h5>
                  </div>
                </div> */}
                {myproject?.length > 0 ? (
                  <MyProject productData={myproject} />
                ) : null}
                <div>{/* <MybarChart /> */}</div>
                {/* {projectData?.map((item, index) => {
                    console.log("id12", item);
                    return (
                      <div className="job_image_description_main" key={index}>
                        <div className="job_image">
                          <img src={item?.image?.[0]} alt="" />
                        </div>
                        <div className="prjct_dscrptn">
                          <div className="pname">
                            <h5>{item?.name}</h5>
                            <div className="projct_hrs_attach_main">
                              <button class="Attachment_btn btn">
                                <i className="fa-regular fa-clock mr-2"></i>
                                {moment(item?.createdAt).fromNow()}
                              </button>
                              <button class="Attachment_btn btn">
                                <i className="fa-solid fa-paperclip mr-2"></i>
                                Attachment {item?.image?.length}
                              </button>
                            </div>
                            <h4> Project Description: </h4>
                            <p>{item?.description}</p>
                          </div>

                          <div className="applied_drpdwn_icon">
                            <p>
                              Total applied Persons ({item?.bidData?.length})
                            </p>
                            <div
                              className="icon_drpdwn"
                              onClick={handleClickShow}
                            >
                              <span
                                className={
                                  onclickshw
                                    ? "fa-solid fa-chevron-down"
                                    : "fa-solid fa-chevron-up"
                                }
                              ></span>
                            </div>
                          </div>
                          {onclickshw && (
                            <div className="img_txt_amont_txt_btn_main">
                              {item?.bidData?.map((biditem, index) => {
                                console.log(
                                  "Myjiobitem",
                                 
                                  biditem
                                );
                                return (
                                  <div className="img_txt_amont_txt_btn">
                                    <div className="prfl_amnt_prjct">
                                      <div class="profile mb-3">
                                        <div class="profPic">
                                          <img
                                         
                                            src={dp}
                                            class="img-fluid"
                                            alt=""
                                          />
                                        </div>
                                        <div class="details">
                                          <div class="d-flex align-items-center mb-1">
                                            <h5>
                                              {biditem?.bidderData?.username}
                                            </h5>
                                            <p>
                                              Rating <span>(0.0)</span>
                                            </p>
                                          </div>
                                          <h6>
                                            {moment(item?.createdAt).fromNow()}
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="amnt_prjct">
                                        <p>£ {biditem?.bidPrice}</p>
                                      </div>
                                    </div>
                                    <div className="prjct_qute_txt">
                                      <p>{biditem?.message}</p>
                                    </div>

                                    <div class="bottom_">
                                      <Link
                                        to="/application_details"
                                        className="vw_applctn"
                                      >
                                        View Application
                                      </Link>
                                      <Link to="/" className="vw_applctn acptd">
                                        Accept
                                      </Link>
                                      <Link to="/" className="vw_applctn rjct">
                                        Reject
                                      </Link>
                                    </div>

                                    {biditem?.acceptStatus === "Rejected" ? (
                                      <div>
                                        <button className="vw_applctn rjct">
                                          Rejected
                                        </button>
                                      </div>
                                    ) : biditem?.acceptStatus === "Accepted" ? (
                                      <div style={{ display: "flex" }}>
                                        <Link
                                          to={`/application_details/${biditem?._id}/${item?._id}`}
                                          className="vw_applctn"
                                        >
                                          View Application
                                        </Link>
                                        <button className="vw_applctn acptd">
                                          Accepted
                                        </button>
                                      </div>
                                    ) : (
                                      <div style={{ display: "flex" }}>
                                        <Link
                                          to={`/application_details/${biditem?._id}/${item?._id}`}
                                          className="vw_applctn"
                                        >
                                          View Application
                                        </Link>
                                        <button
                                          className="vw_applctn acptd"
                                          onClick={(e) =>
                                            handleAccept(e, it?._id)
                                          }
                                        >
                                          Accept
                                        </button>
                                        <button
                                          className="vw_applctn rjct"
                                          onClick={(e) =>
                                            handlereject(e, it?._id)
                                          }
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })} */}
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-12 pr-0">
              <div className="rightsidebar">
                <PaymentDetailsCard
                  Payment="Wallet Balance"
                  // text="Lorem ipsum dolor sit amet consectetur"
                  amout={"£" + Number(price)?.toFixed(2)}
                  btnname="View Details"
                  bgcolor="#04B600"
                  // Receivedbtn={true}
                />

                <PaymentDetailsCard
                  Payment="withdrawn Amount"
                  // text="Lorem ipsum dolor sit amet consectetur"
                  amout={"£" + Number(withdrableAmount)?.toFixed(2)}
                  btnname="View Details"
                  bgcolor="#7F6DF0"
                  // Duebtn={true}
                />

                <SupplierCard />

                <LendersCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Myjob;
