import React, { useEffect, useState } from "react";
import WelcomeJerremy from "../../Component/WelcomeJerremy";
import MyProject from "../../Component/MyProject";
import Recentlybiddedproject from "../../Component/Recentlybiddedproject";
import PaymentDetailsCard from "../../Component/PaymentDetailsCard";
import SupplierCard from "../../Component/SupplierCard";
import LendersCard from "../../Component/LendersCard";
import Projecttrello from "../../Component/Projecttrello";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CustomLoader from "../../Component/loader/CustomLoader";
import { reactLocalStorage } from "reactjs-localstorage";
import ProductServices from "../../services/ProductServices";
import { useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
// Modal.setAppElement('#yourAppElement');

function ProjectSingle() {
  const dispatch = useDispatch();
  const { login_status, userData } = useSelector((state) => state.User);
  const [project, setproject] = useState([]);
  const [myproject, setMyProject] = useState([]);
  const [loading, setLoading] = useState(false);

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  console.log("userData", userData);
  useEffect(() => {}, []);

  const getroledata = () => {
    // console.log('roledata',userData?.jobRoleData?.findIndex(it=>it.name == 'Homeowner'))
    if (
      userData?.jobRoleData?.length == 1 &&
      userData?.jobRoleData?.findIndex((it) => it.name == "Homeowner") == 0
    ) {
      return "Homeowner";
    } else {
      return null;
    }
  };
  return (
    <>
      <CustomLoader loading={loading} />
      <div className="main_wrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <section className="recentlybidded_sec">
                <div className="head_tag">
                  <div className="name">
                    <h4> project</h4>
                  </div>
                  <div className="SeeAll_btn">
                    {/* <button className='btn'>See All</button> */}
                  </div>
                </div>
                <div className="allcard_wrap">
                  <div className="card_box">
                    <div className="d-flex" style={{ margin: "5px 0px" }}>
                      <img
                        src={
                          "https://www.rotarybangkok.org/wp-content/uploads/2017/11/user-placeholder.d2a3ff8.png"
                        }
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50px",
                          border: "1px solid",
                        }}
                      />
                      <div>
                        <h6 style={{ margin: "5px 0px 0px 5px" }}>
                          Rohan Maity
                        </h6>
                        <button className="Attachment_btn btn">
                          <i className="fa-regular fa-clock mr-2"></i>10th oct
                          2023
                        </button>
                      </div>
                    </div>
                    <div className="top_">
                      <div className="head">
                        <h4>Rintu</h4>
                      </div>
                      {/* <div className='action_fun'> */}
                      <h6 style={{ color: "green" }}>Open</h6>
                      {/* </div> */}
                    </div>

                    <img
                      src={
                        "https://www.befunky.com/images/prismic/82e0e255-17f9-41e0-85f1-210163b0ea34_hero-blur-image-3.jpg"
                      }
                      style={{
                        height: "250px",
                        width: "100%",
                        borderRadius: "5px",
                        marginTop: "10px",
                      }}
                    />
                    <div className="bottom_area" style={{ margin: "10px 0px" }}>
                      <div className="right">
                        <div className="d-flex align-items-center">
                          <span>$ 2000 - $5000</span>
                        </div>
                      </div>
                    </div>
                    <div className="text">
                      <p>test,</p>
                    </div>

                    <div className="bottom_">
                      <button className="Attachment_btn btn">
                        <i className="fa-regular fa-note-sticky mr-2"></i> 10
                        Total Bid
                      </button>
                      <button className="Attachment_btn btn">
                        <i className="fa-solid fa-link mr-2"></i> Attachment 2
                      </button>
                      <button className="Attachment_btn btn">
                        <i className="fa-solid fa-eye mr-2"></i>20
                      </button>
                    </div>
                    <div className="top_">
                      <div className="head"></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-lg-4 col-md-4 col-12 pr-0">
              <div className="rightsidebar">
                <PaymentDetailsCard
                  Payment="Payment Received"
                  text="Lorem ipsum dolor sit amet consectetur"
                  amout="£30,000"
                  btnname="View Details"
                  bgcolor="#04B600"
                  Receivedbtn={true}
                />
                <PaymentDetailsCard
                  Payment="Payment Due"
                  text="Lorem ipsum dolor sit amet consectetur"
                  amout="£25,000"
                  btnname="View Details"
                  bgcolor="#7F6DF0"
                  Duebtn={true}
                />

                <SupplierCard />

                <LendersCard />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
        <button onClick={closeModal}>close</button>
        <div>Bid project amount</div>
        <form>
          <div style={{}}>
            <input placeholder="enter your amout" />
          </div>
        </form>
        <button>submit</button>
      </Modal>
    </>
  );
}

export default ProjectSingle;
