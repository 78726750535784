import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function ShowSubtaskCard({ item, index, setModalE, setSubTaskId, deleteSubTast }) {

    return (
        <>
            <section className='ShowSubtaskCard' key={index}>
                <div className=''>
                    <div className='left'>
                        <div className='d-flex' style={{
                            justifyContent: 'space-between'
                        }}>
                            <div className='head'>
                                <h4>{item?.name}</h4>
                            </div>
                            {/* <i class="fa fa-edit" style={{
                                fontSize: '18px',
                                cursor:'pointer',
                                color:'#066DE6'
                            }}
                                onClick={() => {
                                    setModalE(true);
                                    setSubTaskId(item?._id)
                                }}
                            ></i>
                            <i class='fas fa-trash-alt' style={{
                                fontSize:"18px",
                                cursor:'pointer',
                                color:'red'
                            }}
                            onClick={()=>deleteSubTast(item._id)}
                            ></i> */}
                            <div className='right'>
                                <button className='Markascompleted_btn'>Mark as completed</button>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between pt-3'>
                            <div className='text_'>
                                <p>Created on- {moment(item?.createdAt).format("LL")} </p>
                            </div>
                            <i class="fa fa-edit" style={{
                                fontSize: '18px',
                                cursor:'pointer',
                                color:'#066DE6'
                            }}
                                onClick={() => {
                                    setModalE(true);
                                    setSubTaskId(item?._id)
                                }}
                            ></i>
                            <i class='fas fa-trash-alt' style={{
                                fontSize:"18px",
                                cursor:'pointer',
                                color:'red'
                            }}
                            onClick={()=>deleteSubTast(item._id)}
                            ></i>

                        </div>
                    </div>

                    {/* <div className='right'>
                        <button className='Markascompleted_btn'>Mark as completed</button>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default ShowSubtaskCard