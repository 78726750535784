import React, { useEffect } from "react";
import PaymentDetailsCard from "../../Component/PaymentDetailsCard";
import SupplierCard from "../../Component/SupplierCard";
import LendersCard from "../../Component/LendersCard";
import { Link, useParams, useNavigate } from "react-router-dom";
import ppc from "../../../src/Images/rply_img.png";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import pdf from "../../Images/pdf.png";
import ProductServices from "../../services/ProductServices";
import HttpClient from "../../utils/HttpClient";
import moment from "moment";
import { toast } from "react-hot-toast";
import { Pagination } from "swiper/modules";

const Applicationdetails = () => {
  const [onclickshw, setOnclickshw] = useState(false);
  const { bid, post } = useParams();
  const [bidderData, setBidderData] = useState([]);
  const [projectData, setprojectData] = useState([]);

  const navigate = useNavigate();
  console.log("gjhgh", bidderData?.image);

  console.log("projectData", projectData);

  const handleClickShow = () => {
    setOnclickshw(!onclickshw);
    // setDefltshw(!defltshw)
  };

  const getsingleProject = () => {
    ProductServices.getsingleProject(post)
      .then((res) => {
        if (res && res.status) {
          setprojectData(res?.data);
          let Data = res?.data?.bidData;
          let filterData = Data.find((item) => item._id === bid);
          setBidderData(filterData);
        }
      })
      .catch((err) => {});
  };

  const handleAccept = async () => {
    let data = {
      acceptStatus: "Accepted",
    };

    const res = await ProductServices.statusChange(data, bid);
    if (res && res.status) {
      toast.success("status updated successfully");
      console.log("status updated successfully");
      getsingleProject();
      navigate("/");
    }
    console.log("datawa", res);
  };
  console.log("statussss", projectData?.bidData?.acceptStatus);

  const handlereject = async () => {
    let data = {
      acceptStatus: "Rejected",
    };
    const res = await ProductServices.statusChange(data, bid);
    if (res && res.status) {
      toast.error("status updated failed");
      console.log("status updated failed");
      navigate(-1);
    }
    console.log("datawa", res);
  };

  useEffect(() => {
    getsingleProject();
  }, []);

  return (
    <>
      <div className="main_wrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <div className="middlepartwrap">
                <div className="job_dtls" style={{ flexDirection: "column" }}>
                  <div className="posted_icon_txt_main">
                    <Link to="/" className="posted_icon">
                      <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                  </div>

                  <div className="job_image_description_main">
                    <Swiper
                      pagination={true}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {projectData?.image?.map((it, ind) => {
                        console.log("productimg", it);
                        return (
                          <SwiperSlide key={ind}>
                            <div className="projImg">
                              <img src={it} className="img-fluid" alt="" />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>

                    <div className="prjct_dscrptn">
                      <div className="pname">
                        <h5>{projectData?.name}</h5>
                        <div className="projct_hrs_attach_main">
                          <button class="Attachment_btn btn">
                            <i className="fa-regular fa-clock mr-2"></i>{" "}
                            {moment(projectData?.createdAt).fromNow()}
                          </button>
                          <button class="Attachment_btn btn">
                            <i className="fa-solid fa-paperclip mr-2"></i>
                            Attachment {bidderData?.image?.length}
                          </button>
                        </div>
                        <h4> Project Description: </h4>
                        <p>{projectData?.description}</p>
                      </div>

                      <div className="img_txt_amont_txt_btn_main">
                        <div className="img_txt_amont_txt_btn">
                          <div className="prfl_amnt_prjct">
                            <div class="profile mb-3">
                              <div class="profPic">
                                <img
                                  // src={HttpClient.IMAGE_URL + bidderData?.image}
                                  src={
                                    bidderData?.bidderData?.image ??
                                    "https://www.rotarybangkok.org/wp-content/uploads/2017/11/user-placeholder.d2a3ff8.pngu"
                                  }
                                  class="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div class="details">
                                <div class="d-flex align-items-center mb-1">
                                  <h5>{bidderData?.bidderData?.username}</h5>
                                  <p>
                                    Rating <span>(0.0)</span>
                                  </p>
                                </div>
                                <h6>
                                  {moment(bidderData?.createdAt).fromNow()}
                                </h6>
                              </div>
                            </div>
                            <div className="amnt_prjct">
                              <p>£ {bidderData?.bidPrice}</p>
                            </div>
                          </div>
                          <div className="prjct_qutation">
                            <h4>Cover letter</h4>
                            <p>{bidderData?.message}</p>
                          </div>

                          <div
                            className="attch_txt_pdf"
                            style={{
                              display:
                                bidderData?.image?.length > 0
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <h4>Attachment:</h4>
                            {/* <div className="bg_attch"> */}
                            {/* <img src={pdf} alt="" className="pdf_img" /> */}

                            {bidderData?.image?.map((item, ind) => {
                              console.log(item, "ghjkh");
                              return (
                                <div className="projImg">
                                  {/* <div> */}
                                  <a href={item}>
                                    <h4>
                                      <i class="fa fa-file-text"></i>
                                    </h4>
                                    Download
                                  </a>
                                  {/* <img
                                      src={item}
                                      className="img-fluid"
                                      alt=""
                                    /> */}
                                </div>
                              );
                            })}
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {projectData?.bidData?.acceptStatus === "Pending" ? (
                    <div className="accptd_rjct">
                      <button
                        className="vw_applctn acptd"
                        onClick={() => handleAccept()}
                      >
                        Accept
                      </button>
                      <button
                        className="vw_applctn rjct"
                        onClick={() => handlereject()}
                      >
                        Reject
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-12 pr-0">
              <div className="rightsidebar">
                <PaymentDetailsCard
                  Payment="Payment Received"
                  text="Lorem ipsum dolor sit amet consectetur"
                  amout="£30,000"
                  btnname="View Details"
                  bgcolor="#04B600"
                  Receivedbtn={true}
                />
                <PaymentDetailsCard
                  Payment="Payment Due"
                  text="Lorem ipsum dolor sit amet consectetur"
                  amout="£25,000"
                  btnname="View Details"
                  bgcolor="#7F6DF0"
                  Duebtn={true}
                />

                <SupplierCard />

                <LendersCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Applicationdetails;
