import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import Projecttrello from "../../Component/Projecttrello";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getTask } from '../../Redux/Reducer/TaskSlice';
import ProductServices from '../../services/ProductServices';
import { getProduct } from '../../Redux/Reducer/ProductSlice';

function Index() {
  const params = useParams();

  const search = window.location.search;
  const quaryParams = new URLSearchParams(search);
  const paymentSuccess = quaryParams.get('paymentSuccess');
  const taskId = quaryParams.get('taskId');

  const { taskData, loading } = useSelector(state => state.taskReducer)
  const { productData } = useSelector(state => state.productReducer)

  const [singleProjectData, setSingleProjectdata] = useState({})


  const dispatch = useDispatch();

  console.log("singleProjectData", singleProjectData?.name, productData)

  useEffect(() => {
    dispatch(getTask(params.id))
    dispatch(getProduct())
  }, [])

  useEffect(() => {
    let obj = productData.find((item) => item?._id === params.id);
    // console.log('obj',obj)
    // let myobj = JSON.parse(JSON.stringify(obj))
    setSingleProjectdata(obj)
  }, [productData.length])

  useEffect(() => {
    if (paymentSuccess == 'true') {
      payForTask_changeStatus()
    }
  }, [paymentSuccess])

  function payForTask_changeStatus() {
    let data = {
      completedStatus: 'Complete',
      projectId: params.id
    };

    // console.log('data', data, projectId);
    // return
    // setApprovedStatusLoser(true);
    ProductServices.requestStatusCahange(data, taskId)
      .then(res => {
        if (res && res.status) {
          dispatch(getTask(params.id))
        }
      })
      .catch(err => {
        console.log('err', err);
        // toast.show('Please Complete All Subtask',);
      });
  }

  return (
    <>
      <div className='main_wrap'>
        <div className='container-fluid'>
          <section className='Projectpage_area'>
            <div className='toppart'>
              <div className='head'>
                <h4>Task for: {singleProjectData?.name?.slice(0, 200)}</h4>
              </div>

              <ul className='actionpart'>
                <li className="nav-item dropdown">
                  {/* <Link
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="NewestDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Sort by:</span> Newest Project
                  </Link> */}
                  <div className="dropdown-menu" aria-labelledby="NewestDropdown">
                    <Link className="dropdown-item" href="#">
                      demo
                    </Link>
                    <Link className="dropdown-item" href="#">
                      demo
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  {/* <NavLink
                    className="nav-link"
                    to={'/chart-screen/' + params.id}
                  >
                    <i className="fa-solid fa-box mr-1" ></i> View Charts
                  </NavLink> */}

                  {/* <div className="dropdown-menu" aria-labelledby="BoardDropdown">
                    <Link className="dropdown-item" href="#">
                      demo
                    </Link>
                    <Link className="dropdown-item" href="#">
                      demo
                    </Link>
                  </div> */}
                </li>
                <li>
                  {/* <NavLink
                    className="nav-link"
                    to={'/chat-screen/'}
                  >
                    <i className="fa-solid fa-box mr-1" ></i> Chat
                  </NavLink> */}
                </li>
              </ul>

            </div>

            <Projecttrello projectId={params.id} taskData={taskData} singleProjectData={singleProjectData} />

          </section>
        </div>
      </div>
    </>
  )
}

export default Index