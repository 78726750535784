import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import ProductServices from "../../services/ProductServices"

const initialState = {
    subTaskData: [],
    loading: false
}

export const getSubTask = createAsyncThunk("sub_task_get_sub_task", async (id) => {
    const res = await ProductServices.fetchGetSubTask(id)
    console.log("ressubtTask", res)
    return res
})

const subTaskSlice = createSlice({
    name: "get_sub_task",
    initialState,
    reducers: {
        clearState: (state) => {
            state.subTaskData = [];
            state.loading = false;
        }
    },
    extraReducers: {
        [getSubTask.pending]: (state) => {
            state.loading = true;
        },
        [getSubTask.fulfilled]: (state, { payload }) => {
            if (payload && payload?.status) {
                state.subTaskData = payload?.data;
                state.loading = false;
            } else {
                state.loading = false;
            }
        },
        [getSubTask.rejected]: (state) => {
            state.loading = false;
        }
    }
})

export const { clearState } = subTaskSlice.actions;
export const subTtaskReducer = subTaskSlice.reducer;

