import React from 'react'
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import AuthServices from '../../services/AuthServices';
import ProductServices from '../../services/ProductServices';
import HttpClient from '../../utils/HttpClient';

function BidProjectModal({ biddeddata, closemodal, falsemodal }) {
    console.log('biddeddata', biddeddata)
    const [amount, setAmount] = useState('')
    const [later, setLater] = useState('')
    const [bidfiles, setFile] = useState([])
    const { userData } = useSelector((state) => state.User)
    const [imgerrflag, setimgerrflag] = useState(false);


    function getFileExtensionFromMimeType(mimeType) {

        const parts = mimeType.split('.').pop().toLowerCase();
        console.log("kkjjr", parts)
        const nextparts = mimeType.split("/");
        if (nextparts.length === 2) {
            if (nextparts[1] == "pdf")
                return nextparts[1];
        }
        return parts;
        // const parts = mimeType.split("/");
        // if (parts.length === 2) {
        //     return parts[1];
        // } else {
        //     // Handle invalid MIME type
        //     return null;
    }


    const handleImage = async (e) => {
        let file = e.target.files;
        let Arr = []
        for (let element of file) {
            // "docx", "ppt", "exl" "pdf"
            console.log("new error", element)
            if (

                (getFileExtensionFromMimeType(element.type) === "docx" ||
                    getFileExtensionFromMimeType(element.type) === "document" ||
                    getFileExtensionFromMimeType(element.type) === "ppt" ||
                    getFileExtensionFromMimeType(element.type) === "exl" ||
                    getFileExtensionFromMimeType(element.type) === "pdf" ||
                    getFileExtensionFromMimeType(element.type) === "xls" ||
                    getFileExtensionFromMimeType(element.type) === "ms-excel" ||
                    getFileExtensionFromMimeType(element.type) == "sheet") && (element.size < 4000000)


            ) {
                let data = new FormData();
                data.append("img", element);

                let res = await HttpClient.fileUplode("bid-image", "POST", data);

                if (res && res?.status) {
                    toast.success("Uploaded Successfully");
                    console.log("images", res);
                    Arr.push(res?.data)
                    setFile(Arr)
                } else {
                    toast.error(res?.message);
                }

                setimgerrflag(false)
            } else {
                setimgerrflag(true)
            }
        }
    };
    const bidProjecthandler = () => {
        if (amount == '') {
            toast.error('Please enter Amount')
            return
        }
        // if (later == '') {
        //     toast.error('Write Cover letter')
        //     return
        // }
        let data = {
            projectId: biddeddata._id,
            projectOwnerId: biddeddata.userData._id,
            bidderId: userData._id,
            bidPrice: Number(amount),
            message: later,
            image: bidfiles
        }
        ProductServices.bidProject(data)
            .then((res) => {
                // console.log("Bidding", res);
                if (res && res?.status) {
                    // toast.success('Project bidded Successfully')
                    toast.success(res.msg)
                    closemodal(false)
                    falsemodal()
                } else {
                    toast.error(res?.msg)
                }
            })
            .catch((err) => {

            })
    }
    return (
        <>
            <section className='BidProject_wrap'>
                <div className='BidProj_cnt'>
                    <div className='head'>
                        <h4>Bid Project</h4>
                    </div>

                    <div className='close_btn' onClick={() => closemodal(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>

                    <form>
                        <div className="form-group">
                            <label htmlFor="">Bid  Project amount <span>*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                id=""
                                aria-describedby="textHelp"
                                placeholder="Enter your bid Amount"
                                required={true}
                                value={amount}
                                onChange={(e) => {

                                    if (e.target.value == "" || e.target.value == '-') {
                                        setAmount("")
                                    } else {
                                        setAmount(Math.abs(e.target.value))
                                    }
                                }}

                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="">Write Cover letter </label>
                            <textarea
                                className="form-control"
                                id=""
                                rows={4}
                                defaultValue={""}
                                placeholder='Write something about the project'
                                value={later}
                                onChange={(e) => setLater(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="">Attachment File <span></span></label>
                            <span
                                style={{
                                    color: imgerrflag ? "red" : "green",
                                    display: "block",
                                    fontSize: "15px",
                                }}
                            >
                                (Only "docx","ppt","exl" "pdf" format accepted (Max Size 4mb))
                            </span>
                            <input
                                type="file"
                                className="form-control"
                                id=""
                                aria-describedby="textHelp"
                                placeholder="Attach file"
                                onChange={(e) => handleImage(e)}
                            />

                        </div>
                        {/* {
                            bidfiles?.map((it, ind) => {
                                return (
                                    <img src={it} style={{
                                        height: '80px',
                                        width: '80px',
                                        borderRadius: '5px'
                                    }}
                                        key={ind}
                                    />
                                )
                            })
                        } */}
                        <button type="button" className="btn BidNowbtn" onClick={() => bidProjecthandler()}>
                            Bid Now
                        </button>
                    </form>

                </div>
            </section>
        </>
    )
}

export default BidProjectModal