import React, { useState } from 'react'
import PaymentreceivedModal from './Modal/PaymentreceivedModal';
import PaymentdueModal from './Modal/PaymentdueModal';

function PaymentDetailsCard(props) {
    const [paymentreceived, setpaymentreceived] = useState(false);
    const [paymentdue,setPaymentdue]=useState(false);
    const paymentModal = () => {
        setpaymentreceived(true)
    }
    const dueModal = () =>{
        setPaymentdue(true);
    }
    const { Payment, text, amout, btnname, bgcolor, Receivedbtn = false, Duebtn = false, } = props;
    return (
        <>
            <section className='paymentDetail_Card' style={{ backgroundColor: bgcolor }}>
                <div className='headtag'>
                    <h4>{Payment}</h4>
                    <p>{text}</p>
                </div>
                <div className='amout'>
                    <h2>{amout}</h2>
                </div>
                <div className='ViewDetails_btn'>

                    {Receivedbtn &&
                        <button className='btn' onClick={paymentModal}>
                            {btnname}
                        </button>
                    }
                    {Duebtn &&
                        <button className='btn' onClick={dueModal}>
                            {btnname}
                        </button>
                    }
                </div>
            </section>

            {paymentreceived && <PaymentreceivedModal closeModal={setpaymentreceived}/>}

           {paymentdue && <PaymentdueModal closeModal={setPaymentdue}/>}
        </>
    )
}

export default PaymentDetailsCard