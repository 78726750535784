import React, { useEffect, useRef, useState } from "react";
import ConstructionProject from "../Component/ConstructionProject";
import CreatesubtaskModal from "../Component/Modal/CreatesubtaskModal";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ProjectUpdateModal from "../Component/Modal/ProjectUpdateModal";
import CreatetaskModal from "../Component/Modal/CreatetaskModal";
import TaskDetail from "../View/Project/TaskDetail";
import { useSelector } from "react-redux";
import EditSubTaskModal from "./Modal/EditSubTaskModal";
import { useDispatch } from "react-redux";
import { deleteTask, getTask, clearState } from "../Redux/Reducer/TaskSlice";
import ProductServices from "../services/ProductServices";
import toast from "react-hot-toast";
import { getSubTask } from "../Redux/Reducer/SubTaskSlice";
import { useSearchParams } from "react-router-dom";
import PaymentSucsessModel from "./Modal/PaymentSucsessModel";

const coloumnsForProjects = {

  [uuidv4()]: {

    name: "Register",
    items: [

    ],
  },
  [uuidv4()]: {

    name: "In-Progress",
    items: [

    ],
  },
  [uuidv4()]: {
    name: "Make Decision",
    items: [

    ],
  },
  [uuidv4()]: {
    name: "Make Payment ",
    items: [

    ],
  },

  [uuidv4()]: {
    name: "Completed",
    items: [

    ],
  },
};

function Projecttrello({ projectId, singleProjectData }) {

  const [searchParams, setSearchParams] = useSearchParams();


  const dispatch = useDispatch()
  const { taskData, loading, refress, count } = useSelector(state => state.taskReducer)
  const { userData } = useSelector((state) => state.User);
  console.log("jhfgj", singleProjectData?.userData?._id, userData?._id)
  const [coloumns, setColomns] = useState(coloumnsForProjects);
  const [taskId, setTaskId] = useState("");
  const [singleTaskData, setSingleTaskData] = useState();
  const [subTaskId, setSubTaskId] = useState("")
  const [PaymentSucsessModelflaf, setPaymentSucsessModelflag] = useState(false)
  const [quaryparam, setquaryparam] = useState("")
  const [projectCompleteflag, setProjectcompleteflag] = useState(false)
  const [glags, setflag] = useState(false)

  const [refreshFlag, setrefreshFlag] = useState(false)

  // const [singleProjectData, setSingleProjectdata] = useState({})
  // useEffect(() => {
  //   let obj = productData.find((item) => item?._id === params.id);
  //   // console.log('obj',obj)
  //   // let myobj = JSON.parse(JSON.stringify(obj))
  //   setSingleProjectdata(obj)
  // }, [productData.length])
  // const [count, setcount] = useState(false)
  console.log("coloumnsee", coloumns)


  const previousTaskData = useRef(taskData);


  const checkAlltastPaidorNot = (taskData) => {
    console.log("rjfhjkh", taskData)
    for (let i = 0; i < taskData.length; i++) {
      if (taskData[i].completedStatus != "Complete") {
        return false
      }

    }
    return true;
  }



  // projectComplete
  function completeproject() {
    if (
      checkAlltastPaidorNot(taskData)

    ) {
      // Alert.alert('complete');


      ProductServices.updateprojectStatus(
        { completeStatus: true },
        // route.params.itemId
        projectId
      )
        .then(res => {

          if (res && res.status) {
            setProjectcompleteflag(res.data.completeStatus)
            toast.success("Project completed")
          }
        })
        .catch(err => {

        });
    } else {
      toast.error('Please Complete All Task');
    }
  }



  //if not sufficient Balance then add balance in wallet
  console.log("taskData", taskData)

  const [ApprovedStatusLoser, setApprovedStatusLoser] = useState(false);
  const [addwalletAmount, setaddwalletAmount] = useState("");
  //AddWalletBalance
  const AddWalletBalance = async () => {
    if (!addwalletAmount) {
      toast.error("please enter some Amount");
      return;
    }

    const res = await ProductServices.addbalancewallet({
      balance: Number(addwalletAmount),
      details: "Amount Credited",
    });

    if (res && res.status) {
      setaddwalletAmount("");
      // setWallet(false);
      // get_walletBalance();
      // AllBalanceHistory();
    }
  };


  useEffect(() => {
    const qr = searchParams.get("paymentSuccess")
    const payedamount = searchParams.get("amount");
    setaddwalletAmount(payedamount)
    setquaryparam(qr);
    console.log(qr, typeof (qr), "qr")

  }, [])
  useEffect(() => {
    if (count <= 0 && quaryparam == "true") {



      setPaymentSucsessModelflag(true)
      AddWalletBalance()
    }
  }, [quaryparam])



  // modal A
  const [modalA, setModalA] = useState(false);
  const ProjectupdateModal = (data) => {
    // setModalA(!modalA);

    setTaskId(data?._id);
  };

  console.log("jjbklj", taskId)
  useEffect(() => {
    if (taskId) {
      setModalA(!modalA);

    }
  }, [taskId])

  // Modal B
  const [modalB, setModalB] = useState(false);
  const openSubTaskModal = () => {
    setModalA(false);
    setModalB(!modalB);
  };

  // modal D
  const [modalD, setModalD] = useState(false);
  const createtaskmodal = () => {
    setModalD(!modalD);
  };

  // modal E
  const [modalE, setModalE] = useState(false);
  const editSubTaskModal = () => {
    setModalE(!modalE);
  };

  console.log("modalE", modalE)


  const onDragEnd = (result, columns, setColumns) => {
    console.log("resultColumn", result);


    let findOtherModuleId = Object.keys(columns);
    if (!result.destination) return;
    const { source, destination } = result;

    console.log('source.droppableId', source.droppableId, destination.droppableId)
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      // // console.log("se", destColumn);
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];

      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };


  // useEffect(() => {

  //   if (taskData.length > 0) {
  //     let initialFetch = true;
  //     const updatedColumns = { ...coloumns };
  //     taskData.forEach((updatedTask) => {
  //       console.log('updatedTask', updatedTask)
  //       // Find the column containing the updated task
  //       const updatedindex = Object.keys(updatedColumns).find(columnId =>
  //         updatedColumns[columnId].items.some((task) => task._id === updatedTask._id))
  //       console.log('updatedindex', updatedindex)
  //       const updatedColumnId = Object.keys(updatedColumns).find((columnId) =>
  //         updatedColumns[columnId].items.some((task) => task._id === updatedTask._id)
  //       );

  //       if (updatedColumnId) {
  //         // Update the task within its current column
  //         const updatedItems = updatedColumns[updatedColumnId].items.map((task) =>
  //           task._id === updatedTask._id ? updatedTask : task
  //         );

  //         updatedColumns[updatedColumnId].items = updatedItems;
  //         initialFetch = false
  //       }
  //     });
  //     console.log('updatedColumns', updatedColumns)
  //     setColomns(updatedColumns);

  //     if (initialFetch) {
  //       const indexChanged = taskData.findIndex(
  //         (item, index) => item !== previousTaskData.current[index]
  //       );
  //       console.log('indexChanged', indexChanged)
  //       if (indexChanged !== -1) {
  //         let find = Object.keys(coloumns);
  //         const todoUpdate = coloumns[find[indexChanged]];
  //         todoUpdate.items = taskData;
  //         console.log('find', find)
  //         setColomns({
  //           ...coloumns,
  //           [find[indexChanged]]: {
  //             ...todoUpdate,
  //           },
  //         });
  //       }
  //     }
  //   }
  // }, [taskData])

  useEffect(() => {
    dispatch(getTask(projectId))
    setColomns(coloumnsForProjects)
    return (() => {

      dispatch(clearState())
      setColomns(coloumnsForProjects)
    })

  }, [])


  useEffect(() => {

    updateColumnSection("Register", taskData.filter(it => it.completedStatus == 'Pending'))
    updateColumnSection("In-Progress", taskData.filter(it => it.completedStatus == 'ToDo'))
    updateColumnSection("Make Decision", taskData.filter(it => it.completedStatus == 'Request'))
    updateColumnSection("Make Payment ", taskData.filter(it => it.completedStatus == 'Approved'))
    updateColumnSection("Completed", taskData.filter(it => it.completedStatus == 'Complete'))
    setrefreshFlag(s => !s)
  }, [refress,])




  // useEffect(() => {
  //   if (taskData.length > 0) {
  //     const updatedColumns = { ...coloumns };
  //     taskData.forEach((updatedTask) => {
  //       console.log('updatedTask',updatedTask)
  //       // Find the column containing the updated task
  //       const updatedindex = Object.keys(updatedColumns).find(columnId=>updatedColumns[columnId].items.some((task)=>task._id ===updatedTask._id))
  //       console.log('updatedindex',updatedindex)
  //       const updatedColumnId = Object.keys(updatedColumns).find((columnId) =>
  //         updatedColumns[columnId].items.some((task) => task._id === updatedTask._id)
  //       );

  //       if (updatedColumnId) {
  //         // Update the task within its current column
  //         const updatedItems = updatedColumns[updatedColumnId].items.map((task) =>
  //           task._id === updatedTask._id ? updatedTask : task
  //         );

  //         updatedColumns[updatedColumnId].items = updatedItems;

  //       } 
  //     });
  //     console.log('updatedColumns', updatedColumns)
  //     setColomns(updatedColumns); // Use the correct state variable name

  //   }

  // }, [taskData]);

  // getting single data

  //



  //..for button click events ....
  const updateColumnSection = (sectionName, newItem) => {
    const tempobj = coloumnsForProjects
    setColomns(coloumnsForProjects)
    const sectionId = Object.keys(tempobj).find(
      (key) => tempobj[key].name === sectionName
    );
    if (sectionId) {
      tempobj[sectionId].items = newItem;
    }
    console.log("jjfh", tempobj)

    return tempobj
  };


  function approvedForChange(type) {
    let data = {
      // projectId: projectDetails.projectId,
      projectId: singleTaskData.projectId,
      // completedStatus: 'Request',
      completedStatus: type,


      // projectDetails.projectData.name
    };
    console.log('data', data, projectId);

    ProductServices.requestStatusCahange(data, singleTaskData._id)
      .then(res => {
        setApprovedStatusLoser(false);
        console.log('res s ss ', JSON.stringify(res));
        if (res && res.status) {
          dispatch(getTask(projectId))
          setModalA(false)
          // dispatch(clearState())
          // alert("jj")
          // NavigationService.back();
        }
      })
      .catch(err => {
        setApprovedStatusLoser(false);
        console.log('err', err);
        toast.error('Please Complete All Subtask');
      });
  }

  // contuctor Approve function
  function ContuctorApprovePrice() {

    let data =
    {
      taskId: singleTaskData?._id,
      deductionPercentage: 0,
      taskBudget: singleTaskData?.taskBudget,
      taskName: "Confirm Payment" // params _id for user with deducting balance

    }
      ;


    ProductServices.sendPaymentConfirm(data, singleProjectData?.userData?._id)
      .then(res => {
        setApprovedStatusLoser(false);
        console.log('res s ss ', JSON.stringify(res));
        if (res && res.status) {
          // dispatch(getTask(projectId))
          toast.success(" Payment Send Succsessfuly")
          setModalA(false)

        }
      })
      .catch(err => {
        setApprovedStatusLoser(false);
        console.log('err', err);
        toast.error('Please Complete All Subtask');
      });
  }


  useEffect(() => {
    // dispatch(getTask(projectId))
    if (taskId) {
      let singleData = taskData.find((item) => item._id === taskId);
      setSingleTaskData(singleData)
    }


  }, [taskData, taskId])
  useEffect(() => {
    dispatch(getTask(projectId))

  }, [modalD])


  console.log("kkgjhklj", coloumns)

  return (
    <>
      <section className="Projecttrello_area">
        <DragDropContext
          onDragEnd={(result) => {
            onDragEnd(result, coloumns, setColomns);
          }}
        >
          {Object.entries(coloumns).map(([id, column], i) => {
            console.log("jjjtt", id, i, column)
            return (
              <Droppable droppableId={id}>

                {(provided, snapshot) => {
                  return (
                    <div
                      className="cardbox"
                      {...provided?.droppableProps}
                      ref={provided?.innerRef}
                    >
                      <div className="cardhead">
                        <div className="name">
                          <h4>{column.name}</h4>
                        </div>
                        {/* <div className="cardcount">
                          <p>2/2</p>
                        </div> */}
                      </div>

                      {column.items.map((item, i) => {
                        console.log("itemJJ", item)
                        return (
                          <Draggable key={i} draggableId={item._id} index={i}>
                            {(provided, snapshot) => {
                              return (
                                <div
                                  ref={provided?.innerRef}
                                  {...provided?.draggableProps}
                                  {...provided?.dragHandleProps}
                                >
                                  <div className="handle" >
                                    <div onClick={() => ProjectupdateModal(item)}>
                                      {/* <ConstructionProject item={item}/> */}
                                      <TaskDetail item={item} />
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}

                      {provided.placeholder}


                      {
                        i === 0 && <div className="CreateTask_btn">
                          <button className="btn" onClick={createtaskmodal}>
                            <i className="fa-solid fa-plus mr-2"></i>
                            Create Task
                          </button>
                        </div>

                      }

                      {/* {i == 2 && <div className="CreateTask_btn">
                        <button className="btn">Request for Payment</button>
                      </div>} */}

                      {i == 4 &&
                        <div style={{ display: (singleProjectData?.userData?._id == userData?._id) ? "block" : "none" }}>
                          <div
                            className={"CreateTask_btn"}>
                            {(singleProjectData?.completeStatus == true || projectCompleteflag)
                              ?
                              <div className="CompletedTask_btn"
                                onClick={() => toast.success('Project Completed')}
                              // onClick={() => { completeproject() }}
                              >{singleProjectData?.completeStatus == true ? "Project Already Completed" : "Project Complete"}
                              </div> :
                              <button className="btn btn-success" style={{ backgroundColor: 'green' }}

                                onClick={() => { completeproject() }}
                              >{singleProjectData?.completeStatus == true ? "Project Already Completed" : "Project Complete"}
                              </button>



                            }
                          </div>
                        </div>}
                    </div>
                  );
                }}
              </Droppable>
            );

          })}

        </DragDropContext>

      </section>


      {/* create task */}
      {modalD && <CreatetaskModal
        closeModal={setModalD}
        projectId={projectId}
        singleProjectData={singleProjectData}
      />}


      {/*  update task*/}
      {modalA && (
        <ProjectUpdateModal
          closeModal={setModalA}
          openSubTaskModal={() => {
            openSubTaskModal();
          }}
          approvedForChange={approvedForChange}
          singleTaskData={singleTaskData}
          projectId={projectId}
          singleProjectData={singleProjectData}
          setModalE={setModalE}
          setTaskId={setTaskId}
          setSubTaskId={setSubTaskId}
          ContuctorApprovePrice={ContuctorApprovePrice}
          setPaymentSucsessModelflag={setPaymentSucsessModelflag}
        />
      )}

      {/* subtask*/}
      {modalB && <CreatesubtaskModal
        closeModal={setModalB}
        singleTaskData={singleTaskData}
        projectId={projectId}
        taskId={taskId}
        singleProjectData={singleProjectData}
        setModalA={setModalA}
      />}


      {/* edit subTask */}
      {modalE && <EditSubTaskModal
        closeModal={setModalE}
        singleTaskData={singleTaskData}
        projectId={projectId}
        taskId={taskId}
        singleProjectData={singleProjectData}
        setModalA={setModalA}
        subTaskId={subTaskId}
      />}
      {/* //paymentsucsess Model */}
      {PaymentSucsessModelflaf && <PaymentSucsessModel
        iamFrom={"taskPayment"}
        setPaymentSucsessModelflag={setPaymentSucsessModelflag}
        projectId={projectId}
        setquaryparam={setquaryparam} />}

    </>
  );
}

export default Projecttrello;
