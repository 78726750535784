import React, { useState } from 'react'
import ImageDownloader from '../../View/ImageDownloader'

function ViewVideo({ videoUrl }) {
    console.log(videoUrl, "jkjk")
    const [isfulldownload, setisfulldownload] = useState(false)
    return (
        <>
            <video style={isfulldownload ? { position: "absolute", zIndex: "11" } : {}} width="220" height="140" controls>


                <source src={videoUrl} type="video/mp4" />
            </video>
            <ImageDownloader imageUrl={videoUrl} isfulldownload={isfulldownload} setisfulldownload={setisfulldownload} />
        </>
    )
}

export default ViewVideo