import React, { useState } from "react";
import atmImg from "../../Images/atm-card.jpg";
import { useNavigate } from "react-router-dom";

// Utils
import { Publishablekey } from "../../utils/StripeKey";

// Helpers
import { toast } from "react-hot-toast";

// API
import ProductServices from "../../services/ProductServices";
import PaymentSucsessModel from "./PaymentSucsessModel";

let initCardValue = {
  card_number: "",
  expiry_date_month: "",
  expiry_date_year: "",
  cvv: "",
};

let createErrors = {
  card_number: "",
  expiry_date_month: "",
  expiry_date_year: "",
  cvv: "",
};

function PaymentCardModal(props) {
  const { openModal, cardData, stripe_customer_Id, stripeData } = props;

  const [formattedInput, setFormattedInput] = useState(initCardValue);
  const [errors, seterrors] = useState(createErrors);
  const [SuccbidModal, setSuccbidModal] = useState(false);

  const openModalfunc = () => {
    setSuccbidModal(!SuccbidModal);
  };
  const gotoHome = () => {
    setSuccbidModal(!SuccbidModal);
    navigate("/");
  };

  const navigate = useNavigate();

  console.log("stripeData", stripeData);

  const handleChange = (event) => {
    console.log(event.target.name, event.target.value);
    // // Remove spaces and limit input to 16 characters
    // const inputWithoutSpaces = e.target.value.replace(/\s/g, "").slice(0, 16);

    // // Add a space after every 4 characters
    // const formattedValue = inputWithoutSpaces
    //   .replace(/\s/g, "")
    //   .replace(/(.{4})/g, "$1 ")
    //   .trim() // Remove leading/trailing spaces
    //   .slice(0, 16); // Limit to 16 characters

    // Update the state with the formatted input
    setFormattedInput({
      ...formattedInput,
      [event.target.name]: event.target.value,
    });
  };

  // console.log("Month", formattedInput.expiry_date_month);
  // console.log("Year", formattedInput.expiry_date_year);
  const getCreditCardToken = async (creditCardData) => {
    const card = {
      "card[number]": creditCardData.card_number,
      "card[exp_month]": creditCardData.expiry_date_month,
      "card[exp_year]": creditCardData.expiry_date_year,
      "card[cvc]": creditCardData.cvv,
    };
    // console.log("card", card)
    try {
      const response = await fetch("https://api.stripe.com/v1/tokens", {
        headers: {
          // Use the correct MIME type for your server
          Accept: "application/json",
          // Use the correct Content Type to send data to Stripe
          "Content-Type": "application/x-www-form-urlencoded",
          // Use the Stripe publishable key as Bearer
          Authorization: `Bearer ${Publishablekey}`,
        },
        // Use a proper HTTP method
        method: "post",
        // Format the credit card data to a string of key-value pairs
        // divided by &
        body: Object.keys(card)
          .map((key) => key + "=" + card[key])
          .join("&"),
      });
      return await response.json();
    } catch (error) {
      return error;
    }
  };

  const handleValidation = () => {
    const { card_number, expiry_date_month, expiry_date_year, cvv } =
      formattedInput;

    if (card_number == "") {
      seterrors({ ...createErrors, card_number: "Please enter card number" });
      return false;
    }
    if ((expiry_date_month + expiry_date_year).length == 0) {
      seterrors({
        ...createErrors,
        expiry_date_month: "Please enter expiry date in MMYYY format.",
      });
      return false;
    }
    if (card_number == "") {
      seterrors({ ...createErrors, cvv: "Please enter cvv" });
      return false;
    }

    return true;
  };

  const paymentFun = () => {
    if (handleValidation()) {
      // console.log(formattedInput);
      getCreditCardToken(formattedInput)
        .then((cardToken) => {
          // console.log("cardToken", cardToken);
          console.log(stripeData);

          const cardInfo = {
            type: "card",
            card: {
              token: cardToken.id,
            },
            items: [
              {
                price: stripeData.stripePriceId,
              },
            ],
          };

          ProductServices.CreateSubscription(cardInfo, stripe_customer_Id).then(
            (subscription) => {
              if (subscription && subscription.status) {
                ProductServices.Makepayment({
                  planId: stripeData._id,
                  planBuyPrice: stripeData.price,
                  limitAvailable: stripeData.bidLimit,
                  skillLimit: stripeData.skillLimit,
                  deductionPercentage: stripeData.deductionPercentage,
                  userName: null,
                  cvc: formattedInput.cvv,
                  cardExpiry: formattedInput.expiry_date_year,
                  cardNumber: formattedInput.card_number,
                  // "cardType": formattedInput.,
                  amount: stripeData.price * 100,
                  customer: stripe_customer_Id,
                  automatic_payment_methods: {
                    enabled: true,
                  },
                  return_url: "https://www.ankit123.payment.com",
                })
                  .then((responce) => {
                    console.log("responce", responce);
                    // setLoderStatus(false)
                    if (responce && responce.status) {
                      toast.success("Subscription buy successful", {
                        duration: 3,
                      });
                      openModalfunc();
                      // navigate("/");
                    } else {
                      // openModalfunc();
                    }
                  })
                  .catch((err) => {
                    // setLoderStatus(false)

                    console.log("err", err);
                  });
                //   ProductServices.buyTrailplan({
                //     planId: stripeData._id,
                //     planBuyPrice: stripeData.price,
                //     limitAvailable: stripeData.bidLimit,
                //     skillLimit: stripeData.skillLimit,
                //     deductionPercentage: stripeData.deductionPercentage,
                //     userName: null,
                //     cvc: formattedInput.cvv,
                //     cardExpiry: formattedInput.expiry_date_month+"/"+formattedInput.expiry_date_year,
                //     cardNumber: formattedInput.card_number,
                //     cardType: "card",
                //   })
                //   .then(res => {
                //     // setLoderStatus(false)
                //     console.log("trial plan buy", res);
                //     if (res && res.status) {
                //         // subscription buy successful
                //         // Toast.show('Subscription buy successful', Toast.SHORT);
                //         // NavigationService.back()
                //         ProductServices.Makepayment({

                //             "amount": stripeData.price,
                //             "customer": stripe_customer_Id,
                //             "automatic_payment_methods": {
                //                 "enabled": true
                //             },
                //             "return_url": "https://www.ankit123.payment.com"
                //         })
                //             .then(responce => {
                //                 console.log("responce", responce);
                //                 // setLoderStatus(false)
                //                 if (responce && responce.status) {
                //                     toast.success('Subscription buy successful', { duration: 3 });
                //                     navigate('/');
                //                 }
                //             })
                //             .catch(err => {
                //                 // setLoderStatus(false)
                //                 console.log("err", err);
                //             })
                //     } else {
                //       toast.error(res.msg);
                //     }
                // });
              }
            }
          );
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <>
      <section className="subscriptModal_wrap">
        <div className="SubscriptModal_body">
          <button className="close_btn" onClick={() => openModal(false)}>
            <i className="fa-solid fa-xmark"></i>
          </button>

          <h4 className="heading">Enter Your Card Number</h4>

          <div className="content_wrap">
            <figure className="atmcard_img">
              <img src={atmImg} className="img-fluid" alt="atm...." />
            </figure>

            <form>
              <div className="form-group">
                <label htmlFor="">Card Number</label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  placeholder="xxxx xxxx xxxx xxxx"
                  name="card_number"
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    // Allow only backspace and numbers
                    const regex = /^[0-9\b]*$/; // /^[0-9/s]*$/;

                    if (!regex.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                    if (
                      formattedInput.card_number.length >= 16 &&
                      e.key !== "Backspace"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="text-danger">{errors.card_number}</span>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="">Expiry Date</label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    aria-describedby=""
                    placeholder="MMYYYY"
                    // value={formattedInput.expiry_date}
                    onChange={(e) => {
                      if (Number(e.target.value.slice(0, 2)) > 12) {
                        // console.log(true);
                        seterrors((prev) => {
                          let update = JSON.parse(JSON.stringify(prev));
                          update.expiry_date_month = "Please put proper month.";

                          return update;
                        });
                      } else {
                        seterrors((prev) => {
                          let update = JSON.parse(JSON.stringify(prev));
                          update.expiry_date_month = "";

                          return update;
                        });
                        setFormattedInput((prev) => {
                          let update = JSON.parse(JSON.stringify(prev));
                          if (e.target.value.length < 6) {
                            update.expiry_date_month =
                              "0" + e.target.value.slice(0, 2);
                            update.expiry_date_year = e.target.value.slice(2);
                          } else {
                            update.expiry_date_month = e.target.value.slice(
                              0,
                              2
                            );
                            update.expiry_date_year = e.target.value.slice(2);
                          }

                          return update;
                        });
                      }
                    }}
                    onKeyDown={(e) => {
                      // Allow only backspace and numbers
                      const regex = /^[0-9\b]*$/; // /^[0-9/s]*$/;

                      if (!regex.test(e.key) && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                      // if (errors.expiry_date.length > 0 && !/\b/g.test(e.key)) {
                      //   e.preventDefault();
                      // }
                      if (
                        String(
                          formattedInput.expiry_date_month +
                            formattedInput.expiry_date_year
                        ).length == 7 &&
                        e.key !== "Backspace"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">
                    {errors.expiry_date_month}
                  </span>
                  <span className="text-danger">{errors.expiry_date_year}</span>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="">CVV Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id=""
                    placeholder="CVV Number"
                    name="cvv"
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      // Allow only backspace and numbers
                      const regex = /^[0-9\b]*$/; // /^[0-9/s]*$/;

                      if (!regex.test(e.key) && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                      if (
                        formattedInput.cvv.length >= 3 &&
                        e.key !== "Backspace"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">{errors.cvv}</span>
                </div>
              </div>
            </form>

            <div className="paymaent_wrapbtn">
              <button
                type="submit"
                className="Cancel_btn"
                onClick={() => openModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="pay_btn"
                onClick={() => {
                  paymentFun();
                }}
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      </section>
      {SuccbidModal && <PaymentSucsessModel closeModal={gotoHome} />}
    </>
  );
}

export default PaymentCardModal;
