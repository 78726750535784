import React, { useState } from "react";
import otpimg from "../Images/otp.png";
import "./login.css";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

const OtpVerification = ({ otp, flag, seteterpassflag }) => {
  console.log("verification_otp", otp);
  const initValue = {
    email: "",
    password: "",
  };
  const [formValue, setFormValue] = useState(initValue);

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setFormValue((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const [myotp, setOtp] = useState("");
  const [otpverifyFlag, setotpverifyFlag] = useState(false);

  console.log("otpverifyFlag", otpverifyFlag);
  const navigate = useNavigate();

  // const handleInputChange = (event, setInput) => {
  //   const inputValue = event.target.value;
  //   setInput(inputValue);
  // };

  const combineInputs = () => {
    const combinedValue = myotp;
    // console.log("combinedValue", combinedValue);
    if (combinedValue == otp) {
      //   console.log("combined", combinedValue, otp);
      toast.success("OTP verified Successfully");
      // setotpverifyFlag(true);

      if (flag === "forgotpass") {
        // navigate("/account-creation")
        seteterpassflag(true);
      } else {
        navigate("/account-creation", {
          state: { userData: {}, google: false, emailId: "" },
        });
      }
    } else {
      toast.error("Wrong OTP");
    }
  };

  const handleVerify = () => {
    combineInputs();
  };

  const handleResendOtp = () => {};

  return (
    <div className="otp-main">
      <div className="otp-center">
        <div className="mobile-icon">
          <img id="otp-img" src={otpimg} alt="otp" />
        </div>
        <div className="otp-text">
          <h3> OTP VERIFICATION</h3>
          <p>
            Enter the <span>OTP</span> Send to Your Email
          </p>
        </div>
        <OtpInput
          value={myotp}
          onChange={setOtp}
          numInputs={4}
          renderSeparator={<span>--</span>}
          renderInput={(props) => <input {...props} />}
          containerStyle={{
            alignItems: "center",
            justifyContent: "center",
            margin: "10px 0px",
          }}
          inputStyle={{ height: "50px", width: "50px" }}
        />
        <button
          onClick={() => {
            handleVerify();
          }}
          className="verify-btn"
        >
          Verify
        </button>
        {/* <h5
            className="resend-text"
            onClick={() => {
              handleResendOtp();
            }}
          >
            Resend OTP
          </h5> */}
      </div>
    </div>
  );
};

export default OtpVerification;
