import React, { useEffect, useRef, useState } from "react";
import AuthServices from "../../services/AuthServices";
import { MultiSelect } from "react-multi-select-component";
import toast from "react-hot-toast";
import "./AddProject.css";
import HttpClient from "../../utils/HttpClient";
import { useNavigate } from "react-router-dom";
import FindMapAddress from "./FindMapAddress";
import { AuthCtx } from "../../context/CreateContext";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getAllProduct, getProduct } from "../../Redux/Reducer/ProductSlice";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Select from "react-select";
import { Oval } from "react-loader-spinner";

const IMAGE_URL = "http://13.127.222.180:4001/";

const options = [
  { label: "Soundproofing", value: "Soundproofing" },
  {
    label: "Air Conditioning Installation",
    value: "Air Conditioning Installation",
  },
  { label: "Door Installation", value: "Door Installation" },
  { label: "Garage Installation", value: "Garage Installation" },
  { label: "Garage Door Repairs", value: "Garage Door Repairs" },
  { label: "Kitchen Renovation", value: "Kitchen Renovation" },
  { label: "Bathroom Remodeling", value: "Bathroom Remodeling" },
  { label: "Installing New Flooring", value: "Installing New Flooring" },
  {
    label: "Painting (Interior/Exterior)",
    value: "Painting (Interior/Exterior)",
  },
  { label: "Replacing Windows or Doors", value: "Replacing Windows or Doors" },
  { label: "Roof Repair or Replacement", value: "Roof Repair or Replacement" },
  { label: "Double Glazing", value: "Double Glazing" },
  { label: "Adding Insulation", value: "Adding Insulation" },
  {
    label: "Upgrading Electrical or Plumbing Systems",
    value: "Upgrading Electrical or Plumbing Systems",
  },
  { label: "Building a New Room", value: "Building a New Room" },
  { label: "Loft Conversions", value: "Loft Conversions" },
  { label: "Home Office and Annex", value: "Home Office and Annex" },
  {
    label: "Adding a Sunroom or Conservatory",
    value: "Adding a Sunroom or Conservatory",
  },
  { label: "Expanding the Garage", value: "Expanding the Garage" },
  { label: "Building a Deck or Patio", value: "Building a Deck or Patio" },
  { label: "Constructing a New Porch", value: "Constructing a New Porch" },
  { label: "Extending the Living Space", value: "Extending the Living Space" },
  {
    label: "Building an Additional Floor",
    value: "Building an Additional Floor",
  },
  { label: "Constructing a New House", value: "Constructing a New House" },
  { label: "Lawn Care and Maintenance", value: "Lawn Care and Maintenance" },
  { label: "Garden Cleanup", value: "Garden Cleanup" },
  { label: "Shed Creation", value: "Shed Creation" },
  { label: "Outdoor Lighting", value: "Outdoor Lighting" },
  {
    label: "Irrigation and Sprinkler Systems",
    value: "Irrigation and Sprinkler Systems",
  },
  { label: "other", value: "other" },
];

const loandata = [
  // {
  //   label: "--Select--",
  //   value: "",
  // },
  {
    label: "Bridging Loan",
    value: "Bridging Loan",
  },
  {
    label: "Development Finance",
    value: "Development Finance",
  },
  {
    label: "Rent to Rent Mortgage",
    value: "Rent to Rent Mortgage",
  },
  {
    label: "Buy to Let mortgage",
    value: "Buy to Let mortgage",
  },
  {
    label: "HMO Mortgage",
    value: "HMO Mortgage",
  },
  {
    label: "Others",
    value: "Others",
  },
];

const AddProjects = (props) => {
  const { closeModal } = props;
  const dispatch = useDispatch();
  const initial = {
    userId: "",
    description: "",
    categories: "",
    othersCategories: "",
    address: "",
    postalCode: "",
    startDate: "",
    endDate: "",
    location: [
      {
        latitude: 1254.12,
        longitude: 1254.12,
      },
    ],
    startCost: "",
    endCost: "",
    image: [],
  };
  const [projectData, setProjectData] = useState(initial);
  const [error, setError] = useState(initial);
  const [userData, setUserData] = useState();
  const [userLocation, setUserLocation] = useState(false);
  const [userLatLng, setUserLatLng] = useState({});
  console.log("kkwwrrt", userLatLng);
  const [images, setImages] = useState([
    "https://buildbetastorage.blob.core.windows.net/backend/image/projectimg1699955607671?sp=r&st=2023-11-13T09:51:53Z&se=2033-11-01T17:51:53Z&spr=https&sv=2022-11-02&sr=c&sig=uueWf3lxzBIyTsPNhc%2B0pWFMyBa2kTjiRKFaBP8No2c%3D",
  ]);

  const [isStripeUrlopen, setisStripeUrlopen] = useState(false);

  const [selected, setSelected] = useState([]);

  const { address, setAddress } = AuthCtx();
  // const [postalCode, setPostalCode] = useState(
  //   address.zipcode ? address.zipcode : ""
  // );

  const [flag, setflag] = useState(false);
  const [flag1, setflag1] = useState(false);
  const [imageflag, setimageflag] = useState(false);
  console.log("jhjkh", address);

  const [showDate, setShowDate] = useState(true);
  const [freelencer, setFreelencer] = useState(false);
  console.log("freelencer", freelencer);
  const navigate = useNavigate();
  const [fundType, setFundType] = useState("");
  const [fundAmount, setFundAmount] = useState("");
  const [errFlag, seterrFlag] = useState(false);
  const [startDateteFlag, sestartDateteFlag] = useState(false);
  const [endDateFlag, setendDateFlag] = useState(false);
  const [defaultAddressFlag, setdefaultAddressFlag] = useState(false);

  const [first_destination, setfirst_destination] = useState("");
  const [first_PostalCode, setfirst_PostalCode] = useState("");
  const [defaultaddress, setdefaultAddress] = useState("");
  const [defaultpincode, setdefaultpincode] = useState("");

  console.log("jjj2ej", address, first_PostalCode);

  const [myaddress, setMyAddress] = useState("");

  // function removeFirstSegment(inputString) {
  //   const commaIndex = inputString?.indexOf(",");

  //   if (commaIndex !== -1) {
  //     // If a comma is found, extract the substring after the first comma

  //     console.log(inputString?.slice(commaIndex + 1).trim(), "gdggdhf");
  //     return inputString?.slice(commaIndex + 1).trim();
  //   }

  //   // If no comma is found, return the original string
  //   return inputString;
  // }

  function removeFirstSegment(inputString) {
    const commaIndex = inputString?.indexOf(",");

    if (commaIndex !== -1) {
      // If a comma is found, extract the substring after the first comma
      return inputString?.slice(commaIndex + 1).trim();
    }

    // If no comma is found or the string is empty, return an empty string
    return "";
  }

  const handleChangeAddress = (address) => {
    setMyAddress(address);
  };
  useEffect(() => {
    handleChangeAddress(removeFirstSegment(address.area));
  }, [address.area]);
  console.log("addrss", address, myaddress);

  const handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      const reszipcode = results[0]?.address_components?.filter((ele) =>
        ele.types.some((type) => type === "postal_code")
      );
      // setProjectData({ ...projectData, postalCode: reszipcode[0].long_name });
      // setAddress({ ...address, zipcode: reszipcode[0]?.long_name });
      if (reszipcode[0]?.long_name) {
        setfirst_PostalCode(reszipcode[0]?.long_name ?? 0);
      } else {
        toast.error("Enter specific location");
      }
      getLatLng(results[0])
        .then((latLng) => {
          console.log("Success", latLng);
          setUserLatLng(latLng);
          setUserLocation(true);
          setMyAddress(address);
          setfirst_destination(address);
        })
        .catch((error) => console.error("Error", error));
    });
  };
  console.log(address, "hkuli");
  const getUserData = async () => {
    let res = await AuthServices.fetchProfile();
    console.log("res", res);
    setUserData(res?.data?.[0]);
    setMyAddress(res?.data?.[0].userAddress);
    setdefaultAddress(res?.data?.[0].userAddress);
    setAddress({
      latlng: {
        lat: res?.data?.[0].coordinateArray[1],
        lng: res?.data?.[0].coordinateArray[0],
      },
      area: res?.data?.[0].userAddress,
    });
    setdefaultpincode(res?.data?.[0].userAreaPostCode);

    setfirst_PostalCode(res?.data?.[0].userAreaPostCode);
  };
  console.log(address, "ppooowr");
  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleLocationClick = () => {
    setUserLocation(!userLocation);
  };

  const handleChange = (e) => {
    e.preventDefault();

    // if (projectData.startCost < projectData.endCost) {
    //   setflag(true);
    //   // setflag1(true);
    // }
    // {
    //   setflag(false);
    //   // setflag1(false);
    // }

    // if (projectData.startCost) {
    //   setflag1(false);
    //   alert("u");
    // } else {
    //   alert("r");

    //   setflag1(true);
    // }

    if (e.target.name == "endCost") {
      if (e.target.value) {
        if (!projectData.startCost) {
          setflag1(true);
        } else {
          setflag1(false);
        }
        if (e.target.value > projectData.startCost) {
          setProjectData({ ...projectData, endCost: Math.abs(e.target.value) });
          setflag(false);
        } else {
          setflag(true);
          setProjectData({ ...projectData, endCost: Math.abs(e.target.value) });
          // toast.error("please enter End Price bigger than Start Price");
        }
      } else {
        setProjectData({ ...projectData, endCost: "" });
      }
    } else if (e.target.name == "startCost") {
      if (e.target.value) {
        setflag1(false);
        if (e.target.value > projectData.endCost) {
          setflag(true);
          // alert("rr");
        } else {
          // alert("hh");
          setflag(false);
        }
        setProjectData({ ...projectData, startCost: Math.abs(e.target.value) });
      } else {
        setflag1(true);
        setProjectData({ ...projectData, startCost: "" });
      }
    } else {
      setProjectData({ ...projectData, [e.target.name]: e.target.value });
    }
  };

  function getFileExtensionFromMimeType(mimeType) {
    const parts = mimeType.split("/");
    if (parts.length === 2) {
      return parts[1];
    } else {
      // Handle invalid MIME type
      return null;
    }
  }

  const handleImage = async (e) => {
    // setIsLoading(true)

    let arr = [];
    let file = e.target.files;

    for (let element of file) {
      console.log(
        "gghhh",
        element.type,
        getFileExtensionFromMimeType(element.type)
      );
      if (
        // getFileExtensionFromMimeType(element.type) === "jpeg" ||
        getFileExtensionFromMimeType(element.type) === "png" ||
        getFileExtensionFromMimeType(element.type) === "jpg" ||
        getFileExtensionFromMimeType(element.type) === "jpeg"
      ) {
        let data = new FormData();
        data.append("img", element);
        let res = await HttpClient.fileUplode("project-image", "POST", data);

        if (res && res?.status) {
          toast.success("Uploaded Successfully");
          console.log("images", res);
          arr.push(res?.data);
          setimageflag(false);
        } else {
          toast.error(res?.message);
        }
        setimageflag(false);
      } else {
        setimageflag(true);
      }
    }

    setProjectData({
      ...projectData,
      [e?.target?.name]: arr,
    });

    // setIsLoading(false)
    file && setImages(arr);
  };

  const PostalCodehandelar = (e) => {
    // setPostalCode(e.target.value);
    setAddress({ ...address, zipcode: e.target.value });
  };

  const validation = () => {
    let obj = {};
    if (selected.length == 0) {
      obj.categories = "Select Atleast one category";
    } else {
      delete obj.categories;
    }
    if (projectData.description === "") {
      obj.description = "Enter Some Description";
    } else {
      delete obj.description;
    }
    if (address.area === "") {
      obj.address = "Select Location";
    } else {
      delete obj.address;
    }
    if (
      Number(address.zipcode) > 0
        ? address.zipcode == ""
        : first_PostalCode === ""
    ) {
      obj.postalCode = "Select Postal Code";
    } else {
      delete obj.postalCode;
    }
    if (projectData.startCost === "") {
      obj.startCost = "Enter Start Price";
    } else {
      delete obj.startCost;
    }
    if (projectData.endCost === "") {
      obj.endCost = "Enter End Price";
    } else {
      delete obj.endCost;
    }
    // if (address?.latLng?.lng === "") {
    //   alert("enter lat");
    // }
    if (projectData.startDate === "") {
      obj.startDate = "Enter Start Date";
    } else {
      delete obj.startDate;
    }
    if (projectData.endDate === "") {
      obj.endDate = "Enter End Date";
    } else {
      delete obj.endDate;
    }
    if (freelencer && fundAmount == "") {
      obj.fundamount = "Enter Some Amount";
    } else {
      delete obj.fundamount;
    }
    if (freelencer && fundType == "") {
      obj.fundType = "Select finance Type";
    } else {
      delete obj.fundType;
    }

    if (projectData.endCost < projectData.startCost) {
      return false;
    }

    if (imageflag) {
      return false;
    }
    console.log(
      "jjiiuiui",
      projectData.endCost < projectData.startCost,
      imageflag
    );

    setError(obj);
    if (Object.keys(obj).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  let ownLocation = [
    {
      latitude: address?.latlng?.lat,
      longitude: address?.latlng?.lng,
    },
  ];
  let newlocation = [
    {
      latitude: address?.latLng?.lat,
      longitude: address?.latLng?.lng,
    },
  ];
  console.log("latlnrwg", ownLocation, newlocation, address);

  const handelSubmit = async (e) => {
    e.preventDefault();
    // console.log("hfy", address);
    // alert(address?.latlng?.lng);

    if (!validation()) {
      // const modalContent = document.getElementById("modalContent");
      // modalContent.scrollTo(0, 0);
      toast.error("Mandatory fields to be filled", {
        height: "3rem",
        zIndex: 55,
      });
      seterrFlag(true);
      return;
    }
    seterrFlag(false);
    // console.log("click");

    // let ownLocation = [
    //   {
    //     latitude: address?.latlng?.lat,
    //     longitude: address?.latlng?.lng,
    //   },
    // ];
    // let newlocation = [
    //   {
    //     latitude: address?.latLng?.Lat,
    //     longitude: address?.latLng?.Lng,
    //   },
    // ];
    // console.log("latlng", ownLocation, newlocation);

    let newArr = selected.map((item, index) => item.value);

    // selected.forEach((element) => {
    //   Arr.push(element.value);
    // });
    // console.log(userData, Arr);

    let data = {
      userId: userData?._id,
      name: "",
      categories: newArr,
      description: projectData.description,
      othersCategories: projectData.othersCategories,
      address: address?.area,
      postalCode:
        Number(address.zipcode) > 0 ? address.zipcode : first_PostalCode,
      startDate: projectData.startDate,
      endDate: projectData.endDate,
      location: address?.latlng?.lat
        ? [
            {
              latitude: address?.latlng?.lat,
              longitude: address?.latlng?.lng,
            },
          ]
        : [
            {
              latitude: address?.latLng?.lat,
              longitude: address?.latLng?.lng,
            },
          ],
      startCost: projectData.startCost,
      endCost: projectData.endCost,
      image: images,
      expectedEndDate: projectData.endDate,
      isFinance: freelencer,
    };
    if (freelencer) {
      data.amount = Number(fundAmount);
      data.financeType = fundType;
    }
    // console.log(templocation, "uuuuuu");
    // console.log(data, "ooooooooo");
    // return;
    setisStripeUrlopen(true);
    let res = await AuthServices.createProject(data);

    if (res && res.status) {
      console.log("Service created successfully");
      setisStripeUrlopen(false);
      toast.success("Service created successfully");
      dispatch(getProduct());
      dispatch(getAllProduct());
      closeModal(false);
    } else {
      setisStripeUrlopen(false);
      toast.error("project creation failed");
      // toast.error("You Are not a Homeowner");
    }
  };
  //console.log("hjd")

  const HandleCrossClick = (imageIndex) => {
    const updatedImages = images.filter((_, index) => index !== imageIndex);
    setImages(updatedImages);
  };

  console.log(defaultAddressFlag, myaddress, "ppww2rr");

  useEffect(() => {
    getUserData();
    if (address) {
      setAddress({ ...address, area: "" });
    }
  }, []);

  const strDateRef = useRef(null);

  document.addEventListener("DOMContentLoaded", function () {
    var dateInput = document.getElementById("session-date");

    dateInput.addEventListener("click", function () {
      // Trigger the click event on the input
      dateInput.click();
    });
  });

  const handleColClick = () => {
    document.addEventListener("DOMContentLoaded", function () {
      var dateInput = document.getElementById("session-date");

      dateInput.addEventListener("click", function () {
        // Trigger the click event on the input
        dateInput.click();
      });
    });
    // console.log("handleColClick called");
    // if (strDateRef.current) {
    //   strDateRef.current.click();
    // }
  };
  return (
    <section className="create-project-main">
      <div className="create-project" id="create-project">
        <div
          className="add-project"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            marginRight: "2rem",
          }}
        >
          {/* <i class="fa fa-arrow-left arrow" aria-hidden="true"></i> */}
          <h3>Add service request</h3>
          {errFlag && (
            <span style={{ color: "red" }}>Mandatory fields to be filled</span>
          )}
        </div>

        <button
          className="closebtn"
          onClick={() => {
            closeModal(false);
            if (address) {
              setAddress({
                latlng: {},
                area: "",
              });
            }
          }}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>

        <form>
          {/* <pre>{JSON.stringify(selected)}</pre> */}
          <div className="service_input">
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
          </div>
          <small style={{ color: "red", fontWeight: "bold" }}>
            {error.categories}
          </small>
          {/* Description */}
          <div class="form-group description">
            <label for="exampleFormControlTextarea1">Description</label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              name="description"
              value={projectData.description}
              placeholder="Can you provide a bit more information about the project and if you have chosen others option, what are you looking to accomplish?"
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>
          <small style={{ color: "red", fontWeight: "bold" }}>
            {error.description}
          </small>
          {/* Where is this job done? */}
          {/*address*/}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            <p for="formGroupExampleInput">Where is this job done?</p>{" "}
            <p
              for="formGroupExampleInput"
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => {
                setdefaultAddressFlag(!defaultAddressFlag);
                setMyAddress(!defaultAddressFlag ? "" : defaultaddress);
                setfirst_PostalCode(!defaultAddressFlag ? "" : defaultpincode);
              }}
            >
              {defaultAddressFlag ? (
                "Set Default Address"
              ) : (
                <>
                  <i class="fa-solid fa-plus"></i> New Address
                </>
              )}
            </p>
          </div>
          {/* <input
            type={"checkbox"}
            name="addresstype"
            value={"newSelect"}
            onChange={() => setdefaultAddressFlag(!defaultAddressFlag)}
          /> */}

          <div class="form-group address">
            <div className="icon_marge">
              {/* <input
                disabled
                type="text"
                class="form-control address_input"
                id="formGroupExampleInput"
                placeholder="Your Address"
                name="address"
                value={
                  first_destination
                    ? first_destination
                    : removeFirstSegment(address.area)
                }
                onChange={(e) => handleChange(e)}
              /> */}
              {/* <div className="location_icon_div">
                <i
                  class="fa-solid fa-location-dot location_icon"
                  style={{ color: "#e20303" }}
                  onClick={() => handleLocationClick()}
                ></i>
              </div> */}
            </div>
            {/* {userLocation && (
              <div className="maparea">
                <MapComponent
                  lat={userLocation.lat}
                  lng={userLocation.lng}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC-ki3ImgxYzo8K2OCH9yDthHWIWV1yfj4&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={{ height: "100%" }} />}
                  containerElement={<div style={{ height: "400px" }} />}
                  mapElement={<div style={{ height: "100%" }} />}
                />
              </div>
            )} */}

            <PlacesAutocomplete
              value={myaddress}
              onChange={handleChangeAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid black",
                      borderRadius: "5px",
                    }}
                  >
                    <input
                      style={{
                        pointerEvents: defaultAddressFlag ? "" : "none",
                        opacity: 0.7,
                        textDecoration: "none",
                        border: 0,
                        outline: "none",
                      }}
                      // onChange={(e) => {
                      //   alert("e");
                      //   console.log("e.target.value", e.target.value);
                      // }}
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "form-control address_input",
                      })}
                    />
                    {/* {defaultAddressFlag ? <i class="fa fa-search"></i> : null} */}
                  </div>
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      // setfirst_destination(suggestion.description);
                      console.log("sugesion", suggestion);
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {userLocation && defaultAddressFlag && (
              <div
              // onClick={() => {
              //   setfirst_destination("");
              // }}
              >
                <FindMapAddress
                  userLatLng={userLatLng}
                  setUserLatLng={setUserLatLng}
                  setMyAddress={setMyAddress}
                />
              </div>
            )}
          </div>
          <small style={{ color: "red", fontWeight: "bold" }}>
            {error.address}
          </small>
          {/* Post Code */}
          <div class="form-group post_code">
            <label for="formGroupExampleInput">Post Code</label>
            <input
              disabled={defaultAddressFlag ? false : true}
              type="text"
              class="form-control"
              id="formGroupExampleInput"
              placeholder="Enter your postal code"
              name="postalCode"
              // value={postalCode ? postalCode : address.zipcode}
              value={
                Number(address.zipcode) > 0 ? address.zipcode : first_PostalCode
              }
              onChange={(e) => {
                handleChange(e);
                PostalCodehandelar(e);
              }}
            />
          </div>
          <small style={{ color: "red", fontWeight: "bold" }}>
            {error.postalCode}
          </small>
          {/* how much you looking for spend */}
          <div className="spend_money">
            <h5>How much you looking for spend?</h5>
            <div class="row">
              <div class="col">
                <label htmlFor="">Start Price</label>
                <div className="sterlingmainwrap">
                  <input
                    type="number"
                    class="form-control"
                    placeholder=""
                    name="startCost"
                    value={projectData.startCost}
                    onChange={(e) => handleChange(e)}
                  />
                  <div className="sterlingicon">
                    <i class="fa-solid fa-sterling-sign"></i>
                  </div>
                </div>
                <small style={{ color: "red", fontWeight: "bold" }}>
                  {error.startCost}
                </small>
              </div>
              <div class="col">
                <label htmlFor="">End Price</label>

                <div className="sterlingmainwrap">
                  <input
                    type="number"
                    class="form-control"
                    placeholder=""
                    name="endCost"
                    value={projectData.endCost}
                    onChange={(e) => handleChange(e)}
                  />
                  <div className="sterlingicon">
                    <i class="fa-solid fa-sterling-sign"></i>
                  </div>
                </div>
                <span
                  style={{
                    color: "red",
                    display: flag ? "block" : "none",
                    fontSize: "15px",
                  }}
                >
                  Please enter "End Price" bigger than "Start Price"
                </span>
                <span
                  style={{
                    color: "red",
                    display: flag1 ? "block" : "none",
                    fontSize: "15px",
                  }}
                >
                  Please enter Start Price first
                </span>
                <small style={{ color: "red", fontWeight: "bold" }}>
                  {error.endCost}
                </small>
              </div>
            </div>
          </div>
          {/* Estimated timeline */}
          <div className="timeline">
            <h5>Estimated timeline</h5>
            <div class="row">
              <div
                class="col"
                onClick={() => {
                  handleColClick();
                }}
              >
                <label htmlFor="">Start date</label>
                <input
                  type="date"
                  id="session-date"
                  ref={strDateRef}
                  class="form-control"
                  placeholder=""
                  name="startDate"
                  value={moment(projectData?.startDate).format("YYYY-MM-DD")}
                  min={getCurrentDate()}
                  onChange={(e) => {
                    handleChange(e);
                    setShowDate(false);
                  }}
                />
                <small style={{ color: "red", fontWeight: "bold" }}>
                  {error.startDate}
                </small>
              </div>
              <div class="col">
                <label htmlFor="">End date</label>
                <input
                  disabled={showDate}
                  type="date"
                  class="form-control"
                  placeholder=""
                  name="endDate"
                  value={moment(projectData?.endDate).format("YYYY-MM-DD")}
                  min={projectData?.startDate}
                  onChange={(e) => handleChange(e)}
                />
                <small style={{ color: "red", fontWeight: "bold" }}>
                  {error.endDate}
                </small>
              </div>
            </div>
          </div>
          <div
            style={{
              margin: "10px 0px 10px 0px",
            }}
          >
            <input
              type={"checkbox"}
              checked={freelencer}
              onChange={() => setFreelencer(!freelencer)}
            />
            <span
              style={{
                marginLeft: "10px",
                fontWeight: "bolder",
                color: "#007bff",
              }}
            >
              Get Funded
            </span>
          </div>
          {freelencer ? (
            <>
              <div className="col" style={{ marginTop: "10px", zIndex: 29 }}>
                <Select
                  className="basic-single"
                  classNamePrefix="What type of founding are you interested in?"
                  defaultValue={loandata[0]}
                  name="color"
                  options={loandata}
                  onChange={(val) => setFundType(val.value)}
                />
                <small style={{ color: "red", fontWeight: "bold" }}>
                  {error.fundType}
                </small>
              </div>
              <div class="col" style={{ marginTop: "5px" }}>
                <label htmlFor="" style={{ fontWeight: "bold" }}>
                  Funding Amount
                </label>

                <div className="sterlingmainwrap">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Amount"
                    name="amount"
                    value={fundAmount}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setFundAmount("");
                      } else {
                        setFundAmount(Math.abs(e.target.value));
                      }
                    }}
                  />
                  <div className="sterlingicon">
                    <i class="fa-solid fa-sterling-sign"></i>
                  </div>
                </div>
                <small style={{ color: "red", fontWeight: "bold" }}>
                  {error.fundamount}
                </small>
                {/* </div> */}
              </div>
            </>
          ) : null}
          {/* <div 
          style={{
            margin:'10px 0px 10px 0px'
          }}
          >
            <input
            type={"checkbox"}
            checked={freelencer}
            onChange={()=>setFreelencer(!freelencer)}
            />
            <span style={{marginLeft:'10px',fontWeight:'bolder',color:'#007bff'}}>
              Do you want Financer ?</span>
          </div> */}
          {/* Images */}
          <div className="d-flex align-items-center">
            <div className="Attach_Image">
              <h5>Attach Image</h5>
              <span
                style={{
                  color: "green",

                  fontSize: "15px",
                }}
              >
                (Only "jpg" & "png" format accepted)
              </span>
              <div className="Attach_Image_box">
                <input
                  type="file"
                  name="image"
                  id="image"
                  multiple
                  accept="image/*"
                  onChange={(e) => handleImage(e)}
                />

                <div className="cnt_box">
                  <div className="icon">
                    <i className="fa-solid fa-file-image"></i>
                  </div>
                  <p className="text">Add Image</p>
                </div>
              </div>
              <span
                style={{
                  color: "red",
                  display: imageflag ? "block" : "none",
                  fontSize: "15px",
                }}
              >
                Only "jpg" & "png" format accepted
              </span>
            </div>

            {/* view image */}
            {images &&
              images?.map((item, index) => {
                return (
                  <div className="add_product_img">
                    <img src={item} alt="/" />
                    <div
                      className="closebtn"
                      onClick={() => HandleCrossClick(index)}
                    >
                      X
                    </div>
                  </div>
                );
              })}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="btn btn-primary submit_btn"
              onClick={(e) => {
                handelSubmit(e);
                // alert("kk");
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {isStripeUrlopen && (
        <div className="loadingBar">
          <Oval
            height={100}
            width={1200}
            color="green"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </section>
  );
};

export default AddProjects;
