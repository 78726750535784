import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import { socket } from '../Socket/Socket';

function ChatScreen() {
  const [allMsg, setAllMsg] = useState([])
  // function getAllData() {
  //     setLoserStatus(true)
  //     MainServices.getChat(taskId + "/" + PageCount)
  //         .then(res => {
  //             console.log("all chat", res);
  //             setLoserStatus(false)
  //             if (res && res.status) {
  //                 setAllMsg(res.data)

  //             }
  //         })
  //         .catch(err => {
  //             setLoserStatus(false)
  //             console.log("err", err);
  //         })
  // }
  function resivedMessage(data) {
    // console.log(data.resiverId == userData._id);
    console.log("data", data);
    // if (data.resiverId == userData._id) {
    //     setAllMsg(s => {
    //         return [{ ...data, _id: new Date().toTimeString(), createdAt: new Date() }, ...s]
    //     })
    // }

  }
  useEffect(() => {
    // getAllData()
    socket.on('resivedMessage', resivedMessage);
    return () => socket.off('resivedMessage', resivedMessage);
  }, [])

  return (
    <>
      <div className='main_wrap'>
        <div className='container-fluid'>
          <section className='Projectpage_area'>
            <div className='toppart'>
              <div className='head'>
                <h4></h4>
              </div>

              <ul className='actionpart'>
                <li className="nav-item dropdown">
                  {/* <Link
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="NewestDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Sort by:</span> Newest Project
                  </Link> */}
                  <div className="dropdown-menu" aria-labelledby="NewestDropdown">
                    <Link className="dropdown-item" href="#">
                      demo
                    </Link>
                    <Link className="dropdown-item" href="#">
                      demo
                    </Link>
                  </div>
                </li>
              </ul>

            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default ChatScreen