import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import proImg from "../../src/Images/project2225.png";
import ppc from "../../src/Images/profile-pic.png";
import ppc2 from "../../src/Images/supplier.png";
import ppc3 from "../../src/Images/rply_img.png";
import atImg from "../../src/Images/cons1.png";
import pdImg from "../../src/Images/pdf.png";
import BidProjectModal from "../../src/Component/Modal/BidProjectModal";
import SuccessfullybidprojectModal from "../../src/Component/Modal/SuccessfullybidprojectModal";
import { useEffect } from "react";
import ProductServices from "../services/ProductServices";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import HttpClient from "../utils/HttpClient";
import moment from "moment";

function BidViewProjectDetails({ viewFlag }) {
  console.log("hhkjh", viewFlag);
  const { userData } = useSelector((state) => state.User);
  const [BidModal, setBidModal] = useState(false);
  const [SuccbidModal, setSuccbidModal] = useState(false);
  const [singledata, setSingledata] = useState({});
  console.log("singledatwwa", singledata);
  const isFinance = userData?.jobRole?.some(
    (it) => it == "65531d1de9465ce15d48648e"
  );

  const navigate = useNavigate();
  const { id } = useParams();

  const OpenBidproject = () => {
    setBidModal(!BidModal);
  };
  const onclick = () => {
    setSuccbidModal(!SuccbidModal);
  };
  const gotoHome = () => {
    setSuccbidModal(!SuccbidModal);
    navigate("/");
  };
  useEffect(() => {
    getsingleProject();
  }, []);

  const getsingleProject = () => {
    ProductServices.getsingleProject(id)
      .then((res) => {
        console.log("singledata", res);
        if (res && res.status) {
          setSingledata(res?.data);
        }
      })
      .catch((err) => {});
  };

  console.log("gdfjjhsdjfh", singledata?.userData);
  const containerStyle = {
    backgroundColor: "#f4f4f4",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
  };

  const labelStyle = {
    display: "flex",
    gap: "0.2rem",
    fontWeight: "bold",
    alignItems: "center",
  };
  return (
    <>
      <section className="BidViewProjectDetails">
        <div className="head_line">
          <Link
            onClick={() => {
              window.history.back();
            }}
            className="posted_icon"
          >
            <i className="fa-solid fa-arrow-left"></i>
            View Service
          </Link>
        </div>

        <div className="projectImg_wrap">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            {singledata?.image?.map((it, ind) => {
              return (
                <SwiperSlide key={ind}>
                  <div className="projImg">
                    <img src={it} className="img-fluid" alt="" />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="d-flex align-items-center justify-content-between pt-2">
          <div className="projectName">
            <h4>{singledata?.name}</h4>
          </div>
          <div className="bookmark_icon">
            <i className="fa-regular fa-bookmark"></i>
          </div>
        </div>

        <div className="d-flex align-items-center mb-4">
          <button className="Attachment_btn btn mr-3">
            <i className="fa-regular fa-clock mr-2"></i>

            {/* {moment(singledata?.updatedAt).fromNow()} */}
            {moment(singledata?.createdAt).fromNow()}
          </button>
          <button className="Attachment_btn btn">
            <i className="fa-solid fa-link mr-2"></i> Attachment{" "}
            {singledata?.image?.length}
          </button>
        </div>

        <div className="ProjectPostedBy">
          <h4 className="heding">
            {" "}
            {isFinance ? "Service Request Posted By" : "Project Posted By "}
          </h4>

          <div className="profile mb-3">
            <div className="profPic">
              <img
                src={singledata?.userData?.image ?? ppc}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="details">
              <div className="d-flex align-items-center mb-1">
                <h5>{singledata?.userData?.username}</h5>
                <p>
                  Rating <span>(0.0)</span>
                </p>
              </div>
              <h6>
                {/* {moment(singledata?.updatedAt).fromNow()} */}
                {moment(singledata?.createdAt).fromNow()}
              </h6>
            </div>
          </div>
          <div className="ProjectDescription mb-3" style={containerStyle}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.2rem",
              }}
            >
              <h6 style={labelStyle}>
                Name:{" "}
                <span className="spantext">
                  {singledata?.userData?.username}
                </span>{" "}
              </h6>

              <h6 style={labelStyle}>
                Email:
                <span className="spantext"> {singledata?.userData?.email}</span>
              </h6>

              <h6 style={labelStyle}>
                Contact Number:
                <span className="spantext">
                  {" "}
                  {singledata?.userData?.phone}
                </span>{" "}
              </h6>

              <h6 style={labelStyle}>
                Postcode:
                <span className="spantext">{singledata?.postalCode}</span>
              </h6>
            </div>
            {/* <ul>
              {singledata?.categories?.map((it, ind) => {
                return <li key={ind}>{it}</li>;
              })}
            </ul> */}
          </div>

          <div className="ProjectDescription mb-3">
            <h4>Project Description:</h4>
            <p>{singledata?.description}</p>
            <h4>Project Categories:</h4>
            <ul>
              {singledata?.categories?.map((it, ind) => {
                return <li key={ind}>{it}</li>;
              })}
            </ul>
          </div>

          <h4 className="heding">Attachment:</h4>

          <div className="attachment_wrap mb-3">
            {singledata?.image?.map((it, ind) => {
              return (
                <div className="attacImg" key={ind}>
                  <img src={it} className="img-fluid" alt="" />
                  {/* <div className='downloadbtn'>
                                            <i className="fa-solid fa-download"></i>
                                        </div> */}
                </div>
              );
            })}
          </div>
          {viewFlag && (
            <div className="bottom_area">
              <div className="left">
                <h6>
                  £ {singledata?.startCost} - £{singledata?.endCost}
                </h6>
                <p>Total Project Price</p>
              </div>
              <div className="right">
                {singledata?.bidData?.map((it, ind) => {
                  console.log(";ljgh", it);
                  if (it.bidderData.email == userData.email)
                    return (
                      <>
                        <h6>£ {it?.bidPrice}</h6>
                        <p>Your bided amount</p>
                      </>
                    );
                })}
              </div>
            </div>
          )}

          <div
            style={{
              display: viewFlag ? "none" : "block",
            }}
          >
            <div
              style={{
                display: isFinance ? "block" : "none",
              }}
              className="bottom_area"
            >
              <h6>Finance Request:</h6>
              <div
                className="left"
                style={{
                  display: isFinance ? "block" : "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  <h6>
                    Type of Finance:{" "}
                    <span className="spantext">{singledata?.financeType} </span>
                  </h6>
                  <h6>
                    Amount :{" "}
                    <span className="spantext">£ {singledata?.amount}</span>
                  </h6>
                </div>
              </div>
            </div>
            <div
              style={{
                display: !isFinance ? "block" : "none",
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="heding">
                  Total bids for this project({singledata?.bidData?.length})
                </h4>
                {/* <button className='Seemore_btn'>See more</button> */}
              </div>

              {singledata?.bidData?.map((it, ind) => {
                console.log("jhjkhj", it);
                return (
                  <div className="profile_wrap_main" key={ind}>
                    <div className="profile">
                      <div className="profPic">
                        <img
                          src={it?.bidderData.image}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="details">
                        <div className="d-flex align-items-center mb-1">
                          <h5>{it?.bidderData?.username}</h5>
                          {/* <p>Rating <span>(4.5)</span></p> */}
                        </div>
                        <h6>Bidded price: £ {it?.bidPrice} </h6>
                      </div>
                    </div>
                    <div className="text">
                      <p>{it?.message}</p>
                    </div>
                  </div>
                );
              })}

              <div className="bottom_area">
                <div className="left">
                  <h6>
                    £ {singledata?.startCost} - £{singledata?.endCost}
                  </h6>
                  <p>Total Project Price</p>
                </div>
                {console.log(
                  "singledata?.bidData?.findIndex((it) => it.bidderData._id == userData._id)",
                  singledata?.bidData?.findIndex(
                    (it) => it.bidderData?._id == userData?._id
                  )
                )}
                {singledata?.bidData?.findIndex(
                  (it) => it.bidderData?._id == userData?._id
                ) >= 0 ? null : (
                  <div className="right">
                    <button
                      className="Bidthisproject_btn"
                      onClick={OpenBidproject}
                    >
                      Bid this project
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {BidModal && (
        <BidProjectModal
          closemodal={setBidModal}
          falsemodal={() => setSuccbidModal(!SuccbidModal)}
          biddeddata={singledata}
        />
      )}
      {SuccbidModal && <SuccessfullybidprojectModal closeModal={gotoHome} />}
    </>
  );
}

export default BidViewProjectDetails;
