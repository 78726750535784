import HttpClient from "../utils/HttpClient";

const fetchLogin = async (data) => {
  let endpoint = "login";
  return await HttpClient.post(endpoint, data);
};

const getprofile = async (id) => {
  let endpoint = "profile-fetch/" + id;
  return await HttpClient.get(endpoint);
};

const fetchProfile = async () => {
  let endpoint = "profile";
  return await HttpClient.get(endpoint);
};

const sendotp = async (data) => {
  let endpoint = "preRegisterUser";
  return await HttpClient.post(endpoint, data);
};

const getRoles = async () => {
  let endpoint = "role";
  return await HttpClient.get(endpoint);
};

const getUserDatas = async () => {
  let endpoint = "profile"
  return await HttpClient.get(endpoint)
}

const userRegister = async (data) => {
  let endpoint = "register";
  return await HttpClient.post(endpoint, data);
};

const createProject = async (data) => {
  let endpoint = "project";
  return await HttpClient.post(endpoint, data);
};

const uploadImages = async (data) => {
  let endpoint = "project-image";
  return await HttpClient.Imagepost(endpoint, data);
};
const uploadBidImages = async (data) => {
  let endpoint = "bid-image";
  return await HttpClient.Imagepost(endpoint, data);
};
const getProject = async (data) => {
  let endpoint = "project";
  return await HttpClient.get(endpoint);
};
const sociallogin = async (data) => {
  return HttpClient.post('sociallogin', data);
}
const customer = async (data) => {
  return HttpClient.post('customer', data);
}

const profileUpdate = async (data) => {
  return HttpClient.put('profile-update', data);
}
const userchangepass = async (data) => {
  return HttpClient.put('user-change-pass', data);
}
const deleteUser = async () => {

  return HttpClient.deletemethod('delete-user', {});
}

const forgototp = async (data) => {
  return HttpClient.post('forgot-otp', data);
}
// async function setToken(data) {
//   return await Storage.set('token', data);
// }

const changepass = async (data) => {
  return HttpClient.post('change-pass', data);
}
const getProfileById = async (id) => {
  return HttpClient.get('profile-fetch/' + id);
}

export default {
  fetchLogin,
  getprofile,
  fetchProfile,
  sendotp,
  getRoles,
  userRegister,
  uploadImages,
  uploadBidImages,
  createProject,
  getProject,
  sociallogin,
  customer,
  profileUpdate,
  getUserDatas,
  userchangepass,
  deleteUser,
  forgototp,
  // setToken,
  changepass,
  getProfileById
};
