import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import ProductServices from "../../services/ProductServices"

const initialState = {
    taskData: [],
    loading: false,
    refress: false,
    count: 0,
}

export const getTask = createAsyncThunk("task_get_task", async (id) => {

    const res = await ProductServices.fetchGetTask(id);

    if (res && res.status) {
        console.log("res get task", res);
        return res
    } else {
        console.log("failed to fetch tasks due to ", res?.error?.message);
    }
})
// export const ResetState = ()=>{

// }



const taskSlice = createSlice({
    name: "get_task",
    initialState,
    reducers: {
        clearState: (state) => {
            state.taskData = [];
            state.loading = false;
        },
        inCreaseCount: (state) => {
            state.count = state.count + 1
        },
        updateTask(state, action) {
            let data = action.payload;
            console.log('data redux', data)
            let changeindex = state.taskData.findIndex((it) => it._id == data._id)
            console.log(changeindex)
            // state.taskData[changeindex] = data;
            return { ...state, taskData: state.taskData, loading: false }
        }
    },
    extraReducers: {
        [getTask.pending]: (state) => {
            state.loading = true;
        },
        [getTask.fulfilled]: (state, { payload }) => {
            if (payload && payload?.status) {
                console.log("payload", payload);
                state.taskData = payload?.data;
                state.loading = false;
                state.refress = !state.refress
            } else {
                state.loading = false;
            }
        },
        [getTask.rejected]: (state) => {
            state.loading = false;
        }
    }
})

export const { clearState, updateTask, inCreaseCount } = taskSlice.actions;
export const taskReducer = taskSlice.reducer;

