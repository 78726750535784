import { reactLocalStorage } from "reactjs-localstorage";
const BASE_URL = "https://api.buildbeta.co.uk/api/user/";

const IMAGE_URL = "https://api.buildbeta.co.uk/";

const ALLOW_ORIGIN = "api.buildbeta.co.uk";

const USER_TYPE = "User";

function get(endpoint, params) {
  return requestData(endpoint, params);
}

function post(endpoint, params) {
  return requestData(endpoint, params, "POST");
}

function put(endpoint, params) {
  return requestData(endpoint, params, "PUT");
}

function deletemethod(endpoint, params) {
  return requestData(endpoint, params, "DELETE");
}

function Imagepost(endpoint, params) {
  return fileUplode(endpoint, params, "POST");
}

function createProject(endpoint, params) {
  return requestData(endpoint, params, "POST");
}

async function requestData(url, data = {}, method = "GET") {
  // console.log('khankidata', data, method)
  let xhr = new XMLHttpRequest();
  xhr.open(method, BASE_URL + url);
  // console.log("checkingAuth", checkingAuth())
  if (checkingAuth()) xhr.setRequestHeader("authorization", checkingAuth());
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Access-Control-Allow-Origin", ALLOW_ORIGIN);
  xhr.setRequestHeader("userType", USER_TYPE);

  return new Promise((resolve, reject) => {
    // console.log("string mal", JSON.stringify(data));
    if (method == "GET") {
      xhr.send();
    } else {
      xhr.send(JSON.stringify(data));
    }
    xhr.onload = () => {
      console.log(xhr.status);
      let response = JSON.parse(xhr.response);
      // console.log('forbitn error', response, xhr.status);

      if (xhr.status) {
        resolve(response);
      } else {
        reject(response);
      }
    };
  });
}

export async function requestData2(url, method, data = {}) {
  // let token = "";
  // let user = reactLocalStorage.getObject("adminData");
  // if (user && user != null && Object.keys(user).length > 0) {
  //   token = user.token;
  //   console.log(token);
  // }
  let token = localStorage.getItem("constructionToken");
  console.log("Http client request data user token", JSON.parse(token));
  let apiUrl = BASE_URL + url;
  var myHeaders = new Headers();
  // if (token != "") {
  //   myHeaders.append("authorization", token);

  // }
  // myHeaders.append("userType", "Admin");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("authorization", JSON.parse(token).token);
  myHeaders.append("userType", USER_TYPE);
  myHeaders.append("Access-Control-Allow-Origin", "api.buildbeta.co.uk");
  var raw = JSON.stringify(data);

  var requestOptions = {};
  if (method === "POST") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else if (method === "PUT") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else {
    requestOptions = {
      method: method,
      headers: myHeaders,
      redirect: "follow",
    };
  }

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log("API response", result);
      return result;
    })
    .catch((error) => console.log("error", error));
}

async function fileUplode(url, method, file) {
  let token = "";
  let userToken = localStorage.getItem("constructionToken");
  let user = JSON.parse(userToken);
  console.log("UserTok", user.token);
  if (user && user != null && Object.keys(user).length > 0) {
    token = user.token;
  }
  // let bash_url = "http://13.127.101.102:5011/api/v1/";
  let apiUrl = BASE_URL + url;
  // let data = new FormData();
  // data.append("image", file);
  // Object.keys(object_get).forEach(function (key) {
  //   let ddd = object_get[key];
  //   data.append(key, ddd);
  // });

  if (token != "") {
    var toooo = token;
  }
  //  console.log("tokenn",toooo);
  let headers = {
    // 'Accept': 'application/json',
    // "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin": "http://13.127.101.102:5008",
    authorization: toooo,
    userType: "User",
    // 'Authorization': 'Bearer ' + login_status,
  };

  // Display the key/value pairs
  // for (var pair of file.entries()) {
  //   console.log(pair[0] + ", " + pair[1]);
  // }

  return await fetch(apiUrl, {
    method: method,
    body: file,
    redirect: "follow",
    headers: headers,
  })
    .then((response) => {
      // console.log("responseee", response, headers);
      return response.json();
    })
    .then(
      (result) => {
        console.log("ResponIMG", result);
        return result;
      },
      (error) => {
        return error;
      }
    );
}

function checkingAuth() {
  let tokenData = localStorage.getItem("constructionToken");
  console.log("TOKEn", JSON.parse(tokenData));
  let parsetoken = JSON.parse(tokenData)
  if (parsetoken) {
    return parsetoken.token;
  }
  return false;
}

export default {
  requestData,
  requestData2,
  get,
  post,
  put,
  deletemethod,
  fileUplode,
  IMAGE_URL,
  Imagepost,
  createProject,
};
