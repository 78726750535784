import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import ProductServices from "../../services/ProductServices"

const initialState = {

    AllNotifications: [],
    loading: false,

}


export const getAllNotificatios = createAsyncThunk("All_notification", async () => {
    const res = await ProductServices.getAllNotification()
    console.log("resProdd", res)
    return res
})

// export const getAllProduct = createAsyncThunk("product_get_all_Product", async () => {
//     const res = await ProductServices.getallProject()
//     console.log("resProdd_all", res)
//     return res
// })

const NotificationSlice = createSlice({
    name: "get_product",
    initialState,
    // reducers: {
    //     clearState: (state) => {
    //         state.taskData = [];
    //         state.loading = false;
    //     }
    // },
    extraReducers: {
        [getAllNotificatios.pending]: (state) => {
            state.loading = true;
        },
        [getAllNotificatios.fulfilled]: (state, { payload }) => {
            if (payload && payload?.status) {
                state.AllNotifications = payload?.data;
                state.loading = false;
            } else {
                state.loading = false;
            }
        },
        // [getAllProduct.fulfilled]: (state, { payload }) => {
        //     if (payload && payload?.status) {
        //         state.AllProduct = payload?.data;
        //         state.allLoading = false;
        //     } else {
        //         state.allLoading = false;
        //     }
        // },
        [getAllNotificatios.rejected]: (state) => {
            state.loading = false;
        },
        // [getAllProduct.rejected]: (state) => {
        //     state.allLoading = false;
        // }
    }
})

// export const { clearState } = NotificationSlice.actions;
export const NotificationReducer = NotificationSlice.reducer;

