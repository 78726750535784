import React, { useState, useEffect } from 'react'
import rply_img from "../../Images/rply_img.png";
import cons1 from "../../Images/cons1.png";
import cons2 from "../../Images/cons2.png";
import Union from '../../Images/Union.png';
import Group from "../../Images/Group.png";
import ReportBoxModal from "../Modal/ReportBoxModal";
import PartialPaymentRequest from "../Modal/PartialPaymentRequest";
import ProductServices from '../../services/ProductServices';
import { socket } from '../../Socket/Socket';
import { useSelector } from 'react-redux';
import HttpClient from '../../utils/HttpClient';
import toast from 'react-hot-toast';
import AuthServices from '../../services/AuthServices';
import ImageDownloader from '../../View/ImageDownloader';
import { Oval, RotatingLines } from 'react-loader-spinner';
import ViewVideo from '../ImageVideoView/ViewVideo';
import ViewInmage from '../ImageVideoView/ViewInmage';


export default function ChattModel(props) {
    const moment = require('moment');
    const dockimageUrl = 'https://w7.pngwing.com/pngs/521/255/png-transparent-computer-icons-data-file-document-file-format-others-thumbnail.png'
    // const { closeModal, bidderimage } = props;
    // const { singleTaskData, singleProjectData } = props
    const { chatProjectname, setchatFlag, projectId, projectsenderId, projectownerId, projectdata, bidderdata,
        biderimage, task_id, Sender_Id } = props


    const [reportmodal, setReportmodal] = useState(false);
    const [senderId, setSenderId] = useState(Sender_Id ?? "")
    const [msgimage, setMsgimg] = useState([])
    const [taskId, settaskId] = useState(task_id ?? "")
    // const taskId = singleTaskData._id

    const [PageCount, setPageCount] = useState(0);
    const [allMsg, setAllMsg] = useState([]);
    const [LoserStatus, setLoserStatus] = useState(true);
    const [newmessage, setnewMessage] = useState('');
    const userData = useSelector((state) => state.User.userData);
    const [isdownload, setisdownloadloading] = useState(false)
    // console.log(task_id, Sender_Id, userData._id, biderimage, "wwrr")

    const [ImageUrl, setImageUrl] = useState(null)
    const [showImage, setshowImage] = useState(false)
    const [isimage, setisimage] = useState(false)
    console.log("lll", ImageUrl)

    const [projectsenderimage, setprojectsenderimage] = useState("")

    const [biderImage, setbiderImage] = useState("")
    // console.log("ImageUrle", senderId, userData._id, singleTaskData.userId)
    console.log("projectdata", senderId, task_id)
    // console.log("reswwqr", biderImage, projectsenderimage)
    const randomUrl = "https://www.rotarybangkok.org/wp-content/uploads/2017/11/user-placeholder.d2a3ff8.png"

    // console.log(userData._id === projectsenderId, biderimage, "jhghhjkhj")
    console.log(showImage, "ioio")

    const specialModal = () => {
        setReportmodal(true)
    }
    //get Profile
    const seeProfile = async () => {




        const res = await AuthServices.getProfileById(projectsenderId ?? projectownerId)
        if (res && res.status) {
            if (res.data[0]?.image) {
                setprojectsenderimage(res.data[0].image)
            } else {
                setprojectsenderimage(randomUrl)
            }
            // setprojectsenderimage(res.data[0].image)
            console.log("kjfshkjg", res)
        }


    }
    useEffect(() => {

        seeProfile()


    }, [])

    function handleSendMessage() {
        // alert(message)
        if (newmessage == "" && ImageUrl == null) return
        const typename = ImageUrl?.extName === "mp4" ? "video" : (ImageUrl?.extName === "pdf" || ImageUrl?.extName === "csv") ? "document" : "image"
const filenames=ImageUrl?.extName === "mp4" ? "video" :
 (ImageUrl?.extName === "pdf" || ImageUrl?.extName === "csv") ? "document" : "image"

        let data = {
            "senderId": userData._id,
            "resiverId": senderId,
            "taskId": taskId,
            "message": newmessage,
            "image": ImageUrl ? ImageUrl.url : "null",
            "type": typename,
            "filename": ImageUrl ? ImageUrl.filename : "null"

            // "image": msgimage ? msgimage : "null"


        }
        socket.emit('sendMessage', data);
        // setImageModalOpen(false)
        setnewMessage("")
        setImageUrl(null)
        setshowImage(false)
        setMsgimg([])


        setTimeout(() => {
            const chatArea = document.getElementById("chattArea");

            // Check if the element is found before attempting to scroll
            if (chatArea) {
                chatArea.scrollTo(0, chatArea.scrollHeight);
                // alert("jjk")
            } else {
                console.error("Element with id 'chattArea' not found");
            }
        }, 500);



        setAllMsg(s => {

            // const tempmsg = [{ ...data, _id: new Date().toTimeString(), createdAt: new Date() }, ...s];
            // const set = new Set(tempmsg)
            // console.log("sendmessage", set)
            // return ([...set])
            return [{ ...data, _id: new Date().toTimeString(), createdAt: new Date() }, ...s]
        })

        // alert(userData._id === projectsenderId)
        setReportmodal(false)
    }

    function getAllData() {
        console.log("hhh", taskId)
        console.log("1st time", "1st time")
        setLoserStatus(true)
        ProductServices.getChat(taskId + "/" + PageCount)
            .then(res => {
                console.log("all chat", res);
                // setLoserStatus(false)
                if (res && res.status) {
                    setAllMsg(res.data)

                }
            })
            .catch(err => {
                // setLoserStatus(false)
                console.log("err", err);
            })
    }

    const handleImage = async (e) => {
        setshowImage(true)

        setReportmodal(false)
        setisimage(true)
        let file = e.target.files;
        const newfile = e.target.files[0];
        // Get the file name
        const fileName = newfile.name;
        console.log(fileName, "hjhuipoi")
        let Arr = []
        for (let element of file) {
            let data = new FormData();
            data.append("img", element);
            let res = await HttpClient.fileUplode("bid-image", "POST", data);

            if (res && res?.status) {
                toast.success("Uploaded Successfully");
                console.log("images", res);
                Arr.push(res?.data)
                setMsgimg(Arr)

                const newobj = { url: res?.data, extName: res?.extName, filename: fileName }

                setReportmodal(false)
                setImageUrl(newobj)
                setisimage(false)
            } else {
                toast.error(res?.message);
            }
        }
    };

    function resivedMessage(data) {
        // console.log(data.resiverId == userData._id);
        console.log("dattta", data, "ghhh");
        if (data.resiverId == userData._id && taskId == data.taskId) {
            setAllMsg(s => {

                return [... new Set([{ ...data, _id: new Date().toTimeString(), createdAt: new Date() }, ...s])]
            })
        }

    }



    function submitinEnter(e) {
        // e.preventDefault()
        if (e.key === "Enter") {
            if (newmessage == "" && ImageUrl == null) return
            handleSendMessage()
            console.log("lgjhgjh", "hjkjklj")
        }
    }


    useEffect(() => {

        if (taskId) {
            console.log("hhh", taskId)
            getAllData()


            socket.on('resivedMessage', resivedMessage);
            window.scroll(0, document.body.scrollHeight)
            return () => socket.off('resivedMessage', resivedMessage);
        }
    }, [taskId])


    useEffect(() => {

        // seeProfile()
        console.log("projectId", projectId)
        if (projectId) {
            ProductServices.getbidder(projectId)
                .then(res => {
                    console.log("bider Data", res);
                    if (res && res.status) {
                        // if (bidderdata) {
                        //     const temparr = res.data.filter((ele, id) => ele.bidderDetail._id == bidderdata)
                        //     console.log("ssww", temparr)
                        // }
                        window.scroll(0, document.body.scrollHeight)

                        settaskId(res.data._id)
                        setbiderImage(res.data.bidderDetail.image)
                        setSenderId(res.data.projectOwnerId)
                        if (res.data.projectOwnerId == userData._id) {
                            setSenderId(res.data.bidderId)
                            // seeProfile(res.data.bidderId)
                        } else {
                            setSenderId(res.data.projectOwnerId)
                            // seeProfile(res.data.projectOwnerId)
                        }
                    }

                })
                .catch(err => {
                    console.log("err", err);
                })

        }
    }, [projectId])


    //...................................................................
    const [reportbox, setReportbox] = useState(false);
    const reportboxModal = () => {
        setReportbox(!reportbox);
    };

    const [PartialPayment, setPartialPayment] = useState(false);
    const PartialPaymentModal = () => {
        setPartialPayment(!PartialPayment);
    };
    console.log("kkkuu8", allMsg?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)))
    return (
        <>
            <section className='commentmodal_main'>
                <div className='commentmodal'>
                    <div className='commentmodal_overlay'></div>
                    <div className='commentmodal_content'>
                        <div className='commentmodal_project_content'>
                            <p className='notification_txt'>Project Name:{chatProjectname}</p>

                            <div className='closemark' onClick={() => setchatFlag(false)}>
                                <i class="fa-solid fa-xmark"></i>
                            </div>

                            <div id="chattArea" className='mywhitechat'>

                                {
                                    allMsg?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                                        ?.map((ele, id) => {


                                            if (userData._id === ele?.senderId) {
                                                console.log(ele, "lkhjhw")
                                                return (
                                                    <div className='commnt_all_own'>
                                                        <div className='own_rply'>
                                                            <div className='img_name_time'>
                                                                <div className='roly_img_own'>
                                                                    {userData.image ? <img src={userData.image}
                                                                        style={{ borderRadius: "60%", height: "26px", width: "25px", objectFit: "contain" }} alt="/" /> :
                                                                        <img style={{ borderRadius: "60%", height: "26px", width: "25px", objectFit: "contain" }} src={randomUrl} alt="/" />}

                                                                </div>
                                                                <div className='name_time'>
                                                                    <p>{ele?.message}</p>

                                                                </div>
                                                                <p>{moment(ele?.createdAt).format("hh:mm A")}</p>
                                                            </div>

                                                            {ele.image === "null" ?
                                                                "" :
                                                                (
                                                                    <div className='commnt_image'>

                                                                        {ele?.type === "video" ? <div className='commnt_img'>
                                                                            <ViewVideo videoUrl={ele?.image} />
                                                                        </div> : ele?.type === "image" ? <div className='commnt_img'>

                                                                            <ViewInmage imgurl={ele?.image}  />
                                                                        </div> :
                                                                            <div className='commnt_docimg'>
                                                                                <span>{ele?.filename?.slice(0, 10)}...</span>
                                                                                <a href={ele?.image} onClick={() => {
                                                                                    setisdownloadloading(true)
                                                                                    setTimeout(() => {
                                                                                        setisdownloadloading(false)
                                                                                    }, 300)
                                                                                }} >
                                                                                    <img
                                                                                        src={dockimageUrl}
                                                                                        style={{
                                                                                            height: '55px',
                                                                                            width: '55px',
                                                                                            borderRadius: '5px',
                                                                                            alignItems: 'center'
                                                                                        }}
                                                                                        alt="Your Image"
                                                                                    />

                                                                                    {isdownload ? <p style={{ position: "absolute", top: "18px", left: "19px" }}><RotatingLines
                                                                                        strokeColor="black"
                                                                                        height="40"
                                                                                        width="40" /> </p> : <i className="fa fa-download"
                                                                                            aria-hidden="true"></i>}
                                                                                </a>

                                                                            </div>
                                                                        }

                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                );

                                            } else {
                                                return (
                                                    <div className='commnt_all_othrs'>
                                                        <div className='own_rply'>
                                                            <div className='img_name_time'>
                                                                <div className='roly_img_own'>
                                                                    {
                                                                        (userData._id === projectsenderId) ?
                                                                            <img style={{ borderRadius: "60%", height: "26px", width: "25px", objectFit: "contain" }}
                                                                                height={25} width={25} src={biderimage} alt="/" /> :
                                                                            projectsenderimage ?
                                                                                <img height={25} width={25} src={projectsenderimage}
                                                                                    style={{ borderRadius: "60%", height: "26px", width: "25px", objectFit: "contain" }} alt="/" /> :
                                                                                <img height={25} width={25} src={randomUrl} alt="/" />

                                                                    }

                                                                    {/* {
                                                                    projectsenderId ?
                                                                        <img style={{ borderRadius: "60%", height: "26px", width: "25px", objectFit: "contain" }}
                                                                            height={25} width={25} src={projectsenderimage} alt="/" /> : biderImage ?
                                                                            <img height={25} width={25} src={biderImage}
                                                                                style={{ borderRadius: "60%", height: "26px", width: "25px", objectFit: "contain" }} alt="/" /> :
                                                                            <img height={25} width={25} src={randomUrl} alt="/" />
                                                                } */}

                                                                </div>
                                                                <div className='name_time'>
                                                                    <p>{ele?.message}</p>

                                                                </div>
                                                                <p>{moment(ele?.createdAt).format("hh:mm A")}</p>
                                                            </div>

                                                            {ele.image === "null" ?
                                                                "" :
                                                                (
                                                                    <div className='commnt_image'>
                                                                        {ele?.type === "video" ? <div className='commnt_img'>

                                                                            <ViewVideo videoUrl={ele?.image} />
                                                                        </div> : ele?.type === "image" ? <div className='commnt_img'>

                                                                            <ViewInmage imgurl={ele?.image} />
                                                                        </div> :
                                                                            <div className='commnt_docimg'>
                                                                                <a href={ele?.image} onClick={() => {
                                                                                    setisdownloadloading(true)
                                                                                    setTimeout(() => {
                                                                                        setisdownloadloading(false)
                                                                                    }, 300);
                                                                                }}>
                                                                                    <img
                                                                                        src={dockimageUrl}
                                                                                        style={{
                                                                                            height: '55px',
                                                                                            width: '55px',
                                                                                            borderRadius: '5px',
                                                                                            alignItems: 'center'
                                                                                        }}
                                                                                        alt="Your Image"
                                                                                    />

                                                                                    {isdownload ? <p style={{ position: "absolute", top: "17px", left: "18px" }}><RotatingLines
                                                                                        strokeColor="black"
                                                                                        height="40"
                                                                                        width="40" /> </p> : <i className="fa fa-download"
                                                                                            aria-hidden="true"></i>}

                                                                                </a>
                                                                                <span>{ele?.filename?.slice(0, 10)}...</span>
                                                                            </div>
                                                                        }


                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                );

                                            }


                                        })
                                }
                            </div>






                            <div className='commnt_section type_here'>
                                <div className='cmnt_dot' onClick={specialModal}>
                                    <i class="fa-solid fa-ellipsis-vertical"></i>

                                </div>


                                {reportmodal && <div className='rpt_pymnt_rqst_crd' style={{ height: '203px', width: '43%' }}>
                                    <div className='close_btnn' onClick={() => setReportmodal(false)}>
                                        <i class="fa-solid fa-xmark"></i>
                                    </div>
                                    <div className='mainrpt'>
                                        {/* <div className='rpt_flx' style={{ cursor: "pointer" }}
                                         onClick={reportboxModal}>
                                            <div className='rpt_imf'>
                                                <img src={Union} alt="/" />
                                            </div>
                                            <div className='rpt'>
                                                <p>Report</p>
                                            </div>
                                        </div> */}

                                        {/* <div className='rpt_flx' style={{ cursor: "pointer" }} 
                                        onClick={PartialPaymentModal}>
                                            <div className='rpt_imf'>
                                                <img src={Group} alt="/" />
                                            </div>
                                            <div className='rpt'>
                                                <p>Partial Payment Request</p>
                                            </div>
                                        </div> */}
                                        <div className='mb-2 myyinput'>
                                            <div className="file-input">
                                                <label className="file-input__label" htmlFor="file-input">
                                                    <i class="fa-solid fa-file-lines"></i>
                                                    <span>Documents</span>
                                                </label>
                                                <input
                                                    type="file"
                                                    name="file-input"
                                                    id="file-input"
                                                    className="file-input__input"
                                                    onKeyDown={(e) => submitinEnter(e)}
                                                    placeholder="Attach file"
                                                    onChange={(e) => handleImage(e)}
                                                />

                                            </div>

                                            {/* <label>Documents</label>
                                            <input
                                                type="file"
                                                multiple
                                                className="form-control"
                                                id="wage"
                                                aria-describedby="textHelp"
                                                onKeyDown={(e) => submitinEnter(e)}
                                                placeholder="Attach file"
                                                onChange={(e) => handleImage(e)}


                                            /> */}
                                        </div>
                                        <div className='mb-2 myyinput'>
                                            <div className="file-input">
                                                <label className="file-input__label" htmlFor="file-input">
                                                    <i class="fa-solid fa-image"></i>
                                                    <span>Photos</span>
                                                </label>
                                                <input
                                                    type="file"
                                                    name="file-input"
                                                    id="file-input"
                                                    onKeyDown={(e) => submitinEnter(e)}
                                                    placeholder="Attach file"
                                                    onSelect={(e) => handleImage(e)}
                                                    className="file-input__input"
                                                />

                                            </div>

                                            {/* <label>Documents</label>
                                            <input
                                                type="file"
                                                multiple
                                                className="form-control"
                                                id="wage"
                                                aria-describedby="textHelp"
                                                onKeyDown={(e) => submitinEnter(e)}
                                                placeholder="Attach file"
                                                onChange={(e) => handleImage(e)}


                                            /> */}
                                        </div>
                                        <div className='mb-2 myyinput'>
                                            <div className="file-input">
                                                <label className="file-input__label" htmlFor="file-input">
                                                    <i class="fa-solid fa-circle-play"></i>
                                                    <span>Videos</span>
                                                </label>
                                                <input
                                                    type="file"
                                                    name="file-input"
                                                    id="file-input"
                                                    className="file-input__input"
                                                    onKeyDown={(e) => submitinEnter(e)}
                                                    placeholder="Attach file"
                                                    onSelect={(e) => handleImage(e)}

                                                />

                                            </div>

                                            {/* <label>Documents</label>
                                            <input
                                                type="file"
                                                multiple
                                                className="form-control"
                                                id="wage"
                                                aria-describedby="textHelp"
                                                onKeyDown={(e) => submitinEnter(e)}
                                                placeholder="Attach file"
                                                onChange={(e) => handleImage(e)}


                                            /> */}
                                        </div>


                                        {/* {msgimage ? msgimage?.map((it, ind) => {
                                            return (
                                                <img src={it} style={{
                                                    height: '80px',
                                                    width: '80px',
                                                    borderRadius: '5px'
                                                }}
                                                    key={ind}
                                                />
                                            )
                                        }) : ""
                                        } */}
                                        {
                                            // ImageUrl?.extName !== "jpg" ? (
                                            // {ImageUrl && ImageUrl.url ? (
                                            //     <video style={{ position: 'fixed' }} width="320" height="140" controls>
                                            //       <source src={ImageUrl.url} type="video/mp4" />
                                            //     </video>
                                            //   ) : null}
                                            // ) : (
                                            //     <img
                                            //         src={ImageUrl?.url}
                                            //         style={{
                                            //             height: '80px',
                                            //             width: '80px',
                                            //             borderRadius: '5px'
                                            //         }}
                                            //         alt="Your Image"
                                            //     />
                                            // )
                                        }



                                    </div>
                                </div>}

                                {showImage && <div className='up_imggg'>
                                    <div className='close_btnn' style={{ marginLeft: "2rem" }}
                                        onClick={() => setshowImage(false)}>
                                        <i class="fa-solid fa-xmark"></i>
                                    </div>

                                    {
                                        isimage ? <RotatingLines /> : ImageUrl?.extName === "mp4" ?
                                            <video width="220" height="140" controls>
                                                <source src={ImageUrl?.url} type="video/mp4" />
                                            </video> :
                                            ImageUrl?.extName == "jpg" ?
                                                <img
                                                    src={ImageUrl?.url}
                                                    style={{
                                                        height: '80px',
                                                        width: '80px',
                                                        borderRadius: '5px',
                                                        alignItems: 'center'
                                                    }}
                                                    alt="Your Image"
                                                /> :
                                                <a href={ImageUrl?.url}>
                                                    <img
                                                        src={dockimageUrl}
                                                        style={{
                                                            height: '80px',
                                                            width: '80px',
                                                            borderRadius: '5px',
                                                            alignItems: 'center'
                                                        }}
                                                        alt="Your Image"
                                                    />
                                                </a>
                                    }


                                </div>}

                                <div className='inpt_commnt'>


                                    <input type="text"
                                        id="wage"
                                        value={newmessage}
                                        onChange={(e) => {
                                            setnewMessage(e.target.value);

                                        }}
                                        onKeyDown={(e) => submitinEnter(e)}
                                        placeholder='Type your message here'
                                        className='input_cmnt_txt' />



                                </div>


                                <div className='cmnt_snd' style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        handleSendMessage();

                                    }}>

                                    <i class="fa-regular fa-paper-plane"></i>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </section>

            {reportbox && <ReportBoxModal closeReportBox={setReportbox} />}

            {PartialPayment && <PartialPaymentRequest closePartial={setPartialPayment} />}
        </>
    )
}

