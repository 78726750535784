import React, { useEffect, useRef, useState } from "react";
import AuthServices from "../../services/AuthServices";

import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import FindMapAddress from "../../View/Project/FindMapAddress";
import { AuthCtx } from "../../context/CreateContext";


import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";


const IMAGE_URL = "http://13.127.222.180:4001/";


const AddownAddressModel = ({ setaddOwnAddressflag }) => {



    const [userData, setUserData] = useState();
    const [userLocation, setUserLocation] = useState(false);
    const [userLatLng, setUserLatLng] = useState({});
    console.log("kkwwrrt", userLatLng);

    const { address, setAddress } = AuthCtx();
    const [ownArea, setownArea] = useState(
        ""
    );



    const [imageflag, setimageflag] = useState(false);
    console.log("jhjkh", imageflag);

    const [showDate, setShowDate] = useState(true);
    const [freelencer, setFreelencer] = useState(false);
    console.log("freelencer", freelencer);
    const navigate = useNavigate();

    const [errFlag, seterrFlag] = useState(false);
    const [errordata, setErrorData] = useState()



    const [first_destination, setfirst_destination] = useState("");
    const [first_PostalCode, setfirst_PostalCode] = useState("");
    console.log("jjjj", Number(address.zipcode));
    const [myaddress, setMyAddress] = useState("");
    const handleChangeAddress = (address) => {
        setMyAddress(address);

    };
    console.log(myaddress, "klhkjfhliuuu")
    useEffect(() => {
        handleChangeAddress(removeFirstSegment(address.area))

    }, [address.area])


    console.log("wrrq", address, myaddress);

    const handleSelect = (address) => {
        geocodeByAddress(address).then((results) => {
            const reszipcode = results[0]?.address_components?.filter((ele) =>
                ele.types.some((type) => type === "postal_code")
            );

            if (reszipcode[0]?.long_name) {
                setfirst_PostalCode(reszipcode[0]?.long_name ?? 0);

                console.log(reszipcode[0], "hhkjq2")

            } else {
                toast.error("Enter specific location");
            }
            getLatLng(results[0])
                .then((latLng) => {
                    console.log("Success", latLng);
                    setUserLatLng(latLng);
                    setUserLocation(true);
                    setMyAddress(address);
                    setfirst_destination(address);
                })
                .catch((error) => console.error("Error", error));
        });
    };
    const getUserData = async () => {
        let res = await AuthServices.fetchProfile();
        console.log("res", res);
        setUserData(res?.data?.[0]);
        setMyAddress(res?.data?.[0].userAddress);
        setAddress({
            latlng: {
                lat: res?.data?.[0].coordinateArray[1],
                lng: res?.data?.[0].coordinateArray[0],
            },
            area: res?.data?.[0].userAddress,
        });
        setfirst_PostalCode(res?.data?.[0].userAreaPostCode);
    };

    // function removeFirstSegment(inputString) {
    //     const commaIndex = inputString.indexOf(",");

    //     if (commaIndex !== -1) {
    //         // If a comma is found, extract the substring after the first comma

    //         console.log(inputString.slice(commaIndex + 1).trim(), "gdggdhf");
    //         return inputString.slice(commaIndex + 1).trim();
    //     }

    //     // If no comma is found, return the original string
    //     return inputString;
    // }


    function removeFirstSegment(inputString) {
        const commaIndex = inputString?.indexOf(",");

        if (commaIndex !== -1) {
            // If a comma is found, extract the substring after the first comma
            console.log(inputString?.slice(commaIndex + 1).trim(), "pppwwwqqq")
            return inputString?.slice(commaIndex + 1).trim();
        }

        // If no comma is found or the string is empty, return an empty string
        return "";
    }



    const PostalCodehandelar = (e) => {
        // setPostalCode(e.target.value);
        setAddress({ ...address, zipcode: e.target.value });
    };

    const handelSubmit = (e) => {
        e.preventDefault()

        if (removeFirstSegment(address.area).indexOf("+") < 0) {

            console.log(removeFirstSegment(address.area).indexOf("+"), address.area)
            const data = {
                // "categories":["Expanding the Garage"],
                userAreaPostCode: Number(address.zipcode) > 0 ? address.zipcode : first_PostalCode,
                userAddress: removeFirstSegment(address.area),
                coordinateArray: [address?.latLng?.lng, address?.latLng?.lat]


            }
            AuthServices.profileUpdate(data)
                .then((res) => {
                    console.log(res, "gjhkl")
                    if (res && res.status) {
                        toast.success("Address Saved succsessfully")
                    }
                    setaddOwnAddressflag(false);
                    if (address) {
                        setAddress({
                            latlng: {},
                            area: "",
                        });
                    }
                }).catch((err) => {
                    console.log(err)
                    toast.error(err?.mesg)
                    setErrorData(err?.mesg)
                })

        } else {
            setErrorData("Please select a address")
            // alert("Please select a address")
            toast.error("Please select a address")
        }
    }




    useEffect(() => {
        getUserData();
        if (address) {
            setAddress({ ...address, area: "" });
        }
    }, []);

    const strDateRef = useRef(null);

    document.addEventListener("DOMContentLoaded", function () {
        var dateInput = document.getElementById("session-date");

        dateInput.addEventListener("click", function () {
            // Trigger the click event on the input
            dateInput.click();
        });
    });


    return (
        <section className="create-project-main">
            <div className="create-project" id="create-project">
                <div
                    className="add-project"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                        marginRight: "2rem",
                    }}
                >
                    {/* <i class="fa fa-arrow-left arrow" aria-hidden="true"></i> */}
                    <h3>Add own address</h3>
                    <p style={{ color: "red" }}>{errordata}</p>

                </div>



                <button
                    className="closebtn"
                    onClick={() => {
                        setaddOwnAddressflag(false);
                        if (address) {
                            setAddress({
                                latlng: {},
                                area: "",
                            });
                        }
                    }}
                >
                    <i className="fa-solid fa-xmark"></i>
                </button>

                <form>
                    {/* <pre>{JSON.stringify(selected)}</pre> */}
                    <div className="service_input">


                        <PlacesAutocomplete
                            value={myaddress}
                            onChange={handleChangeAddress}
                            onSelect={handleSelect}
                        >
                            {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                            }) => (
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            border: "1px solid black",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        <input
                                            style={{
                                                textDecoration: "none",
                                                border: 0,
                                                outline: "none",
                                            }}
                                            // onChange={(e) => {
                                            //   alert("e");
                                            //   console.log("e.target.value", e.target.value);
                                            // }}
                                            {...getInputProps({
                                                placeholder: "Search Places ...",
                                                className: "form-control address_input",
                                            })}
                                        />
                                        <i class="fa fa-search"></i>
                                    </div>
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                            // setfirst_destination(suggestion.description);
                                            console.log("sugesion", suggestion);
                                            const className = suggestion.active
                                                ? "suggestion-item--active"
                                                : "suggestion-item";
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                : { backgroundColor: "#ffffff", cursor: "pointer" };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                        {userLocation && (
                            <div
                            // onClick={() => {
                            //   setfirst_destination("");
                            // }}
                            >
                                <FindMapAddress userLatLng={userLatLng} setMyAddress={setMyAddress} setUserLatLng={setUserLatLng} setownArea={setownArea} iamfrom={"ownAddress"} />
                            </div>
                        )}
                    </div>


                    {/* Post Code */}
                    <div class="form-group post_code">
                        <label for="formGroupExampleInput">Post Code</label>
                        <input
                            type="text"
                            class="form-control"
                            id="formGroupExampleInput"
                            placeholder="Enter your postal code"
                            name="postalCode"
                            // value={postalCode ? postalCode : address.zipcode}
                            value={
                                Number(address.zipcode) > 0 ? address.zipcode : first_PostalCode
                            }
                            onChange={(e) => {
                                // handleChange(e);
                                PostalCodehandelar(e);
                            }}
                        />
                    </div>







                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button
                            className="btn btn-primary submit_btn"
                            onClick={(e) => {
                                handelSubmit(e);
                                // alert("kk");
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default AddownAddressModel;
